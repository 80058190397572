import React, { useState, useEffect } from 'react';
import { useESIMStore } from '../../../hooks/esim';

const PersonalInfoSection = ({ customerInfo }) => {
  const [editedInfo, setEditedInfo] = useState({
    personalInfo: {}
  });
  const [hasChanges, setHasChanges] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);

  // Get the updatePersonalInfo function from Zustand store
  const updatePersonalInfo = useESIMStore(state => state.updatePersonalInfo);

  // Common nationalities list
  const nationalities = [
    "Ghanaian", "Nigerian", "South African", "Kenyan", "Ivorian", 
    "Ethiopian", "Tanzanian", "Ugandan", "Zambian", "Zimbabwean", 
    "Cameroonian", "Egyptian", "Moroccan", "Algerian", "Tunisian",
    "American", "British", "Canadian", "Australian", "German", "French"
  ].sort();

  useEffect(() => {
    if (customerInfo && customerInfo.personalInfo) {
      setEditedInfo({
        personalInfo: { ...customerInfo.personalInfo }
      });
    }
  }, [customerInfo]);

  // Function to check if the form has any changes
  useEffect(() => {
    if (customerInfo && customerInfo.personalInfo && editedInfo.personalInfo) {
      const original = customerInfo.personalInfo;
      const edited = editedInfo.personalInfo;
      
      const editableFields = ['gender', 'occupation', 'maidenName', 'dateOfBirth', 'nationality'];
      
      const hasAnyChanges = editableFields.some(field => 
        original[field] !== edited[field] && edited[field] !== undefined && edited[field] !== ''
      );
      
      setHasChanges(hasAnyChanges);
    }
  }, [editedInfo, customerInfo]);

  const handlePersonalInfoChange = (e) => {
    const { name, value } = e.target;
    
    // Clear errors for this field if they exist
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: null
      }));
    }
    
    setEditedInfo({
      ...editedInfo,
      personalInfo: {
        ...editedInfo.personalInfo,
        [name]: value
      }
    });
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    
    // Validate date is not in the future
    const selectedDate = new Date(value);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to beginning of day for accurate comparison
    
    if (selectedDate >= today) {
      setErrors({
        ...errors,
        [name]: "Date of birth cannot be today or in the future"
      });
      return;
    } else {
      // Clear error if valid
      setErrors({
        ...errors,
        [name]: null
      });
    }
    
    handlePersonalInfoChange(e);
  };

  const validateForm = () => {
    const newErrors = {};
    
    // Add any specific validations here
    if (editedInfo.personalInfo.dateOfBirth) {
      const selectedDate = new Date(editedInfo.personalInfo.dateOfBirth);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      if (selectedDate >= today) {
        newErrors.dateOfBirth = "Date of birth cannot be today or in the future";
      }
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveChanges = async () => {
    if (!hasChanges) {
      alert("No changes to save");
      return;
    }
    
    if (!validateForm()) {
      return;
    }
    
    setIsSaving(true);
    setSaveError(null);
    setSaveSuccess(false);
    
    try {
      // Simulate a delay to show the spinner (remove in production)
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // Call the Zustand store function to update personal info
      const result = await updatePersonalInfo(
        customerInfo.customerId, 
        editedInfo.personalInfo
      );
      
      if (result.success) {
        setSaveSuccess(true);
        
        // Reset success message after 3 seconds
        setTimeout(() => setSaveSuccess(false), 3000);
        
        // Reset hasChanges since data is now saved
        setHasChanges(false);
      } else {
        setSaveError(result.error || "Failed to save changes");
      }
    } catch (error) {
      setSaveError(error.message || "An error occurred while saving changes");
    } finally {
      setIsSaving(false);
    }
  };

  // Format the date string (DD/MM/YYYY) to YYYY-MM-DD for date input
  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    
    // Check if already in YYYY-MM-DD format
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      return dateString;
    }
    
    // Handle DD/MM/YYYY format
    const parts = dateString.split('/');
    if (parts.length === 3) {
      // Convert DD/MM/YYYY to YYYY-MM-DD
      return `${parts[2]}-${parts[1].padStart(2, '0')}-${parts[0].padStart(2, '0')}`;
    }
    
    return '';
  };

  return (
    <div className="bg-white rounded-lg p-6 shadow-sm">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-medium">Personal Information</h3>
        {saveSuccess && (
          <div className="bg-green-100 text-green-800 px-3 py-1 rounded-full text-sm font-medium">
            Changes saved successfully!
          </div>
        )}
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Non-editable fields */}
       
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Surname</label>
          <div className="p-2 bg-gray-100 rounded">
            {customerInfo.personalInfo?.surname || "N/A"}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Other Names</label>
          <div className="p-2 bg-gray-100 rounded">
            {customerInfo.personalInfo?.otherNames || "N/A"}
          </div>
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
          <div className="p-2 bg-gray-100 rounded">
            {customerInfo.contacts?.email || "N/A"}
          </div>
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Phone Number</label>
          <div className="p-2 bg-gray-100 rounded">
            {customerInfo.contacts?.telephoneNumber || "N/A"}
          </div>
        </div>
        
        {/* Editable fields with updated data display */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Gender</label>
          <select
            name="gender"
            value={editedInfo.personalInfo?.gender || ''}
            onChange={handlePersonalInfoChange}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            disabled={isSaving}
          >
            <option value="">Select gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
          {saveSuccess && editedInfo.personalInfo?.gender && (
            <p className="mt-1 text-xs text-blue-600">Updated: {editedInfo.personalInfo.gender}</p>
          )}
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Occupation</label>
          <input
            type="text"
            name="occupation"
            value={editedInfo.personalInfo?.occupation || ''}
            onChange={handlePersonalInfoChange}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            disabled={isSaving}
          />
          {saveSuccess && editedInfo.personalInfo?.occupation && (
            <p className="mt-1 text-xs text-blue-600">Updated: {editedInfo.personalInfo.occupation}</p>
          )}
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Maiden Name</label>
          <input
            type="text"
            name="maidenName"
            value={editedInfo.personalInfo?.maidenName || ''}
            onChange={handlePersonalInfoChange}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            disabled={isSaving}
          />
          {saveSuccess && editedInfo.personalInfo?.maidenName && (
            <p className="mt-1 text-xs text-blue-600">Updated: {editedInfo.personalInfo.maidenName}</p>
          )}
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Date of Birth</label>
          <input
            type="date"
            name="dateOfBirth"
            value={formatDateForInput(editedInfo.personalInfo?.dateOfBirth || '')}
            onChange={handleDateChange}
            max={new Date().toISOString().split('T')[0]} // Set max to today
            className={`w-full p-2 border ${errors.dateOfBirth ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
            disabled={isSaving}
          />
          {errors.dateOfBirth && (
            <p className="mt-1 text-sm text-red-600">{errors.dateOfBirth}</p>
          )}
          {saveSuccess && editedInfo.personalInfo?.dateOfBirth && (
            <p className="mt-1 text-xs text-blue-600">
              Updated: {new Date(editedInfo.personalInfo.dateOfBirth).toLocaleDateString()}
            </p>
          )}
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Nationality</label>
          <select
            name="nationality"
            value={editedInfo.personalInfo?.nationality || ''}
            onChange={handlePersonalInfoChange}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            disabled={isSaving}
          >
            <option value="">Select nationality</option>
            {nationalities.map(nationality => (
              <option key={nationality} value={nationality}>
                {nationality}
              </option>
            ))}
            <option value="other">Other</option>
          </select>
          {saveSuccess && editedInfo.personalInfo?.nationality && (
            <p className="mt-1 text-xs text-blue-600">Updated: {editedInfo.personalInfo.nationality}</p>
          )}
        </div>
      </div>
      
      <div className="mt-6 flex justify-between items-center">
        {saveError && (
          <div className="bg-red-100 text-red-800 px-3 py-1 rounded text-sm">
            Error: {saveError}
          </div>
        )}
        
        <div className="ml-auto">
          <button
            className={`px-4 py-2 ${hasChanges && !isSaving 
              ? 'bg-blue-500 hover:bg-blue-600' 
              : 'bg-gray-400 cursor-not-allowed'} text-white rounded transition-colors inline-flex items-center`}
            onClick={handleSaveChanges}
            disabled={!hasChanges || isSaving}
          >
            {isSaving && (
              <svg className="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            )}
            {isSaving ? 'Saving...' : 'Save Changes'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoSection;