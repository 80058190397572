// import React, { useState, useEffect } from 'react';
// import DocumentModal from './documentModal';
// import { useESIMStore } from '../../../hooks/esim';

// const DocumentsSection = ({ customerId }) => {
//   const [documents, setDocuments] = useState([]);
//   const [passports, setPassports] = useState([]);
//   const [showModal, setShowModal] = useState(false);
//   const [currentDocument, setCurrentDocument] = useState(null);
//   const [documentType, setDocumentType] = useState('');
//   const [updatedDocType, setUpdatedDocType] = useState(null);
//   const [isDeleting, setIsDeleting] = useState(false);
//   const [deleteId, setDeleteId] = useState(null);
//   const [deleteError, setDeleteError] = useState(null);
//   const [initialLoading, setInitialLoading] = useState(true);
//   const [fetchError, setFetchError] = useState(null);

//   // Get required functions and state from store
//   const { 
//     deleteDocument,
//     resetLoadingState,
//     fetchCustomerInfo,
//     customerInfo
//   } = useESIMStore(state => ({
//     deleteDocument: state.deleteDocument,
//     resetLoadingState: state.resetLoadingState,
//     fetchCustomerInfo: state.fetchCustomerInfo,
//     customerInfo: state.customerInfo
//   }));

//   const isLoading = useESIMStore(state => state.isLoading);

//   // Fetch customer data when component mounts or customerId changes
//   useEffect(() => {
//     const loadCustomerData = async () => {
//       if (customerId) {
//         setInitialLoading(true);
//         setFetchError(null);
        
//         try {
//           console.log("Fetching customer data for:", customerId);
//           const result = await fetchCustomerInfo(customerId);
          
//           if (!result.success) {
//             setFetchError(result.error || "Failed to fetch customer data");
//           }
//         } catch (error) {
//           console.error("Error fetching customer data:", error);
//           setFetchError(error.message || "An error occurred while fetching customer data");
//         } finally {
//           setInitialLoading(false);
//         }
//       }
//     };
    
//     loadCustomerData();
//   }, [customerId, fetchCustomerInfo]);

//   // Update local state when customerInfo changes
//   useEffect(() => {
//     if (customerInfo) {
//       // Set documents
//       if (customerInfo.documents && Array.isArray(customerInfo.documents)) {
//         setDocuments(customerInfo.documents);
//       } else {
//         setDocuments([]);
//       }
      
//       // Set passports
//       if (customerInfo.passports && Array.isArray(customerInfo.passports)) {
//         setPassports(customerInfo.passports);
//       } else {
//         setPassports([]);
//       }
//     }
//   }, [customerInfo]);

//   // Reset loading state when component unmounts
//   useEffect(() => {
//     return () => {
//       resetLoadingState();
//     };
//   }, [resetLoadingState]);

//   // Effect to highlight updated document types temporarily
//   useEffect(() => {
//     if (updatedDocType) {
//       const timer = setTimeout(() => {
//         setUpdatedDocType(null);
//       }, 3000);
      
//       return () => clearTimeout(timer);
//     }
//   }, [updatedDocType]);

//   const handleRefreshData = async () => {
//     if (customerId) {
//       setInitialLoading(true);
//       setFetchError(null);
      
//       try {
//         await fetchCustomerInfo(customerId);
//       } catch (error) {
//         setFetchError(error.message || "An error occurred while refreshing data");
//       } finally {
//         setInitialLoading(false);
//       }
//     }
//   };

//   const handleAddDocument = (type) => {
//     setDocumentType(type);
//     setCurrentDocument(null);
//     setShowModal(true);
//   };

//   const handleEditDocument = (document, isPassport = false) => {
//     setCurrentDocument({ ...document, isPassport });
//     setDocumentType(document.type);
//     setShowModal(true);
//   };

//   const handleSaveDocument = (documentData) => {
//     const { isPassport, ...documentToSave } = documentData;
    
//     // Update the appropriate list for immediate UI update
//     if (isPassport || documentToSave.type === 'passport') {
//       if (currentDocument && currentDocument.isPassport) {
//         // Update existing passport
//         const updatedPassports = passports.map(doc => 
//           (doc.documentId && doc.documentId === currentDocument.documentId) || 
//           (doc.fileUrl === currentDocument.fileUrl) ? documentToSave : doc
//         );
//         setPassports(updatedPassports);
//       } else {
//         // Add new passport
//         setPassports([...passports, documentToSave]);
//       }
//       setUpdatedDocType('passport');
//     } else {
//       if (currentDocument && !currentDocument.isPassport) {
//         // Update existing document
//         const updatedDocuments = documents.map(doc => 
//           (doc.documentId && doc.documentId === currentDocument.documentId) || 
//           (doc.fileUrl === currentDocument.fileUrl) ? documentToSave : doc
//         );
//         setDocuments(updatedDocuments);
//       } else {
//         // Add new document
//         setDocuments([...documents, documentToSave]);
//       }
//       setUpdatedDocType(documentToSave.type);
//     }
    
//     setShowModal(false);
    
//     // The actual saving to backend is handled by the DocumentModal via Zustand
//     console.log('Document saved:', documentToSave);
//   };

//   const handleDeleteDocument = async (document, isPassport = false) => {
//     if (window.confirm('Are you sure you want to remove this document?')) {
//       setIsDeleting(true);
//       setDeleteId(document.documentId || document.fileUrl);
//       setDeleteError(null);
      
//       try {
//         // Call the delete function from store
//         const result = await deleteDocument(customerId, {
//           ...document,
//           isPassport,
//           documentId: document.documentId,
//           fileUrl: document.fileUrl
//         });
        
//         if (result.success) {
//           // Update will happen via the customerInfo change
//           console.log('Document deleted successfully');
//         } else {
//           setDeleteError(result.error || 'Failed to delete document');
//         }
//       } catch (error) {
//         console.error('Error deleting document:', error);
//         setDeleteError(error.message || 'An error occurred while deleting');
//       } finally {
//         setIsDeleting(false);
//         setDeleteId(null);
//       }
//     }
//   };

//   // Group related documents (like Ghana Card front and back)
//   const groupRelatedDocuments = (docs) => {
//     const grouped = {};
    
//     docs.forEach(doc => {
//       if (doc.type === 'Ghana Card' || doc.type === 'Ghana Card Back') {
//         if (!grouped['Ghana Card']) {
//           grouped['Ghana Card'] = [];
//         }
//         grouped['Ghana Card'].push(doc);
//       } else {
//         if (!grouped[doc.type]) {
//           grouped[doc.type] = [];
//         }
//         grouped[doc.type].push(doc);
//       }
//     });
    
//     return grouped;
//   };

//   const groupedDocuments = groupRelatedDocuments(documents);
//   const isUpdatedType = (type) => updatedDocType === type || (type === 'Ghana Card' && (updatedDocType === 'Ghana Card' || updatedDocType === 'Ghana Card Back'));

//   // Loading state view
//   if (initialLoading) {
//     return (
//       <div className="bg-white rounded-lg p-6 shadow-sm">
//         <div className="flex justify-center items-center py-10">
//           <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
//         </div>
//         <div className="text-center text-gray-500">Loading documents...</div>
//       </div>
//     );
//   }

//   // Error state view
//   if (fetchError) {
//     return (
//       <div className="bg-white rounded-lg p-6 shadow-sm">
//         <div className="bg-red-100 text-red-700 p-4 rounded-md mb-4">
//           <h3 className="font-medium mb-2">Error Loading Documents</h3>
//           <p>{fetchError}</p>
//         </div>
//         <div className="flex justify-center">
//           <button
//             onClick={handleRefreshData}
//             className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded flex items-center"
//           >
//             <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//               <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
//             </svg>
//             Retry
//           </button>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <>
//       <div className="bg-white rounded-lg p-6 shadow-sm">
//         <div className="flex justify-between items-center mb-4">
//           <h3 className="font-medium"></h3>
//           <div className="flex space-x-2">
//             <button
//               onClick={handleRefreshData}
//               className="px-3 py-1 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded flex items-center"
//               disabled={isLoading}
//             >
//               <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
//               </svg>
//               Refresh
//             </button>
//             <button
//               className="px-3 py-1 bg-green-500 hover:bg-green-600 text-white rounded flex items-center"
//               onClick={() => handleAddDocument('')}
//               disabled={isLoading}
//             >
//               {isLoading ? (
//                 <>
//                   <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
//                     <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
//                     <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
//                   </svg>
//                   Loading...
//                 </>
//               ) : (
//                 <>
//                   <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
//                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
//                   </svg>
//                   Add Document
//                 </>
//               )}
//             </button>
//           </div>
//         </div>

//         {/* Error message */}
//         {deleteError && (
//           <div className="mb-4 p-3 bg-red-100 text-red-700 rounded">
//             {deleteError}
//           </div>
//         )}

//         {/* Passports Section */}
//         <div className={`mb-6 ${isUpdatedType('passport') ? 'border-2 border-blue-500 p-4 rounded-lg transition-all duration-300' : ''}`}>
//           <div className="flex justify-between items-center">
//             <h4 className={`text-lg font-medium mb-3 ${isUpdatedType('passport') ? 'text-blue-600' : ''}`}>Passports</h4>
//             {isUpdatedType('passport') && (
//               <div className="bg-blue-100 text-blue-700 px-2 py-1 rounded text-sm mb-3">
//                 Updated successfully!
//               </div>
//             )}
//           </div>
          
//           {passports.length > 0 ? (
//             <div className="overflow-x-auto">
//               <table className="min-w-full divide-y divide-gray-200">
//                 <thead className="bg-gray-50">
//                   <tr>
//                     <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Passport Number</th>
//                     <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date Issued</th>
//                     <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Expiry Date</th>
//                     <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
//                     <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
//                   </tr>
//                 </thead>
//                 <tbody className="bg-white divide-y divide-gray-200">
//                   {passports.map((passport, index) => (
//                     <tr key={passport.documentId || index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
//                         {passport.passportNumber || 'N/A'}
//                       </td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                         {passport.passportDateIssued || passport.dateIssued || 'N/A'}
//                       </td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                         {passport.passportDateExpirey || passport.dateExpire || 'N/A'}
//                       </td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm">
//                         {passport.isActive ? (
//                           <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
//                             Active
//                           </span>
//                         ) : (
//                           <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
//                             Inactive
//                           </span>
//                         )}
//                       </td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
//                         <button 
//                           onClick={() => handleEditDocument(passport, true)}
//                           className="text-indigo-600 hover:text-indigo-900 mr-3"
//                           disabled={isLoading || isDeleting}
//                         >
//                           Edit
//                         </button>
//                         <a 
//                           href={passport.fileUrl}
//                           target="_blank"
//                           rel="noreferrer"
//                           className="text-blue-600 hover:text-blue-900 mr-3"
//                         >
//                           View
//                         </a>
//                         <button 
//                           onClick={() => handleDeleteDocument(passport, true)}
//                           className="text-red-600 hover:text-red-900 inline-flex items-center"
//                           disabled={isLoading || (isDeleting && deleteId === (passport.documentId || passport.fileUrl))}
//                         >
//                           {isDeleting && deleteId === (passport.documentId || passport.fileUrl) ? (
//                             <>
//                               <svg className="animate-spin -ml-1 mr-1 h-4 w-4 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
//                                 <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
//                                 <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
//                               </svg>
//                               Deleting...
//                             </>
//                           ) : (
//                             'Delete'
//                           )}
//                         </button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           ) : (
//             <div className="text-center text-gray-500 p-4 border rounded-md">No passports uploaded</div>
//           )}
//         </div>

//         {/* Other Documents Section */}
//         <div>
//           <h4 className="text-lg font-medium mb-3">Other Documents</h4>
//           {Object.keys(groupedDocuments).length > 0 ? (
//             <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//               {Object.entries(groupedDocuments).map(([type, docs]) => (
//                 <div key={type} className={`border rounded-lg p-4 ${isUpdatedType(type) ? 'border-2 border-blue-500 transition-all duration-300' : ''}`}>
//                   <div className="flex justify-between items-center">
//                     <h5 className={`font-medium mb-2 ${isUpdatedType(type) ? 'text-blue-600' : ''}`}>{type}</h5>
//                     {isUpdatedType(type) && (
//                       <div className="bg-blue-100 text-blue-700 px-2 py-1 rounded text-sm mb-2">
//                         Updated!
//                       </div>
//                     )}
//                   </div>
                  
//                   {/* Ghana Card special handling to show front/back */}
//                   {type === 'Ghana Card' ? (
//                     <div className="space-y-3">
//                       {docs.map((doc, idx) => (
//                         <div key={doc.documentId || idx} className="flex justify-between items-center">
//                           <div>
//                             <span className="text-sm font-medium">{doc.type === 'Ghana Card Back' ? 'Back' : 'Front'}</span>
//                             <p className="text-xs text-gray-500">{doc.date_uploaded}</p>
//                           </div>
//                           <div className="flex space-x-2">
//                             <button 
//                               onClick={() => handleEditDocument(doc)}
//                               className="text-xs text-indigo-600 hover:text-indigo-900"
//                               disabled={isLoading || isDeleting}
//                             >
//                               Edit
//                             </button>
//                             <a 
//                               href={doc.fileUrl}
//                               target="_blank"
//                               rel="noreferrer"
//                               className="text-xs text-blue-600 hover:text-blue-900"
//                             >
//                               View
//                             </a>
//                             <button 
//                               onClick={() => handleDeleteDocument(doc)}
//                               className="text-xs text-red-600 hover:text-red-900 inline-flex items-center"
//                               disabled={isLoading || (isDeleting && deleteId === (doc.documentId || doc.fileUrl))}
//                             >
//                               {isDeleting && deleteId === (doc.documentId || doc.fileUrl) ? (
//                                 <>
//                                   <svg className="animate-spin -ml-1 mr-1 h-3 w-3 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
//                                     <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
//                                     <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
//                                   </svg>
//                                   Deleting...
//                                 </>
//                               ) : (
//                                 'Delete'
//                               )}
//                             </button>
//                           </div>
//                         </div>
//                       ))}
//                     </div>
//                   ) : (
//                     <div className="space-y-3">
//                       {docs.map((doc, idx) => (
//                         <div key={doc.documentId || idx} className="flex justify-between items-center">
//                           <div>
//                             <p className="text-xs text-gray-500">{doc.date_uploaded}</p>
//                           </div>
//                           <div className="flex space-x-2">
//                             <button 
//                               onClick={() => handleEditDocument(doc)}
//                               className="text-xs text-indigo-600 hover:text-indigo-900"
//                               disabled={isLoading || isDeleting}
//                             >
//                               Edit
//                             </button>
//                             <a 
//                               href={doc.fileUrl}
//                               target="_blank"
//                               rel="noreferrer"
//                               className="text-xs text-blue-600 hover:text-blue-900"
//                             >
//                               View
//                             </a>
//                             <button 
//                               onClick={() => handleDeleteDocument(doc)}
//                               className="text-xs text-red-600 hover:text-red-900 inline-flex items-center"
//                               disabled={isLoading || (isDeleting && deleteId === (doc.documentId || doc.fileUrl))}
//                             >
//                               {isDeleting && deleteId === (doc.documentId || doc.fileUrl) ? (
//                                 <>
//                                   <svg className="animate-spin -ml-1 mr-1 h-3 w-3 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
//                                     <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
//                                     <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
//                                   </svg>
//                                   Deleting...
//                                 </>
//                               ) : (
//                                 'Delete'
//                               )}
//                             </button>
//                           </div>
//                         </div>
//                       ))}
//                     </div>
//                   )}
//                 </div>
//               ))}
//             </div>
//           ) : (
//             <div className="text-center text-gray-500 p-4 border rounded-md">No other documents uploaded</div>
//           )}
//         </div>
//       </div>

//       {showModal && (
//         <DocumentModal
//           isOpen={showModal}
//           onClose={() => {
//             setShowModal(false);
//             resetLoadingState();
//           }}
//           onSave={handleSaveDocument}
//           document={currentDocument}
//           initialDocumentType={documentType}
//           customerId={customerId}
//         />
//       )}
//     </>
//   );
// };

// export default DocumentsSection;


import React, { useState, useEffect } from 'react';
import DocumentModal from './documentModal';
import { useESIMStore } from '../../../hooks/esim';

const DocumentsSection = ({ customerId }) => {
  const [documents, setDocuments] = useState([]);
  const [passports, setPassports] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(null);
  const [documentType, setDocumentType] = useState('');
  const [updatedDocType, setUpdatedDocType] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteError, setDeleteError] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  // Get required functions and state from store
  const { 
    deleteDocument,
    resetLoadingState,
    fetchCustomerInfo,
    customerInfo
  } = useESIMStore(state => ({
    deleteDocument: state.deleteDocument,
    resetLoadingState: state.resetLoadingState,
    fetchCustomerInfo: state.fetchCustomerInfo,
    customerInfo: state.customerInfo
  }));

  const isLoading = useESIMStore(state => state.isLoading);

  // Fetch customer data when component mounts or customerId changes
  useEffect(() => {
    const loadCustomerData = async () => {
      if (customerId) {
        setInitialLoading(true);
        setFetchError(null);
        
        try {
          console.log("Fetching customer data for:", customerId);
          const result = await fetchCustomerInfo(customerId);
          
          if (!result.success) {
            setFetchError(result.error || "Failed to fetch customer data");
          }
        } catch (error) {
          console.error("Error fetching customer data:", error);
          setFetchError(error.message || "An error occurred while fetching customer data");
        } finally {
          setInitialLoading(false);
          // Reset loading state from store as well
          resetLoadingState();
        }
      }
    };
    
    loadCustomerData();
  }, [customerId, fetchCustomerInfo, resetLoadingState]);

  // Update local state when customerInfo changes
  useEffect(() => {
    if (customerInfo) {
      // Set documents
      if (customerInfo.documents && Array.isArray(customerInfo.documents)) {
        setDocuments(customerInfo.documents);
      } else {
        setDocuments([]);
      }
      
      // Set passports
      if (customerInfo.passports && Array.isArray(customerInfo.passports)) {
        setPassports(customerInfo.passports);
      } else {
        setPassports([]);
      }
      
      // Ensure loading states are reset when data is loaded
      setInitialLoading(false);
      resetLoadingState();
    }
  }, [customerInfo, resetLoadingState]);

  // Reset loading state when component unmounts
  useEffect(() => {
    return () => {
      resetLoadingState();
    };
  }, [resetLoadingState]);

  // Effect to highlight updated document types temporarily
  useEffect(() => {
    if (updatedDocType) {
      const timer = setTimeout(() => {
        setUpdatedDocType(null);
      }, 3000);
      
      return () => clearTimeout(timer);
    }
  }, [updatedDocType]);

  const handleRefreshData = async () => {
    if (customerId) {
      setInitialLoading(true);
      setFetchError(null);
      
      try {
        await fetchCustomerInfo(customerId);
      } catch (error) {
        setFetchError(error.message || "An error occurred while refreshing data");
      } finally {
        setInitialLoading(false);
        // Reset loading state from store as well
        resetLoadingState();
      }
    }
  };

  const handleAddDocument = (type) => {
    setDocumentType(type);
    setCurrentDocument(null);
    setShowModal(true);
  };

  const handleEditDocument = (document, isPassport = false) => {
    setCurrentDocument({ ...document, isPassport });
    setDocumentType(document.type);
    setShowModal(true);
  };

  const handleSaveDocument = (documentData) => {
    const { isPassport, ...documentToSave } = documentData;
    
    // Update the appropriate list for immediate UI update
    if (isPassport || documentToSave.type === 'passport') {
      if (currentDocument && currentDocument.isPassport) {
        // Update existing passport
        const updatedPassports = passports.map(doc => 
          (doc.documentId && doc.documentId === currentDocument.documentId) || 
          (doc.fileUrl === currentDocument.fileUrl) ? documentToSave : doc
        );
        setPassports(updatedPassports);
      } else {
        // Add new passport
        setPassports([...passports, documentToSave]);
      }
      setUpdatedDocType('passport');
    } else {
      if (currentDocument && !currentDocument.isPassport) {
        // Update existing document
        const updatedDocuments = documents.map(doc => 
          (doc.documentId && doc.documentId === currentDocument.documentId) || 
          (doc.fileUrl === currentDocument.fileUrl) ? documentToSave : doc
        );
        setDocuments(updatedDocuments);
      } else {
        // Add new document
        setDocuments([...documents, documentToSave]);
      }
      setUpdatedDocType(documentToSave.type);
    }
    
    setShowModal(false);
    
    // Ensure loading state is reset after save
    resetLoadingState();
    
    // The actual saving to backend is handled by the DocumentModal via Zustand
    console.log('Document saved:', documentToSave);
  };

  const handleDeleteDocument = async (document, isPassport = false) => {
    if (window.confirm('Are you sure you want to remove this document?')) {
      setIsDeleting(true);
      setDeleteId(document.documentId || document.fileUrl);
      setDeleteError(null);
      
      try {
        // Call the delete function from store
        const result = await deleteDocument(customerId, {
          ...document,
          isPassport,
          documentId: document.documentId,
          fileUrl: document.fileUrl
        });
        
        if (result.success) {
          // Update will happen via the customerInfo change
          console.log('Document deleted successfully');
        } else {
          setDeleteError(result.error || 'Failed to delete document');
        }
      } catch (error) {
        console.error('Error deleting document:', error);
        setDeleteError(error.message || 'An error occurred while deleting');
      } finally {
        setIsDeleting(false);
        setDeleteId(null);
        // Reset loading state from store
        resetLoadingState();
      }
    }
  };

  // Group related documents (like Ghana Card front and back)
  const groupRelatedDocuments = (docs) => {
    const grouped = {};
    
    docs.forEach(doc => {
      if (doc.type === 'Ghana Card' || doc.type === 'Ghana Card Back') {
        if (!grouped['Ghana Card']) {
          grouped['Ghana Card'] = [];
        }
        grouped['Ghana Card'].push(doc);
      } else {
        if (!grouped[doc.type]) {
          grouped[doc.type] = [];
        }
        grouped[doc.type].push(doc);
      }
    });
    
    return grouped;
  };

  const groupedDocuments = groupRelatedDocuments(documents);
  const isUpdatedType = (type) => updatedDocType === type || (type === 'Ghana Card' && (updatedDocType === 'Ghana Card' || updatedDocType === 'Ghana Card Back'));

  // Loading state view
  if (initialLoading) {
    return (
      <div className="bg-white rounded-lg p-6 shadow-sm">
        <div className="flex justify-center items-center py-10">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
        <div className="text-center text-gray-500">Loading documents...</div>
      </div>
    );
  }

  // Error state view
  if (fetchError) {
    return (
      <div className="bg-white rounded-lg p-6 shadow-sm">
        <div className="bg-red-100 text-red-700 p-4 rounded-md mb-4">
          <h3 className="font-medium mb-2">Error Loading Documents</h3>
          <p>{fetchError}</p>
        </div>
        <div className="flex justify-center">
          <button
            onClick={handleRefreshData}
            className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded flex items-center"
          >
            <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
            </svg>
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-white rounded-lg p-6 shadow-sm">
        <div className="flex justify-between items-center mb-4">
          <h3 className="font-medium">Travel Documents</h3>
          <div className="flex space-x-2">
            <button
              onClick={handleRefreshData}
              className="px-3 py-1 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded flex items-center"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <svg className="animate-spin w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Refreshing...
                </>
              ) : (
                <>
                  <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                  </svg>
                  Refresh
                </>
              )}
            </button>
            <button
              className="px-3 py-1 bg-green-500 hover:bg-green-600 text-white rounded flex items-center"
              onClick={() => handleAddDocument('')}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Loading...
                </>
              ) : (
                <>
                  <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                  </svg>
                  Add
                </>
              )}
            </button>
          </div>
        </div>

        {/* Error message */}
        {deleteError && (
          <div className="mb-4 p-3 bg-red-100 text-red-700 rounded">
            {deleteError}
          </div>
        )}

        {/* Passports Section */}
        <div className={`mb-6 ${isUpdatedType('passport') ? 'border-2 border-blue-500 p-4 rounded-lg transition-all duration-300' : ''}`}>
          <div className="flex justify-between items-center">
            <h4 className={`text-lg font-medium mb-3 ${isUpdatedType('passport') ? 'text-blue-600' : ''}`}>Passports</h4>
            {isUpdatedType('passport') && (
              <div className="bg-blue-100 text-blue-700 px-2 py-1 rounded text-sm mb-3">
                Updated successfully!
              </div>
            )}
          </div>
          
          {passports.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Passport Number</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date Issued</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Expiry Date</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {passports.map((passport, index) => (
                    <tr key={passport.documentId || index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {passport.passportNumber || 'N/A'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {passport.passportDateIssued || passport.dateIssued || 'N/A'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {passport.passportDateExpirey || passport.dateExpire || 'N/A'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        {passport.isActive ? (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            Active
                          </span>
                        ) : (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                            Inactive
                          </span>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <button 
                          onClick={() => handleEditDocument(passport, true)}
                          className="text-indigo-600 hover:text-indigo-900 mr-3"
                          disabled={isLoading || isDeleting}
                        >
                          Edit
                        </button>
                        <a 
                          href={passport.fileUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="text-blue-600 hover:text-blue-900 mr-3"
                        >
                          View
                        </a>
                        <button 
                          onClick={() => handleDeleteDocument(passport, true)}
                          className="text-red-600 hover:text-red-900 inline-flex items-center"
                          disabled={isLoading || (isDeleting && deleteId === (passport.documentId || passport.fileUrl))}
                        >
                          {isDeleting && deleteId === (passport.documentId || passport.fileUrl) ? (
                            <>
                              <svg className="animate-spin -ml-1 mr-1 h-4 w-4 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>
                              Deleting...
                            </>
                          ) : (
                            'Delete'
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-center text-gray-500 p-4 border rounded-md">No passports uploaded</div>
          )}
        </div>

        {/* Other Documents Section */}
        <div>
          <h4 className="text-lg font-medium mb-3">Other Documents</h4>
          {Object.keys(groupedDocuments).length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {Object.entries(groupedDocuments).map(([type, docs]) => (
                <div key={type} className={`border rounded-lg p-4 ${isUpdatedType(type) ? 'border-2 border-blue-500 transition-all duration-300' : ''}`}>
                  <div className="flex justify-between items-center">
                    <h5 className={`font-medium mb-2 ${isUpdatedType(type) ? 'text-blue-600' : ''}`}>{type}</h5>
                    {isUpdatedType(type) && (
                      <div className="bg-blue-100 text-blue-700 px-2 py-1 rounded text-sm mb-2">
                        Updated!
                      </div>
                    )}
                  </div>
                  
                  {/* Ghana Card special handling to show front/back */}
                  {type === 'Ghana Card' ? (
                    <div className="space-y-3">
                      {docs.map((doc, idx) => (
                        <div key={doc.documentId || idx} className="flex justify-between items-center">
                          <div>
                            <span className="text-sm font-medium">{doc.type === 'Ghana Card Back' ? 'Back' : 'Front'}</span>
                            <p className="text-xs text-gray-500">{doc.date_uploaded}</p>
                          </div>
                          <div className="flex space-x-2">
                            <button 
                              onClick={() => handleEditDocument(doc)}
                              className="text-xs text-indigo-600 hover:text-indigo-900"
                              disabled={isLoading || isDeleting}
                            >
                              Edit
                            </button>
                            <a 
                              href={doc.fileUrl}
                              target="_blank"
                              rel="noreferrer"
                              className="text-xs text-blue-600 hover:text-blue-900"
                            >
                              View
                            </a>
                            <button 
                              onClick={() => handleDeleteDocument(doc)}
                              className="text-xs text-red-600 hover:text-red-900 inline-flex items-center"
                              disabled={isLoading || (isDeleting && deleteId === (doc.documentId || doc.fileUrl))}
                            >
                              {isDeleting && deleteId === (doc.documentId || doc.fileUrl) ? (
                                <>
                                  <svg className="animate-spin -ml-1 mr-1 h-3 w-3 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                  </svg>
                                  Deleting...
                                </>
                              ) : (
                                'Delete'
                              )}
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="space-y-3">
                      {docs.map((doc, idx) => (
                        <div key={doc.documentId || idx} className="flex justify-between items-center">
                          <div>
                            <p className="text-xs text-gray-500">{doc.date_uploaded}</p>
                          </div>
                          <div className="flex space-x-2">
                            <button 
                              onClick={() => handleEditDocument(doc)}
                              className="text-xs text-indigo-600 hover:text-indigo-900"
                              disabled={isLoading || isDeleting}
                            >
                              Edit
                            </button>
                            <a 
                              href={doc.fileUrl}
                              target="_blank"
                              rel="noreferrer"
                              className="text-xs text-blue-600 hover:text-blue-900"
                            >
                              View
                            </a>
                            <button 
                              onClick={() => handleDeleteDocument(doc)}
                              className="text-xs text-red-600 hover:text-red-900 inline-flex items-center"
                              disabled={isLoading || (isDeleting && deleteId === (doc.documentId || doc.fileUrl))}
                            >
                              {isDeleting && deleteId === (doc.documentId || doc.fileUrl) ? (
                                <>
                                  <svg className="animate-spin -ml-1 mr-1 h-3 w-3 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                  </svg>
                                  Deleting...
                                </>
                              ) : (
                                'Delete'
                              )}
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center text-gray-500 p-4 border rounded-md">No other documents uploaded</div>
          )}
        </div>
      </div>

      {showModal && (
        <DocumentModal
          isOpen={showModal}
          onClose={() => {
            setShowModal(false);
            resetLoadingState();
          }}
          onSave={handleSaveDocument}
          document={currentDocument}
          initialDocumentType={documentType}
          customerId={customerId}
        />
      )}
    </>
  );
};

export default DocumentsSection;