import React from "react";

const ListingESIMLoader = ({length}:{length: number}) => {
  return (
    <div className="grid grid-cols-5 gap-8 p-4">
      {Array.from({ length: length}).map((_, index) => (
        <div
          key={index}
          className="flex items-center p-4 border rounded-lg shadow-sm bg-gray-200 animate-pulse w-full h-20"
        >
          <div className="w-16 h-10 bg-gray-300 rounded-full mr-4"></div>
          <div className="flex-1">
            <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
            <div className="h-3 bg-gray-300 rounded w-1/2"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ListingESIMLoader;
