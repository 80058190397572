import { useState, useEffect } from "react";
import { CheckCircle } from "lucide-react";
import { useESIMStore } from "../../../hooks/esimLead"; // Adjust path if necessary
import { Dialog, Tab } from "@headlessui/react"; // Example using Headless UI - adjust imports based on your UI library
import { RegisterForm } from "./RegisterForm"; // Adjust path if necessary
import { LoginForm } from "./LoginForm"; // Adjust path if necessary
import SimDetailLoader from "./Loaders/ESIMDetailLoader";
import { count } from "console";

//import { TransactionFailed } from "../containers/LandingPages/LandingPageSections/SiteError";

export default function EsimCard({ selData, openModal, setPurchaseDetails }) {
  const { products, isProductsLoading, fetchProducts } = useESIMStore();
  const [selectedPlan, setSelectedPlan] = useState(selData);
  const [quantity, setQuantity] = useState(1);
  const [esimPlans, setEsimPlans] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isUnlimited, setIsUnlimited] = useState(false);

  const [isLoginUser, setIsLoginUser] = useState(false); // Changed to default false

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  useEffect(() => {
    if (products && products.length > 0) {
      const esimProducts = products.filter(
        (product) => product.product_type === "esim"
      );
      const formattedPlans = esimProducts
        .map((product) => {
          const parts = product.title.split(" - ");
          const displayTitle = product.title;
          return {
            title: displayTitle,
            price: `$${parseFloat(product.cost).toFixed(2)}`,
            sku: product.sku,
            cost: parseFloat(product.cost),
            size: product.size,
          };
        })
        .sort((a, b) => b.cost - a.cost); // Sort by cost (descending)

      //console.log(products);

      setEsimPlans(formattedPlans);
      if (formattedPlans.length > 0) {
        setSelectedPlan(formattedPlans[0].title);
      }
    }
  }, [products]);

  useEffect(() => {
    // Update total price when selectedPlan or quantity changes
    if (selectedPlan && esimPlans.length > 0) {
      const selectedPlanData = esimPlans.find(
        (plan) => plan.title === selectedPlan
      );
      if (selectedPlanData) {
        setTotalPrice(selectedPlanData.cost * quantity);
      }
    }
  }, [selectedPlan, quantity, esimPlans]);

  useEffect(() => {
    if (selData?.simDetails?.data_plan?.toLowerCase().includes("unlimited data")) {
      setIsUnlimited(true);
    } else {
      setIsUnlimited(false);
    }
  }, []);

  const handleBuyNow = () => {
    setPurchaseDetails((prev) => ({
      ...prev,
      message: "Purchase Details:",
      simDetails: {
        ...prev.simDetails, // Preserve other properties inside simDetails
        totalPrice: totalPrice,
        data_plan: esimPlans.find((plan) => plan.title === selectedPlan),
        count: quantity,
      },
      // Keeping quantity assignment if it's outside simDetails
    }));

    if (!isLoginUser) {
      openModal(); // Open the login modal
      return; // Prevent purchase if not logged in
    }
  };

  if (isProductsLoading) {
    return <SimDetailLoader />;
  }

  if (esimPlans.length === 0) {
    return <p>No eSIM plans available.</p>;
  }

  // const nonExpiryPlan = [
  //   "Non-Expiry eSIM + 20 min + 1GB Data",
  //   "Non-Expiry eSIM + 20 min + 2GB Data",
  //   "Non-Expiry eSIM + 20 min + 3GB Data",
  //   "Non-Expiry eSIM + 4GB Data"
  // ]

 
  const handlePlanSelect = (title) => {
    setSelectedPlan(title);
  };

  return (
    <div className="w-full md:p-6 pt-8 rounded-2xl ">
      <h2 className="text-lg font-bold text-green-600 mb-2">
        {/* <span className="text-[#03844e]">{selData.displayName} eSIM</span> Plan */}
      </h2>
      <p className="text-gray-700 font-medium mb-4">Select Data Plan for Your Trip:</p>
      <div className="space-y-2 mb-10">
        {esimPlans.map((plan) => (
          <div
            key={plan.title}
            onClick={() => handlePlanSelect(plan.title)}
            className={`flex justify-between items-center p-3 border rounded-lg cursor-pointer transition ${
              selectedPlan === plan.title
                ? "bg-[#03844e] text-white"
                : "bg-gray-100 text-gray-700"
            }`}
          >
            { isUnlimited ? (
              <>
                <div>
                  <p className="text-sm font-normal ">{plan.title}</p>
                </div>
                <div className="flex text-base font-medium  items-center gap-2">
                  <span>{plan.price}</span>
                  {selectedPlan === plan.title && (
                    <CheckCircle className="w-5 h-5" />
                  )}
                </div>
              </>
            ) : (
              <>
                <div>
                  <p className="text-sm font-normal">
                    Non-Expiry eSIM {plan.size === "1GB" ? "" : "+ 20 min"} +{" "}
                    {plan.size} data
                  </p>
                </div>

                <div className="flex text-base font-medium  items-center gap-2">
                  <span>{plan.price}</span>
                  {selectedPlan === plan.title && (
                    <CheckCircle className="w-5 h-5" />
                  )}
                </div>
              </>
            )}
          </div>
        ))}
      </div>
      <span className="w-16 font-medium mt-20 ">Select number of eSIM</span>
      <div className="flex items-center justify-between bg-green-50 p-5">
        <div className="flex items-center gap-3">
          <button
            onClick={() => setQuantity(Math.max(1, quantity - 1))}
            className="px-3 py-1 border rounded-md bg-green-100"
          >
            -
          </button>
          <span className="w-16 font-medium">{quantity} eSIM</span>
          <button
            onClick={() => setQuantity(quantity + 1)}
            className="px-3 py-1 border rounded-m bg-green-100"
          >
            +
          </button>
        </div>
        <div>
          <span className="w-20">
            Total:{" "}
            <span className="text-red-600 text-xl font-semibold">
              ${totalPrice.toFixed(2)}
            </span>
          </span>
        </div>
      </div>
      <div className="flex items-center justify-center mt-4">
        <button
          onClick={handleBuyNow}
          className="bg-[#03844e] text-white px-6 py-2 rounded-lg ml-2 "
        >
          Buy Now
        </button>
      </div>

      <div style={{ marginTop: "10%" }}>
        <ul className="mt-4 text-sm text-gray-600 space-y-2">
          <li className="flex items-center gap-2">✅ High-Speed 5G/4G Data</li>
          <li className="flex items-center gap-2">
            ✅ Auto-Starts Only When You Land Abroad
          </li>
          <li className="flex items-center gap-2">
            ✅ 5-Year Travel eSIM for{" "}
            <span className="text-blue-500">120 destinations</span>
          </li>
          <li className="flex items-center gap-2">
            ✅ 20 International Call Minutes
          </li>
        </ul>
      </div>

      {/* Login/Register Modal */}
      {/* <Dialog open={loginModalOpen} onClose={() => setLoginModalOpen(false)} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto max-w-md bg-white rounded-2xl p-6 shadow-xl border border-gray-200">
            <Dialog.Title className="text-lg font-bold text-gray-800 mb-4">
              Login or Register
            </Dialog.Title>
            <Tab.Group>
              <Tab.List className="flex space-x-2 rounded-xl bg-gray-100 p-1">
                <Tab className={({ selected }) =>
                  `w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700
                    ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2
                    ${selected
                      ? 'bg-white shadow'
                      : 'text-blue-100 hover:bg-white/[0.12] hover:text-blue-500'
                    }`
                }>
                  Register
                </Tab>
                <Tab className={({ selected }) =>
                  `w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700
                    ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2
                    ${selected
                      ? 'bg-white shadow'
                      : 'text-blue-100 hover:bg-white/[0.12] hover:text-blue-500'
                    }`
                }>
                  Login
                </Tab>
              </Tab.List>
              <Tab.Panels className="mt-2">
                <Tab.Panel>
                  <RegisterForm setIsLoginUser={setIsLoginUser} setLoginModalOpen={setLoginModalOpen} />
                </Tab.Panel>
                <Tab.Panel>
                  <LoginForm setIsLoginUser={setIsLoginUser} setLoginModalOpen={setLoginModalOpen} />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </Dialog.Panel>
        </div>
      </Dialog> */}

      {/* 
New code
<Dialog open={loginModalOpen} onClose={() => setLoginModalOpen(false)} className="relative z-50">
  <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
  <div className="fixed inset-0 flex items-center justify-center p-4">
    <Dialog.Panel className="mx-auto max-w-2xl w-full bg-white rounded-2xl p-6 shadow-xl border border-gray-200">
      <Dialog.Title className="text-lg font-bold text-gray-800 mb-4">
        Login or Register
      </Dialog.Title>
      <Tab.Group>
        <Tab.List className="flex space-x-2 rounded-xl bg-gray-100 p-1">
          <Tab className={({ selected }) =>
            `w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700
              ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2
              ${selected
                ? 'bg-white shadow'
                : 'text-blue-100 hover:bg-white/[0.12] hover:text-blue-500'
              }`
          }>
            Register
          </Tab>
          <Tab className={({ selected }) =>
            `w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700
              ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2
              ${selected
                ? 'bg-white shadow'
                : 'text-blue-100 hover:bg-white/[0.12] hover:text-blue-500'
              }`
          }>
            Login
          </Tab>
        </Tab.List>
        <Tab.Panels className="mt-2">
          <Tab.Panel>
            <RegisterForm setIsLoginUser={setIsLoginUser} setLoginModalOpen={setLoginModalOpen} />
          </Tab.Panel>
          <Tab.Panel>
            <LoginForm setIsLoginUser={setIsLoginUser} setLoginModalOpen={setLoginModalOpen} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Dialog.Panel>
  </div>
</Dialog> */}
    </div>
  );
}
