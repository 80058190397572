import exp from "constants";
import React from "react";

const ESIMCard: React.FC = () => {
  return (
    <div className="flex justify-center my-8">
      <div className="w-64 relative bg-gradient-to-br from-green-700 to-cyan-500 p-6 rounded-xl shadow-lg overflow-hidden">
        {/* eSIM Chip Design */}
        <div className="w-12 h-12 bg-orange-400 rounded-lg absolute top-5 left-5 transform rotate-45 shadow-lg shadow-yellow-300/50"></div>
        {/* Signal Waves */}
        <div className="w-24 h-24 border-2 border-white/30 rounded-full absolute -top-8 -right-8 animate-pulse"></div>
        <div className="w-36 h-36 border-2 border-white/20 rounded-full absolute -top-12 -right-12 animate-pulse animation-delay-500"></div>
        {/* eSIM Text */}
        <div className="font-sans text-white text-2xl font-bold mt-16 relative z-10">
          eSIM
        </div>
        {/* Subtext */}
        <div className="font-sans text-white/80 text-sm mt-2 relative z-10">
          The Future of Connectivity
        </div>
      </div>
    </div>
  );
};
export default ESIMCard;