
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomerEsim from './esims';
import CustomerInfoDisplay from "./CustomerAccDisplay";
import Documents from "./documents"
import Refunds from "./refunds"
import useAuthStore from '../../../hooks/Auths';

const UserDashboard = () => {
  const [activeTab, setActiveTab] = useState('profile');
  const [selectedLocation, setSelectedLocation] = useState(1);
  const [customerId, setCustomerId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  
  const navigate = useNavigate();
  const { user, userData } = useAuthStore();
  
  // Check authentication on component mount
  useEffect(() => {
    const checkAuth = () => {
      // If no user is logged in, redirect to login page
      if (!user) {
        navigate('/login');
        return;
      }
      
      // Get customer ID from localStorage
      const storedCustomerId = localStorage.getItem('adansi_customer_id');
      
      if (storedCustomerId) {
        setCustomerId(storedCustomerId);
        //console.log('Using customer ID from localStorage:', storedCustomerId);
      } else if (userData && userData.customerid) {
        // Fallback to userData if available
        setCustomerId(userData.customerid);
        console.log('Using customer ID from userData:', userData.customerid);
      } else {
        console.error('No customer ID available, redirecting to login');
        navigate('/login');
        return;
      }
      
      setIsLoading(false);
    };
    
    checkAuth();
  }, [user, userData, navigate]);

  // Updated menu items as requested
  const menuItems = [
    { 
      id: 'profile', 
      label: 'Profile',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
        </svg>
      ) 
    },
    { 
      id: 'documents', 
      label: 'Documents',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
      )
    },
    { 
      id: 'esim', 
      label: 'Esim',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
        </svg>
      )
    },
    { 
      id: 'refund', 
      label: 'Refunds',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <circle cx="12" cy="12" r="10" />
        <path d="M12 6v12" />
        <path d="M15 9h-6a2 2 0 0 0-2 2v0a2 2 0 0 0 2 2h6a2 2 0 0 1 2 2v0a2 2 0 0 1-2 2h-6" />
      </svg>
      )
    },
  ];

  // Handle tab change
  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  // Loading state
  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-4">
        <div className="bg-white rounded-lg shadow-lg p-8 text-center max-w-md">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#03844e] mx-auto mb-4"></div>
          <h2 className="text-xl font-semibold text-gray-700 mb-2">Loading your account...</h2>
          <p className="text-gray-500">Please wait while we retrieve your information.</p>
        </div>
      </div>
    );
  }

  // If not authenticated, this will redirect in the useEffect
  if (!user || !customerId) return null;

  return (
    <div className="flex flex-col min-h-screen bg-gray-50 md:pt-10">
      {/* Container - full width on mobile, centered on desktop */}
      <div className="mx-auto w-full md:max-w-6xl shadow-lg bg-white flex flex-col h-full">
        {/* Header - smaller on mobile */}
        <header className="bg-white border-b py-3 md:py-4 px-4 md:px-6 sticky top-0 z-20">
          <div className="flex justify-between items-center">
            <h1 className="text-lg md:text-xl font-bold text-gray-800">My Adansi Account</h1>
            <button 
              onClick={() => {
                useAuthStore.getState().clearAuth();
                navigate('/login');
              }}
              className="text-sm text-[#03844e] hover:text-[#026d40] font-medium flex items-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>
              Sign Out
            </button>
          </div>
        </header>

        {/* Content area */}
        <div className="flex flex-col">
          {/* Horizontal menu tabs for mobile and desktop */}
          <div className="bg-white border-b sticky top-16 z-10">
            <div className="flex overflow-x-auto">
              {menuItems.map((item) => (
                <button
                  key={item.id}
                  className={`flex items-center space-x-2 py-3 px-4 transition-colors whitespace-nowrap ${
                    activeTab === item.id 
                      ? 'text-[#03844e] border-b-2 border-[#03844e]' 
                      : 'text-gray-600 hover:text-gray-900 border-b-2 border-transparent'
                  }`}
                  onClick={() => handleTabChange(item.id)}
                >
                  <span className="w-5 h-5">{item.icon}</span>
                  <span className="text-sm">{item.label}</span>
                </button>
              ))}
            </div>
          </div>

          {/* Main content area */}
          <div className="flex-1 overflow-auto p-4 md:p-6 bg-gray-50">
            {activeTab === 'profile' && (
              <div className="bg-white rounded-lg shadow p-4 md:p-6">
                <CustomerInfoDisplay customerId={customerId} />
              </div>
            )}
            
            {activeTab === 'documents' && (
              <div className="bg-white rounded-lg shadow p-4 md:p-6">
                <h2 className="text-lg md:text-xl font-semibold mb-4 md:mb-6">Documents</h2>            
                <Documents customerId={customerId} />
              </div>
            )}
            
            {activeTab === 'refund' && (
              <div className="bg-white rounded-lg shadow p-4 md:p-6">
                <h2 className="text-lg md:text-xl font-semibold mb-4 md:mb-6">Refunds</h2>
                <Refunds customerId={customerId}/>
              </div>
            )}
            
            {activeTab === 'esim' && (
              <CustomerEsim customerId={customerId} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;


