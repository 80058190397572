import React from "react";

interface TransactionFailedProps {
  errorMessage?: string;
  details?: string;
  buttonText: string;
  isOpen: boolean;
  onClose: () => void;
  onRetryTransaction: () => void;
  onCancelTransaction: () => void;
}

const TransactionFailed: React.FC<TransactionFailedProps> = ({
  errorMessage = "Your payment was not completed.",
  details = "",
  buttonText,
  isOpen,
  onClose,
  onCancelTransaction,
  onRetryTransaction,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-gray-100 p-9 rounded-3xl shadow-lg max-w-md w-full">
        <h2 className="text-xl font-bold mb-1 text-red-600">Transaction Unsuccessful</h2>
        <p className="text-gray-800 text-left mx-2 p-2 mb-3">
          Your payment was not completed, and no charges have been made. If this was a mistake, you can try again.
        </p>
        <p className="text-gray-800 text-left p-2 mx-2 mb-5">
          Would you like to retry the transaction or cancel the process?
        </p>

        <div className="flex justify-between space-x-4">
          <button
            className="bg-red-600 text-white py-2 px-4 rounded-xl hover:bg-red-700"
            onClick={onCancelTransaction}
          >
            Cancel
          </button>
          <button
            className="bg-green-600 text-white py-2 px-4 rounded-xl hover:bg-green-700"
            onClick={onRetryTransaction}
          >
            Retry Payment
          </button>
        </div>
      </div>
    </div>
  );
};

interface EsimCreationFailedProps {
  isOpen: boolean;
  onClose: () => void;
  onRetryEsim: () => void;
}

const EsimCreationFailed: React.FC<EsimCreationFailedProps> = ({ isOpen, onClose, onRetryEsim }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-xl font-bold mb-1 text-red-600">eSIM Creation Failed</h2>
        <p className="text-gray-800 text-left mx-2 p-2 mb-3">
          Your payment was successful, but the eSIM creation process failed. Please try again. If the issue persists, contact our support team.
        </p>
        <p className="text-gray-800 text-left mx-2 p-2 mb-3">
          Visit your <a href="/esim-portal" className="text-blue-600 underline">eSIM Portal</a> for more details.
        </p>
        <div className="flex justify-between space-x-4">
          <button
            className="bg-red-600 text-white py-2 px-4 rounded-xl hover:bg-red-700"
            onClick={onClose}
          >
            Close
          </button>
          <button
            className="bg-green-600 text-white py-2 px-4 rounded-xl hover:bg-green-700"
            onClick={onRetryEsim}
          >
            Retry eSIM Creation
          </button>
        </div>
      </div>
    </div>
  );
};

export { TransactionFailed, EsimCreationFailed };
