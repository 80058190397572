// src/store/authStore.js
import create from 'zustand';
import { persist } from 'zustand/middleware';
import firebase from "../../api/firebase" //'../api/firebase';
import { auth } from '../../api/firebase';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

export const useAuthStore = create(
  persist(
    (set, get) => ({
      user: null,
      userData: null,
      loading: false,
      error: null,
      verificationId: null,
      
      // Clear all auth data
      clearAuth: () => {
        // Clear customer ID from localStorage
        localStorage.removeItem('adansi_customer_id');
        
        // Clear state
        set({ 
          user: null, 
          userData: null, 
          error: null, 
          verificationId: null 
        });
      },
      
      // Set loading state
      setLoading: (isLoading) => set({ loading: isLoading }),
      
      // Set error state
      setError: (errorMessage) => set({ error: errorMessage }),
      
      // Fetch user data from API
      fetchUserData: async (uid) => {
        try {
          set({ loading: true });
          const response = await axios.post(`${BASE_URL}website_fetch_customer_with_uid`, {
            authid: uid
          }, {
            headers: { 'adway-key': API_KEY }
          });
          
          if (response.data && response.data.status) {
            // Store user data in state
            set({ userData: response.data.message, loading: false, error: null });
            
            // Store customer ID in localStorage for easier access
            if (response.data.message && response.data.message.customerid) {
              localStorage.setItem('adansi_customer_id', response.data.message.customerid);
              console.log('Customer ID saved:', response.data.message.customerid);
            }
            
            return true;
          } else {
            set({ 
              loading: false, 
              error: response.data?.message || 'Failed to fetch user data' 
            });
            return false;
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          set({ 
            loading: false, 
            error: error.message || 'Failed to connect to the server' 
          });
          return false;
        }
      },
      
      // Phone authentication - send verification code
      sendPhoneVerificationCode: async (phoneNumber, recaptchaVerifier) => {
        try {
          set({ loading: true, error: null });
          
          // Set timeout for the operation
          const timeoutPromise = new Promise((_, reject) => {
            setTimeout(() => {
              reject(new Error('Request timed out. Please try again.'));
            }, 30000);
          });
          
          // Attempt to send verification code
          const sendCodePromise = auth.signInWithPhoneNumber(
            phoneNumber,
            recaptchaVerifier
          );
          
          // Race the promises
          const result = await Promise.race([sendCodePromise, timeoutPromise]);
          
          set({ 
            verificationId: result.verificationId, 
            loading: false 
          });
          
          return true;
        } catch (error) {
          console.error('Error sending verification code:', error);
          
          let errorMessage = 'Failed to send verification code';
          if (error.code === 'auth/invalid-phone-number') {
            errorMessage = 'Invalid phone number format';
          } else if (error.code === 'auth/captcha-check-failed') {
            errorMessage = 'reCAPTCHA verification failed';
          } else if (error.code === 'auth/quota-exceeded') {
            errorMessage = 'SMS quota exceeded. Please try again later';
          } else if (error.message.includes('timeout')) {
            errorMessage = 'The request timed out. Please try again';
          } else if (error.message) {
            errorMessage = error.message;
          }
          
          set({ loading: false, error: errorMessage });
          return false;
        }
      },
      
      // Phone authentication - verify code
      verifyPhoneCode: async (verificationCode) => {
        try {
          const verificationId = get().verificationId;
          
          if (!verificationId) {
            set({ error: 'Verification session expired. Please request a new code' });
            return false;
          }
          
          set({ loading: true, error: null });
          
          // Create credential
          const credential = firebase.auth.PhoneAuthProvider.credential(
            verificationId,
            verificationCode
          );
          
          // Set timeout for the operation
          const timeoutPromise = new Promise((_, reject) => {
            setTimeout(() => {
              reject(new Error('Verification timed out. Please try again.'));
            }, 30000);
          });
          
          // Attempt to sign in
          const signInPromise = auth.signInWithCredential(credential);
          
          // Race the promises
          const result = await Promise.race([signInPromise, timeoutPromise]);
          
          // Store the user
          set({ user: result.user });
          
          // Fetch user data
          const success = await get().fetchUserData(result.user.uid);
          
          return success;
        } catch (error) {
          console.error('Error verifying code:', error);
          
          let errorMessage = 'Invalid verification code';
          if (error.code === 'auth/invalid-verification-code') {
            errorMessage = 'The verification code is invalid';
          } else if (error.code === 'auth/code-expired') {
            errorMessage = 'The verification code has expired';
          } else if (error.message.includes('timeout')) {
            errorMessage = 'The verification request timed out';
          } else if (error.message) {
            errorMessage = error.message;
          }
          
          set({ loading: false, error: errorMessage });
          return false;
        }
      },
      
      // Email/password authentication
      signInWithEmailPassword: async (email, password) => {
        try {
          set({ loading: true, error: null });
          
          // Set timeout for the operation
          const timeoutPromise = new Promise((_, reject) => {
            setTimeout(() => {
              reject(new Error('Sign in request timed out. Please try again.'));
            }, 30000);
          });
          
          // Attempt to sign in
          const signInPromise = auth.signInWithEmailAndPassword(email, password);
          
          // Race the promises
          const result = await Promise.race([signInPromise, timeoutPromise]);
          
          // Store the user
          set({ user: result.user });
          
          // Fetch user data
          const success = await get().fetchUserData(result.user.uid);
          
          return success;
        } catch (error) {
          console.error('Error signing in with email/password:', error);
          
          let errorMessage = 'Authentication failed';
          if (error.code === 'auth/user-not-found') {
            errorMessage = 'No account found with this email';
          } else if (error.code === 'auth/wrong-password') {
            errorMessage = 'Incorrect password';
          } else if (error.code === 'auth/invalid-email') {
            errorMessage = 'Invalid email format';
          } else if (error.code === 'auth/too-many-requests') {
            errorMessage = 'Too many unsuccessful attempts. Try again later';
          } else if (error.message.includes('timeout')) {
            errorMessage = 'The sign in request timed out';
          } else if (error.message) {
            errorMessage = error.message;
          }
          
          set({ loading: false, error: errorMessage });
          return false;
        }
      },
      
      // Sign out
      signOut: async () => {
        try {
          await auth.signOut();
          set({ user: null, userData: null });
          return true;
        } catch (error) {
          console.error('Error signing out:', error);
          return false;
        }
      }
    }),
    {
      name: 'adansi-auth-storage', // unique name for localStorage
      getStorage: () => localStorage, // use localStorage
      partialize: (state) => ({ user: state.user, userData: state.userData }), // only persist user and userData
    }
  )
);

export default useAuthStore;