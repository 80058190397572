import { FC } from "react";
import { useNavigate } from "react-router-dom";

interface ModalProps {
  message: string;
  onClose: () => void;
}

const SuccessESIMPaymentModal: FC<ModalProps> = ({ message, onClose }) => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/login"); // Redirect to the login page
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-white bg-opacity-100">
      <div className="flex flex-col items-center bg-white rounded-xl shadow-lg p-8 max-w-md w-full mx-4 relative text-center">
        <h2 className="text-2xl font-bold text-green-600 mb-2">Success!</h2>
        <p className="text-gray-600 text-left mb-6">{message}</p>
        <span className="pb-10 text-left">
          You can also go to your portal for more information
        </span>
        <button
          onClick={handleRedirect}
          className="w-40 px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600"
        >
          Go to Portal
        </button>
      </div>
    </div>
  );
};

export default SuccessESIMPaymentModal;
