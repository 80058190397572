

import React, { useState } from 'react';
import DependantModal from './dependantModal';
import { useESIMStore } from '../../../hooks/esim';

const DependantsSection = ({ customerInfo }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedDependant, setSelectedDependant] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  // Get the deleteDependant function from Zustand store
  const deleteDependant = useESIMStore(state => state.deleteDependant);
  
  const dependants = customerInfo?.dependants || [];

  const handleAddDependant = () => {
    setSelectedDependant(null);
    setShowModal(true);
  };

  const handleEditDependant = (dependant) => {
    setSelectedDependant(dependant);
    setShowModal(true);
  };

  const handleDeleteDependant = async (dependantId) => {
    if (window.confirm('Are you sure you want to delete this dependant?')) {
      setIsDeleting(true);
      setDeleteId(dependantId);
      setDeleteError(null);
      
      try {
        const result = await deleteDependant(customerInfo.customerId, dependantId);
        
        if (!result.success) {
          setDeleteError(result.error || 'Failed to delete dependant');
        }
      } catch (error) {
        setDeleteError(error.message || 'An error occurred while deleting');
      } finally {
        setIsDeleting(false);
        setDeleteId(null);
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedDependant(null);
  };

  return (
    <div className="bg-white rounded-lg p-6 shadow-sm">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-medium">Dependants</h3>
        <button
          onClick={handleAddDependant}
          className="px-3 py-1 bg-blue-500 hover:bg-blue-600 text-white rounded flex items-center"
        >
          <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
          </svg>
          Add Dependant
        </button>
      </div>

      {deleteError && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded">
          {deleteError}
        </div>
      )}
      
      {dependants.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Full Name</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Gender</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date of Birth</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">School</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {dependants.map((dependant, index) => (
                <tr key={dependant.dependantid || index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">{dependant.dependantFullName}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-500 capitalize">{dependant.dependantGender}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-500">{dependant.dependantDateOfBirth || 'N/A'}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-500">{dependant.schoolAttending || 'N/A'}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <div className="flex space-x-2">
                      <button
                        onClick={() => handleEditDependant(dependant)}
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDeleteDependant(dependant.dependantid)}
                        className="text-red-600 hover:text-red-900 inline-flex items-center"
                        disabled={isDeleting && deleteId === dependant.dependantid}
                      >
                        {isDeleting && deleteId === dependant.dependantid ? (
                          <>
                            <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Deleting...
                          </>
                        ) : (
                          'Delete'
                        )}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-center py-8 text-gray-500">
          No dependants found. Click the "Add Dependant" button to add one.
        </div>
      )}
      
      <DependantModal
        isOpen={showModal}
        onClose={handleCloseModal}
        dependant={selectedDependant}
        customerId={customerInfo?.customerId}
      />
    </div>
  );
};

export default DependantsSection;