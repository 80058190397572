import React, { useState } from "react";
import TopDestinations from "./topRecentDestination";
import AllDestinations from "./all_destination";

import BackgroundSection from "components/BackgroundSection/BackgroundSection";

const E_SIM: React.FC = () => {

  return (
   <div id="tour-packages" className="relative py-16">
            {/* <BackgroundSection /> */}
            <TopDestinations/>
            <AllDestinations />


    </div>
  );
};

export default E_SIM;
