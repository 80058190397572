import React, { useState } from "react";
import { useESIMStore } from "../../../hooks/esimLead"; // Adjust path if necessary
import { CheckCircleIcon, XCircleIcon, ArrowPathIcon } from '@heroicons/react/24/solid';

const PhoneSupportCheck = () => {
  const {
      // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
    compatibilityResult,
      // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
    isCompatibilityLoading,
      // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
    checkCompatibility,
      // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
    resetCompatibilityResult,
  } = useESIMStore();

  const [phoneModel, setPhoneModel] = useState('');
  const [phoneModelError, setPhoneModelError] = useState('');

  const handleCheckCompatibility = () => {
    if (!phoneModel.trim()) {
      setPhoneModelError('Please enter a phone model name.');
      return;
    }

    setPhoneModelError(''); // Clear any previous error
    resetCompatibilityResult(); // Reset the result before making a new check
    checkCompatibility(phoneModel.trim()); // Trim whitespace from input
  };

  const handleClear = () => {
    setPhoneModel('');
    setPhoneModelError('');
    resetCompatibilityResult();
  };

  // Format the "howtocheck" instructions into a step-by-step list
  const formatInstructions = (instructions) => {
    if (!instructions) return null;
    const steps = instructions.split('. ').filter(step => step.trim() !== '');
    return (
      <ol className="list-decimal list-inside space-y-2">
        {steps.map((step, index) => (
          <li key={index} className="text-gray-700 dark:text-gray-300">{step}</li>
        ))}
      </ol>
    );
  };

  return (
    <div className="bg-white dark:bg-neutral-900 rounded-lg shadow-md p-6">
      <div>
        <h2 className=" text-base text-red-600 md:text-2xl font-semibold mb-6">Let's check if your phone supports eSIM</h2>
        <div className="mb-4">
          <label className="block pb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
            Enter your Phone Name
          </label>
          <input
            required
            type="text"
            placeholder="e.g., iPhone 16 Pro Max"
            value={phoneModel}
            onChange={(e) => setPhoneModel(e.target.value)}
            className={`mt-1 block w-full p-2 border ${phoneModelError ? 'border-red-500' : 'border-neutral-300 dark:border-neutral-700'} rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none`}
          />
          {phoneModelError && <p className="text-red-500 text-sm mt-1">{phoneModelError}</p>}
        </div>
        <div className="w-full border-b border-neutral-200 dark:border-neutral-700 mb-4"></div>

        {/* Show Check or Clear button based on state */}
        {!isCompatibilityLoading && !compatibilityResult && (
          <div className="flex justify-center">
            <button
              onClick={handleCheckCompatibility}
              className="px-6 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition"
              disabled={!phoneModel.trim()} // Disable if no phone model is entered
            >
              Check Now
            </button>
          </div>
        )}

        {/* Show Clear button when there's a result */}
        {compatibilityResult && !isCompatibilityLoading && (
          <div className="flex justify-center">
            <button
              onClick={handleClear}
              className="px-6 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition flex items-center"
            >
              <ArrowPathIcon className="h-5 w-5 mr-2" />
              Clear
            </button>
          </div>
        )}

        {/* Loading spinner */}
        {isCompatibilityLoading && (
          <div className="flex justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent"></div>
          </div>
        )}

        {/* Display compatibility result */}
        {compatibilityResult && !isCompatibilityLoading && (
          <div className="mt-6">
            {compatibilityResult.error ? (
              <div className="text-red-500">
                <p>Error: {compatibilityResult.error}</p>
              </div>
            ) : (
              <div>
                <div className="flex items-center mb-4">
                  {compatibilityResult.status === "Yes" ? (
                    <>
                      <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2" aria-hidden="true" />
                      <span className="text-green-500 font-semibold">eSIM Supported</span>
                    </>
                  ) : (
                    <>
                      <XCircleIcon className="h-6 w-6 text-red-500 mr-2" aria-hidden="true" />
                      <span className="text-red-500 font-semibold">eSIM Not Supported</span>
                    </>
                  )}
                </div>

                {/* Step-by-step instructions */}
                <div className="mb-4">
                  <h3 className="text-lg font-semibold mb-2">How to Check:</h3>
                  {formatInstructions(compatibilityResult.howtocheck)}
                </div>

                {/* Video guide */}
                {compatibilityResult.howtocheckvideo &&
                  compatibilityResult.howtocheckvideo !== "Not Applicable" &&
                  compatibilityResult.howtocheckvideo !== "Video not available" && (
                    <div className="mb-4">
                      <h3 className="text-lg font-semibold mb-2">Video Guide:</h3>
                      <div className="aspect-w-16 aspect-h-9">
                        <iframe
                          src={compatibilityResult.howtocheckvideo.replace("watch?v=", "embed/")}  // Convert to embed URL
                          title="YouTube video"
                          allowFullScreen
                          className="rounded-lg"
                        ></iframe>
                      </div>
                    </div>
                  )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PhoneSupportCheck;