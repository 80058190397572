

import React, { useState } from 'react';
import ParentModal from './parentModal';

const ParentsSection = ({ customerInfo }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedParent, setSelectedParent] = useState(null);
  
  const parents = customerInfo?.parents || {};
  const father = parents.father || {};
  const mother = parents.mother || {};

  const handleEditFather = () => {
    setSelectedParent({
      ...father,
      type: 'father' // Ensure type is set
    });
    setShowModal(true);
  };

  const handleEditMother = () => {
    setSelectedParent({
      ...mother,
      type: 'mother' // Ensure type is set
    });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const renderParentCard = (parent, type) => {
    const hasInfo = parent && parent.name;
    const title = type === 'father' ? 'Father Information' : 'Mother Information';
    const editFunc = type === 'father' ? handleEditFather : handleEditMother;
    
    return (
      <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
        <div className="flex justify-between items-center mb-4">
          <h4 className="text-md font-medium">{title}</h4>
          <button
            onClick={editFunc}
            className="px-3 py-1 bg-blue-500 hover:bg-blue-600 text-white rounded text-sm"
          >
            {hasInfo ? 'Edit' : 'Add'}
          </button>
        </div>
        
        {hasInfo ? (
          <div className="space-y-2">
            <div>
              <p className="text-sm text-gray-600">Name</p>
              <p className="font-medium">{parent.name}</p>
            </div>
            <div>
              <p className="text-sm text-gray-600">Contact</p>
              <p className="font-medium">{parent.contact || 'N/A'}</p>
            </div>
            {parent.email && (
              <div>
                <p className="text-sm text-gray-600">Email</p>
                <p className="font-medium">{parent.email}</p>
              </div>
            )}
            {parent.occupation && (
              <div>
                <p className="text-sm text-gray-600">Occupation</p>
                <p className="font-medium">{parent.occupation}</p>
              </div>
            )}
            {parent.address && (
              <div>
                <p className="text-sm text-gray-600">Address</p>
                <p className="font-medium">{parent.address}</p>
              </div>
            )}
          </div>
        ) : (
          <div className="text-center py-6 text-gray-500">
            No {type} information available. Click the "Add" button to add details.
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="bg-white rounded-lg p-6 shadow-sm">
      <h3 className="text-lg font-medium mb-4">Parents Information</h3>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {renderParentCard(father, 'father')}
        {renderParentCard(mother, 'mother')}
      </div>
      
      <ParentModal
        isOpen={showModal}
        onClose={handleCloseModal}
        parent={selectedParent}
        customerId={customerInfo?.customerId}
      />
    </div>
  );
};

export default ParentsSection;