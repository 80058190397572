import { useState, useEffect, FC } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Spinner from "components/Adways/ContentLoaders/formSpinner";
import PaystackHook from "components/Adways/Payment";
import { SimDataType } from "data/types";
import { TransactionFailed, EsimCreationFailed } from "./ESIMModalPages/ErrorPaymentModal";
import { useSaveESIMLeads, useUpdateESIMLeads } from "hooks/esimLead";
import SuccessESIMPaymentModal from "./ESIMModalPages/successPaymentModal";

import ts from "typescript";
import { set } from "lodash";

const API_Keys = process.env.REACT_APP_PAYSTACK_API_KEY;

export interface CheckoutBookingProps {
  className?: string;
  purchaseInfo?: SimDataType;
  onClose?: () => void;
}

const ESIMCheckout: FC<CheckoutBookingProps> = ({
  className = "",
  purchaseInfo,
  onClose,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStartTransaction, setIsStartPayment] = useState(false);
  const [isTransactionCancelled, setIsTransactionCancelled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isESIMCreationFailed, setIsESIMCreationFailed] = useState(false);
  const [saveLeadsData, setSaveLeadsData] = useState({});
  const [updateLeadsData, setUpdateLeadsData] = useState([]);
  const [updatedPurchaseInfo, setUpdatedPurchaseInfo] = useState(null);
  const [email, setEmail] = useState("");

  const { isLoading, data, submitted, isError, error } = useSaveESIMLeads(
    saveLeadsData,
    "https://workstation.adansitravels.com/save_esim_leads"
  );

  const { isLoadingUpdate, updated, updateFailed } = useUpdateESIMLeads(
    updateLeadsData,
    "https://workstation.adansitravels.com/api_purchase_sim_card"
  );

  let config = {
    reference: "",
    email: email,
    amount:
      purchaseInfo?.simDetails?.totalPrice *
      purchaseInfo?.simDetails?.currencyRate *
      100,
    currency: "GHS",
    publicKey: API_Keys, // Use environment variable in production
  };

  const initialValues = {
    email: "",
    surname: "",
    othernames: "",
    phone: "",
  };

  const onSubmit = async (values: typeof initialValues) => {
    setIsSubmitting(true);
    try {
      const newPurchaseInfo = {
        ...purchaseInfo,
        surname: values.surname,
        othernames: values.othernames,
        email: values.email,
        phone: values.phone,
        simDetails: {
          ...purchaseInfo?.simDetails,
        },
      };

      setUpdatedPurchaseInfo(newPurchaseInfo); // Store it in state

      setEmail(values.email);

      setSaveLeadsData(newPurchaseInfo);
      //console.log("newPurchaseInfo", newPurchaseInfo);
    } catch (error) {
      console.error("Form submission error: ", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (submitted) {
     //console.log("Submitted", data);

      //@ts-ignore
      config.reference = data.data.leadid;

      setIsStartPayment(true);
    }
   
  }, [submitted]);

  
  const onSuccessPaystack = (reference) => {
    // Pass reference to hook after success call.
    
    setIsStartPayment(false);
    setUpdateLeadsData({
      //@ts-ignore
      Id: updatedPurchaseInfo?.simDetails?.data_plan.sku,
      //@ts-ignore
      leadid: data.data.leadid,
      reference: reference.reference,
      plan: updatedPurchaseInfo?.simDetails?.data_plan,
    });
  };
  
  //console.log("data", updatedPurchaseInfo?.simDetails?.data_plan);

  useEffect(() => {
    if (isError) {
      setIsTransactionCancelled(true);
    }

    //@ts-ignore
    if (submitted && !updated ) {
      setIsESIMCreationFailed(true);
    }
    //@ts-ignore
    else if (updated) {
      setIsModalOpen(true);
      setIsStartPayment(false);
    }
    else if (updateFailed) {
      setIsESIMCreationFailed(true);
    }

  }, [error, updated, updateFailed]);


  if (isLoading || isLoadingUpdate) return (<div className="flex justify-center items-center w-full h-full"><Spinner /></div>);

  return (
    <div className="mx-auto h-full md:w-xl bg-gray-100 bg-opacity-50 p-2 md:p-10">
      <div className="justify-content-center mx-auto row">
        <div className="col-12 col-md-8">
          <h3 className="text-left md:text-center text-xl md:text-2xl p-1 text-gray-600 mt-0 mb-10 font-semibold">
            CHECKOUT PAGE
          </h3>
          <div className="text-left card">
            <div className="card-body">
              <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {({ setFieldValue, values }) => (
                  <Form className="grid md:mx-10 mx-2 grid-cols-1 md:grid-cols-2 gap-5">
                    <FormField name="surname" label="surname" />
                    <FormField name="othernames" label="other Names" />
                    <FormField name="email" label="Email" type="email" />
                    <div className="mb-1 sm:ml-3">
                      <label className="form-label font-normal text-black">
                        Phone Number<span className="text-red-600">*</span>
                      </label>
                      <PhoneInput
                        country={"gh"}
                        value={values.phone}
                        onChange={(phone) => setFieldValue("phone", phone)}
                        inputStyle={{ width: "100%" }}
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                    </div>
                    <div className="col-span-1 md:col-span-2 text-center mt-4 flex justify-center gap-4">
                      {/* Close Button (Gray, Positioned to the Left) */}
                      <button
                        type="button"
                        onClick={onClose}
                        className="px-4 py-2 rounded bg-gray-300 text-white hover:bg-gray-400 transition duration-300"
                      >
                        back
                      </button>

                      {/* Submit Button */}
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="px-4 py-2 rounded bg-green-600 text-white hover:bg-green-700 transition duration-300"
                      >
                        {isSubmitting ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
          <SuccessESIMPaymentModal
          message="Your eSIM purchase was successful. Check your email for confirmation and installation instructions."
          onClose={() => setIsModalOpen(false)}
        />
      )}

      {isStartTransaction && (
        <PaystackHook
          config={config}
          onClose={() => {setIsStartPayment(false); setIsTransactionCancelled(true)}}
          onSuccess={onSuccessPaystack}
        />
      )}

      {isTransactionCancelled && (
        <TransactionFailed
          details={purchaseInfo?.othernames || "Transaction"}
          buttonText="Try Again"
          isOpen={isTransactionCancelled}
          onClose={() => setIsTransactionCancelled(false)}
          onRetryTransaction={() => {setIsStartPayment(true); setIsTransactionCancelled(false)}}
          onCancelTransaction={() => setIsTransactionCancelled(false)}
        />
      )}

      { isESIMCreationFailed && (
          <EsimCreationFailed
            isOpen={isESIMCreationFailed}
            onClose={() => setIsESIMCreationFailed(false)}
            onRetryEsim={() => {setIsStartPayment(true); setIsESIMCreationFailed(false)}}
          />
      )
      }
    </div>
  );
};

const FormField = ({ name, label, type = "text" }) => (
  <div className="mb-1 sm:ml-3">
    <label className="form-label font-normal text-black">
      {label} <span className="text-red-600">*</span>
    </label>
    <Field
      type={type}
      name={name}
      className="form-control w-full p-2 border border-gray-300 rounded-md"
    />
    <ErrorMessage
      name={name}
      component="div"
      className="text-red-600 text-sm font-light"
    />
  </div>
);

export default ESIMCheckout;
