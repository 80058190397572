import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bookingEngineUrl: "https://workstation.adansitravels.com",
  selected_esim: null, // Initialize as null or an empty object if needed
};

const ESIMSlice = createSlice({
  name: 'ESIM',
  initialState,
  reducers: {
    setSelectedESIM: (state, action) => {
      state.selected_esim = action.payload; // Correctly update state with payload data
    },
  },
});

export default ESIMSlice.reducer;
export const { setSelectedESIM } = ESIMSlice.actions;
