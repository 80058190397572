import React, { useState, useEffect } from 'react';
import { useESIMStore } from "../../../hooks/esim"; 

const PaymentMethodModal = ({ isOpen, onClose, onSave, existingPaymentInfo, customerId }) => {
  // Connect to the store for payment info updates and customer info fetching
  const { 
    updateCustomerPaymentInfo, 
    isUpdatingPaymentInfo,
    fetchCustomerInfo 
  } = useESIMStore(state => ({
    updateCustomerPaymentInfo: state.updateCustomerPaymentInfo,
    isUpdatingPaymentInfo: state.isUpdatingPaymentInfo,
    fetchCustomerInfo: state.fetchCustomerInfo
  }));
  
  // Initialize with existing payment info or defaults
  const [formData, setFormData] = useState({
    paymentMode: existingPaymentInfo?.paymentMode || 'Mobile Money',
    momoName: existingPaymentInfo?.momoName || '',
    momo_number: existingPaymentInfo?.momo_number || '',
    bankNameGHS: existingPaymentInfo?.bankNameGHS || '',
    bankNumberGHS: existingPaymentInfo?.bankNumberGHS || '',
    bankAccountNameGHS: existingPaymentInfo?.bankAccountNameGHS || '',
    branchGHS: existingPaymentInfo?.branchGHS || '',
    bankNameUSD: existingPaymentInfo?.bankNameUSD || '',
    bankNumberUSD: existingPaymentInfo?.bankNumberUSD || '',
    bankAccountNameUSD: existingPaymentInfo?.bankAccountNameUSD || '',
    branchUSD: existingPaymentInfo?.branchUSD || ''
  });
  
  // Set initial active tab based on existing data
  const getInitialActiveTab = () => {
    if (existingPaymentInfo?.momoName && existingPaymentInfo?.momo_number) {
      return 'momo';
    } else if (existingPaymentInfo?.bankNameGHS && existingPaymentInfo?.bankNumberGHS) {
      return 'ghsBank';
    } else if (existingPaymentInfo?.bankNameUSD && existingPaymentInfo?.bankNumberUSD) {
      return 'usdBank';
    }
    return 'momo'; // Default tab
  };
  
  const [activeTab, setActiveTab] = useState(getInitialActiveTab);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetchingBeforeClose, setIsFetchingBeforeClose] = useState(false);

  // Reset form when modal opens/closes or when existingPaymentInfo changes
  useEffect(() => {
    if (isOpen && existingPaymentInfo) {
      setFormData({
        paymentMode: existingPaymentInfo.paymentMode || 'Mobile Money',
        momoName: existingPaymentInfo.momoName || '',
        momo_number: existingPaymentInfo.momo_number || '',
        bankNameGHS: existingPaymentInfo.bankNameGHS || '',
        bankNumberGHS: existingPaymentInfo.bankNumberGHS || '',
        bankAccountNameGHS: existingPaymentInfo.bankAccountNameGHS || '',
        branchGHS: existingPaymentInfo.branchGHS || '',
        bankNameUSD: existingPaymentInfo.bankNameUSD || '',
        bankNumberUSD: existingPaymentInfo.bankNumberUSD || '',
        bankAccountNameUSD: existingPaymentInfo.bankAccountNameUSD || '',
        branchUSD: existingPaymentInfo.branchUSD || ''
      });
      setActiveTab(getInitialActiveTab());
    }
  }, [isOpen, existingPaymentInfo]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Clear errors for this field
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null
      });
    }
    
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateForm = () => {
    const newErrors = {};
    
    // Validate based on active tab
    if (activeTab === 'momo') {
      if (!formData.momoName || formData.momoName.trim() === '') {
        newErrors.momoName = 'Name is required';
      }
      
      if (!formData.momo_number || formData.momo_number.trim() === '') {
        newErrors.momo_number = 'Number is required';
      } else if (!/^\d{9,12}$/.test(formData.momo_number.replace(/\s/g, ''))) {
        newErrors.momo_number = 'Please enter a valid mobile money number';
      }
    } else if (activeTab === 'ghsBank') {
      if (!formData.bankNameGHS || formData.bankNameGHS.trim() === '') {
        newErrors.bankNameGHS = 'Bank name is required';
      }
      
      if (!formData.bankNumberGHS || formData.bankNumberGHS.trim() === '') {
        newErrors.bankNumberGHS = 'Account number is required';
      }
      
      if (!formData.bankAccountNameGHS || formData.bankAccountNameGHS.trim() === '') {
        newErrors.bankAccountNameGHS = 'Account name is required';
      }
    } else if (activeTab === 'usdBank') {
      if (!formData.bankNameUSD || formData.bankNameUSD.trim() === '') {
        newErrors.bankNameUSD = 'Bank name is required';
      }
      
      if (!formData.bankNumberUSD || formData.bankNumberUSD.trim() === '') {
        newErrors.bankNumberUSD = 'Account number is required';
      }
      
      if (!formData.bankAccountNameUSD || formData.bankAccountNameUSD.trim() === '') {
        newErrors.bankAccountNameUSD = 'Account name is required';
      }
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Custom close handler that fetches latest data before closing
  const handleCloseWithRefresh = async () => {
    // Only do the refresh if we're not already submitting or fetching
    if (!isSubmitting && !isUpdatingPaymentInfo && !isFetchingBeforeClose) {
      setIsFetchingBeforeClose(true);
      
      try {
        // Fetch the latest customer info
        await fetchCustomerInfo(customerId);
        
        // Now call the original onClose
        onClose();
      } catch (error) {
        console.error("Error refreshing customer data before closing:", error);
        // Still close the modal even if the refresh fails
        onClose();
      } finally {
        setIsFetchingBeforeClose(false);
      }
    } else {
      // If we're already in a loading state, just close without refreshing
      onClose();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Payment modal form submitted");
    setIsSubmitting(true);
    
    if (!validateForm()) {
      setIsSubmitting(false);
      return;
    }
    
    // Prepare payment info based on active tab
    let payInfo = {};
    
    if (activeTab === 'momo') {
      payInfo = {
        paymentMode: formData.paymentMode,
        momoName: formData.momoName,
        momo_number: formData.momo_number,
        // Keep existing bank details if any
        bankNameGHS: formData.bankNameGHS,
        bankNumberGHS: formData.bankNumberGHS,
        bankAccountNameGHS: formData.bankAccountNameGHS,
        branchGHS: formData.branchGHS,
        bankNameUSD: formData.bankNameUSD,
        bankNumberUSD: formData.bankNumberUSD,
        bankAccountNameUSD: formData.bankAccountNameUSD,
        branchUSD: formData.branchUSD
      };
    } else if (activeTab === 'ghsBank') {
      payInfo = {
        bankNameGHS: formData.bankNameGHS,
        bankNumberGHS: formData.bankNumberGHS,
        bankAccountNameGHS: formData.bankAccountNameGHS,
        branchGHS: formData.branchGHS,
        // Keep existing mobile money details if any
        paymentMode: formData.paymentMode,
        momoName: formData.momoName,
        momo_number: formData.momo_number,
        // Keep existing USD bank details if any
        bankNameUSD: formData.bankNameUSD,
        bankNumberUSD: formData.bankNumberUSD,
        bankAccountNameUSD: formData.bankAccountNameUSD,
        branchUSD: formData.branchUSD
      };
    } else if (activeTab === 'usdBank') {
      payInfo = {
        bankNameUSD: formData.bankNameUSD,
        bankNumberUSD: formData.bankNumberUSD,
        bankAccountNameUSD: formData.bankAccountNameUSD,
        branchUSD: formData.branchUSD,
        // Keep existing mobile money details if any
        paymentMode: formData.paymentMode,
        momoName: formData.momoName,
        momo_number: formData.momo_number,
        // Keep existing GHS bank details if any
        bankNameGHS: formData.bankNameGHS,
        bankNumberGHS: formData.bankNumberGHS,
        bankAccountNameGHS: formData.bankAccountNameGHS,
        branchGHS: formData.branchGHS
      };
    }
    
    try {
      // Update payment info in the database
      const result = await updateCustomerPaymentInfo(customerId, payInfo);
      
      if (result.success) {
        console.log("Payment information updated successfully");
        
        // Fetch the latest customer info to ensure we have the most up-to-date data
        await fetchCustomerInfo(customerId);
        
        // Call the onSave callback to update parent components
        onSave({
          customerId,
          payInfo
        });
      } else {
        console.error("Failed to update payment information:", result.error);
        
        // Show error to user
        setErrors({
          ...errors,
          general: result.error || 'Failed to update payment information'
        });
        
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error in payment update:", error);
      
      setErrors({
        ...errors,
        general: error.message || 'An unexpected error occurred'
      });
      
      setIsSubmitting(false);
    }
  };

  // Add an effect to reset states when unmounting or modal closes
  useEffect(() => {
    if (!isOpen) {
      setIsSubmitting(false);
      setErrors({});
      setIsFetchingBeforeClose(false);
    }
    
    return () => {
      setIsSubmitting(false);
      setErrors({});
      setIsFetchingBeforeClose(false);
    };
  }, [isOpen]);

  // Render guard - if not open, don't render anything
  if (!isOpen) {
    console.log("Payment modal is not open");
    return null;
  }

  console.log("Rendering payment modal");
  
  // Determine if buttons should be disabled
  const isDisabled = isSubmitting || isUpdatingPaymentInfo || isFetchingBeforeClose;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-lg max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">
            {existingPaymentInfo ? 'Edit Payment Method' : 'Add Payment Method'}
          </h2>
          <button 
            onClick={handleCloseWithRefresh}
            className="text-gray-500 hover:text-gray-700"
            disabled={isDisabled}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>

        {/* General error message */}
        {errors.general && (
          <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-md">
            <p>{errors.general}</p>
          </div>
        )}

        <div className="mb-4">
          <div className="flex border-b">
            <button
              type="button"
              onClick={() => setActiveTab('momo')}
              className={`px-4 py-2 font-medium text-sm ${activeTab === 'momo' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500 hover:text-gray-700'}`}
              disabled={isDisabled}
            >
              Mobile Money
            </button>
            <button
              type="button"
              onClick={() => setActiveTab('ghsBank')}
              className={`px-4 py-2 font-medium text-sm ${activeTab === 'ghsBank' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500 hover:text-gray-700'}`}
              disabled={isDisabled}
            >
              GHS Bank Account
            </button>
            <button
              type="button"
              onClick={() => setActiveTab('usdBank')}
              className={`px-4 py-2 font-medium text-sm ${activeTab === 'usdBank' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500 hover:text-gray-700'}`}
              disabled={isDisabled}
            >
              USD Bank Account
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          {/* Mobile Money Tab */}
          {activeTab === 'momo' && (
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Mobile Money Provider
                </label>
                <select
                  name="paymentMode"
                  value={formData.paymentMode || 'MTN Mobile Money'}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  disabled={isDisabled}
                >
                  <option value=""></option>
                  <option value="MTN Mobile Money">MTN Mobile Money</option>
                  <option value="Vodafone Cash">Vodafone Cash</option>
                  <option value="AirtelTigo Money">AirtelTigo Money</option>
                </select>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Account Name*
                </label>
                <input
                  type="text"
                  name="momoName"
                  value={formData.momoName || ''}
                  onChange={handleChange}
                  className={`w-full p-2 border ${errors.momoName ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  placeholder="Full name on mobile money account"
                  required
                  disabled={isDisabled}
                />
                {errors.momoName && (
                  <p className="mt-1 text-sm text-red-600">{errors.momoName}</p>
                )}
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Mobile Money Number*
                </label>
                <input
                  type="text"
                  name="momo_number"
                  value={formData.momo_number || ''}
                  onChange={handleChange}
                  className={`w-full p-2 border ${errors.momo_number ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  placeholder="Mobile number (e.g., 024XXXXXXX)"
                  required
                  disabled={isDisabled}
                />
                {errors.momo_number && (
                  <p className="mt-1 text-sm text-red-600">{errors.momo_number}</p>
                )}
              </div>
            </div>
          )}
          
          {/* Ghana Bank Account Tab */}
          {activeTab === 'ghsBank' && (
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Bank Name*
                </label>
                <input
                  type="text"
                  name="bankNameGHS"
                  value={formData.bankNameGHS || ''}
                  onChange={handleChange}
                  className={`w-full p-2 border ${errors.bankNameGHS ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  placeholder="Name of your bank"
                  required
                  disabled={isDisabled}
                />
                {errors.bankNameGHS && (
                  <p className="mt-1 text-sm text-red-600">{errors.bankNameGHS}</p>
                )}
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Account Name*
                </label>
                <input
                  type="text"
                  name="bankAccountNameGHS"
                  value={formData.bankAccountNameGHS || ''}
                  onChange={handleChange}
                  className={`w-full p-2 border ${errors.bankAccountNameGHS ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  placeholder="Name on bank account"
                  required
                  disabled={isDisabled}
                />
                {errors.bankAccountNameGHS && (
                  <p className="mt-1 text-sm text-red-600">{errors.bankAccountNameGHS}</p>
                )}
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Account Number*
                </label>
                <input
                  type="text"
                  name="bankNumberGHS"
                  value={formData.bankNumberGHS || ''}
                  onChange={handleChange}
                  className={`w-full p-2 border ${errors.bankNumberGHS ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  placeholder="Your account number"
                  required
                  disabled={isDisabled}
                />
                {errors.bankNumberGHS && (
                  <p className="mt-1 text-sm text-red-600">{errors.bankNumberGHS}</p>
                )}
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Branch (Optional)
                </label>
                <input
                  type="text"
                  name="branchGHS"
                  value={formData.branchGHS || ''}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Bank branch"
                  disabled={isDisabled}
                />
              </div>
            </div>
          )}
          
          {/* USD Bank Account Tab */}
          {activeTab === 'usdBank' && (
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Bank Name*
                </label>
                <input
                  type="text"
                  name="bankNameUSD"
                  value={formData.bankNameUSD || ''}
                  onChange={handleChange}
                  className={`w-full p-2 border ${errors.bankNameUSD ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  placeholder="Name of your bank"
                  required
                  disabled={isDisabled}
                />
                {errors.bankNameUSD && (
                  <p className="mt-1 text-sm text-red-600">{errors.bankNameUSD}</p>
                )}
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Account Name*
                </label>
                <input
                  type="text"
                  name="bankAccountNameUSD"
                  value={formData.bankAccountNameUSD || ''}
                  onChange={handleChange}
                  className={`w-full p-2 border ${errors.bankAccountNameUSD ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  placeholder="Name on bank account"
                  required
                  disabled={isDisabled}
                />
                {errors.bankAccountNameUSD && (
                  <p className="mt-1 text-sm text-red-600">{errors.bankAccountNameUSD}</p>
                )}
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Account Number*
                </label>
                <input
                  type="text"
                  name="bankNumberUSD"
                  value={formData.bankNumberUSD || ''}
                  onChange={handleChange}
                  className={`w-full p-2 border ${errors.bankNumberUSD ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  placeholder="Your account number"
                  required
                  disabled={isDisabled}
                />
                {errors.bankNumberUSD && (
                  <p className="mt-1 text-sm text-red-600">{errors.bankNumberUSD}</p>
                )}
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Branch (Optional)
                </label>
                <input
                  type="text"
                  name="branchUSD"
                  value={formData.branchUSD || ''}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Bank branch"
                  disabled={isDisabled}
                />
              </div>
            </div>
          )}

          <div className="flex justify-end gap-2 mt-6">
            <button
              type="button"
              onClick={handleCloseWithRefresh}
              className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-800 rounded disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed"
              disabled={isDisabled}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded disabled:bg-blue-300 disabled:cursor-not-allowed flex items-center justify-center min-w-[140px]"
              disabled={isDisabled}
            >
              {isDisabled ? (
                <>
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  {isFetchingBeforeClose ? 'Refreshing...' : 'Saving...'}
                </>
              ) : 'Save Payment Method'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PaymentMethodModal;