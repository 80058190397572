import React, { useState, useEffect } from 'react';
import RefundModal from './refundsModal';
import PaymentMethodModal from './refundPaymentModal';
import { useESIMStore } from "../../../hooks/esim"; 

const RefundsSection = ({ customerId }) => {
  const { 
    fetchCustomerRefunds, 
    isRefundLoading, 
    refundError, 
    customerRefunds,
    updatePaymentInfo,
    isUpdatingPaymentInfo,
    customerInfo,
    fetchCustomerInfo,
    isCustomerInfoLoading,
    fetchCustomerTransactions,
    customerTransactions,
    isTransactionsLoading,
    transactionError
  } = useESIMStore(state => ({
    fetchCustomerRefunds: state.fetchCustomerRefunds,
    isRefundLoading: state.isRefundLoading,
    refundError: state.refundError,
    customerRefunds: state.customerRefunds,
    updatePaymentInfo: state.updatePaymentInfo,
    isUpdatingPaymentInfo: state.isUpdatingPaymentInfo,
    customerInfo: state.customerInfo,
    fetchCustomerInfo: state.fetchCustomerInfo,
    isCustomerInfoLoading: state.isCustomerInfoLoading,
    fetchCustomerTransactions: state.fetchCustomerTransactions,
    customerTransactions: state.customerTransactions,
    isTransactionsLoading: state.isTransactionsLoading,
    transactionError: state.transactionError
  }));
  
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [isPaymentSectionExpanded, setIsPaymentSectionExpanded] = useState(true);
  const [isTransactionSectionExpanded, setIsTransactionSectionExpanded] = useState(true);
  const [isRefundSectionExpanded, setIsRefundSectionExpanded] = useState(true);

  // Fetch customer info, transactions, and refund history when component mounts or refresh is triggered
  useEffect(() => {
    if (customerId) {
      // Fetch all data in parallel
      fetchCustomerInfo(customerId);
      fetchCustomerRefunds(customerId);
      fetchCustomerTransactions(customerId);
    }
  }, [customerId, refreshTrigger, fetchCustomerInfo, fetchCustomerRefunds, fetchCustomerTransactions]);

  // Check if payment info exists in customer info
  const hasPaymentInfo = customerInfo && 
    customerInfo.payInfo && 
    ((customerInfo.payInfo.momoName && customerInfo.payInfo.momo_number) || 
     (customerInfo.payInfo.bankNameGHS && customerInfo.payInfo.bankNumberGHS) || 
     (customerInfo.payInfo.bankNameUSD && customerInfo.payInfo.bankNumberUSD));

  // Function to refresh data
  const refreshData = () => {
    setRefreshTrigger(prev => prev + 1);
  };

  // Toggle section expansion
  const togglePaymentSection = () => {
    setIsPaymentSectionExpanded(!isPaymentSectionExpanded);
  };

  const toggleTransactionSection = () => {
    setIsTransactionSectionExpanded(!isTransactionSectionExpanded);
  };

  const toggleRefundSection = () => {
    setIsRefundSectionExpanded(!isRefundSectionExpanded);
  };

  const handleOpenPaymentModal = () => {
    console.log('Opening payment modal');
    setShowPaymentModal(true);
  };

  const handleRefundRequest = (transaction) => {
    console.log('Handling refund request for transaction:', transaction);
    setSelectedTransaction(transaction);
    
    if (!hasPaymentInfo) {
      console.log('No payment info available, showing payment modal first');
      setShowPaymentModal(true);
      return;
    }
    
    console.log('Payment info available, showing refund modal directly');
    setShowRefundModal(true);
  };

  const handleRefundSubmit = (refundData) => {
    console.log('Processing refund:', refundData);
    
    // Close the modal first
    setShowRefundModal(false);
    setSelectedTransaction(null);
    
    // Refresh all data to reflect the new refund
    refreshData();
    
    // Ensure refund section is expanded to show the new refund
    setIsRefundSectionExpanded(true);
  };

  const handlePaymentMethodSave = async (paymentData) => {
    console.log('Saving payment method:', paymentData);
    
    // Update the payment info in the database
    const result = await updatePaymentInfo(customerId, paymentData.payInfo);
    
    if (result.success) {
      // Force refresh customer data
      refreshData();
      
      // Ensure payment section is expanded to show updates
      setIsPaymentSectionExpanded(true);
      
      setShowPaymentModal(false);
      
      // If this was called from a refund request, open the refund modal
      if (selectedTransaction) {
        console.log('Opening refund modal after saving payment info');
        setShowRefundModal(true);
      }
    } else {
      // Handle error - could display an error message to the user
      console.error('Failed to update payment information:', result.error);
    }
  };

  // Handler for closing payment modal
  const handleClosePaymentModal = () => {
    console.log('Closing payment modal and refreshing data');
    
    // Force refresh customer data
    refreshData();
    
    // Ensure payment section is expanded to show updates
    setIsPaymentSectionExpanded(true);
    
    // Close the modal
    setShowPaymentModal(false);
    
    // If there was no payment info and we're not coming from a refund request, clear selected transaction
    if (!hasPaymentInfo && selectedTransaction) {
      setSelectedTransaction(null);
    }
  };

  // Format date from seconds timestamp
  const formatDate = (seconds) => {
    if (!seconds) return 'N/A';
    
    const date = new Date(typeof seconds === 'object' && seconds._seconds ? 
      seconds._seconds * 1000 : 
      typeof seconds === 'number' ? seconds * 1000 : new Date(seconds));
      
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });
  };

  // Get status badge color based on status
  const getStatusColor = (status) => {
    switch(status?.toLowerCase()) {
      case 'completed':
        return 'bg-green-100 text-green-800';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'cancelled':
        return 'bg-red-100 text-red-800';
      case 'refunded':
      case 'processed':
        return 'bg-purple-100 text-purple-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  // Determine if a transaction is eligible for refund
  const isRefundEligible = (transaction) => {
    // Logic to determine if a transaction can be refunded
    // For demo purposes, we'll say completed transactions with payment are eligible
    return transaction.status === 'completed' && 
           (transaction.totalpaid > 0 || transaction.totalPaid > 0) && 
           !transaction.refundRequested;
  };

  const renderPaymentMethodSection = () => {
    const isDataLoading = isCustomerInfoLoading || isUpdatingPaymentInfo;
    
    return (
      <div className="mb-6 bg-gray-50 rounded-lg border border-gray-200 overflow-hidden">
        {/* Collapsible header */}
        <div 
          className="flex justify-between items-center p-4 cursor-pointer hover:bg-gray-100 transition-colors duration-150"
          onClick={togglePaymentSection}
        >
          <div className="flex items-center">
            <svg className="w-5 h-5 mr-2 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"></path>
            </svg>
            <h4 className="text-md font-medium">Payment Methods</h4>
          </div>
          <div className="flex items-center">
            {!isDataLoading && hasPaymentInfo && (
              <span className="mr-2 px-2 py-1 bg-green-100 text-green-800 text-xs rounded-full">
                {hasPaymentInfo ? 'Active' : 'Not Set'}
              </span>
            )}
            <svg 
              className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${isPaymentSectionExpanded ? 'transform rotate-180' : ''}`} 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
            </svg>
          </div>
        </div>

        {/* Collapsible content */}
        {isPaymentSectionExpanded && (
          <div className="p-4 border-t border-gray-200">
            {isDataLoading ? (
              <div className="flex justify-center py-4">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
              </div>
            ) : hasPaymentInfo ? (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-3">
                  {/* Mobile Money */}
                  {customerInfo?.payInfo?.momoName && customerInfo?.payInfo?.momo_number && (
                    <div className="bg-white p-3 rounded-md shadow-sm border border-gray-100">
                      <div className="flex items-center mb-2">
                        <svg className="w-5 h-5 mr-2 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"></path>
                        </svg>
                        <span className="font-medium">{customerInfo.payInfo.paymentMode || "Mobile Money"}</span>
                      </div>
                      <p className="text-sm text-gray-600">Name: {customerInfo.payInfo.momoName}</p>
                      <p className="text-sm text-gray-600">Number: {customerInfo.payInfo.momo_number}</p>
                    </div>
                  )}
                  
                  {/* Ghana Bank Account */}
                  {customerInfo?.payInfo?.bankNameGHS && customerInfo?.payInfo?.bankNumberGHS && (
                    <div className="bg-white p-3 rounded-md shadow-sm border border-gray-100">
                      <div className="flex items-center mb-2">
                        <svg className="w-5 h-5 mr-2 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"></path>
                        </svg>
                        <span className="font-medium">Cedis Bank Account (GHS)</span>
                      </div>
                      <p className="text-sm text-gray-600">Bank: {customerInfo.payInfo.bankNameGHS}</p>
                      {customerInfo.payInfo.bankAccountNameGHS && (
                        <p className="text-sm text-gray-600">Account Name: {customerInfo.payInfo.bankAccountNameGHS}</p>
                      )}
                      <p className="text-sm text-gray-600">Account Number: {customerInfo.payInfo.bankNumberGHS}</p>
                      {customerInfo.payInfo.branchGHS && (
                        <p className="text-sm text-gray-600">Branch: {customerInfo.payInfo.branchGHS}</p>
                      )}
                    </div>
                  )}
                  
                  {/* USD Bank Account */}
                  {customerInfo?.payInfo?.bankNameUSD && customerInfo?.payInfo?.bankNumberUSD && (
                    <div className="bg-white p-3 rounded-md shadow-sm border border-gray-100">
                      <div className="flex items-center mb-2">
                        <svg className="w-5 h-5 mr-2 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"></path>
                        </svg>
                        <span className="font-medium">USD Bank Account</span>
                      </div>
                      <p className="text-sm text-gray-600">Bank: {customerInfo.payInfo.bankNameUSD}</p>
                      {customerInfo.payInfo.bankAccountNameUSD && (
                        <p className="text-sm text-gray-600">Account Name: {customerInfo.payInfo.bankAccountNameUSD}</p>
                      )}
                      <p className="text-sm text-gray-600">Account Number: {customerInfo.payInfo.bankNumberUSD}</p>
                      {customerInfo.payInfo.branchUSD && (
                        <p className="text-sm text-gray-600">Branch: {customerInfo.payInfo.branchUSD}</p>
                      )}
                    </div>
                  )}
                </div>
                <div className="flex justify-end">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenPaymentModal();
                    }}
                    className="px-3 py-1 bg-gray-200 hover:bg-gray-300 text-gray-800 rounded text-sm flex items-center"
                    disabled={isDataLoading}
                  >
                    {isDataLoading ? (
                      <>
                        <svg className="animate-spin -ml-1 mr-2 h-3 w-3 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Updating...
                      </>
                    ) : 'Edit Payment Method'}
                  </button>
                </div>
              </>
            ) : (
              <div className="flex flex-col items-center justify-center py-4">
                <svg className="w-16 h-16 text-gray-400 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"></path>
                </svg>
                <h4 className="text-lg font-medium mb-2">No Payment Method Added</h4>
                <p className="text-gray-600 text-center mb-4">
                  You need to add a payment method to process refunds.
                </p>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenPaymentModal();
                  }}
                  className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded flex items-center"
                  disabled={isDataLoading}
                >
                  {isDataLoading ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Processing...
                    </>
                  ) : (
                    <>
                      <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                      </svg>
                      Add Payment Method
                    </>
                  )}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  // Show refund history section if there are refunds
  const renderRefundHistorySection = () => {
    return (
      <div className="mb-6 bg-gray-50 rounded-lg border border-gray-200 overflow-hidden">
        {/* Collapsible header */}
        <div 
          className="flex justify-between items-center p-4 cursor-pointer hover:bg-gray-100 transition-colors duration-150"
          onClick={toggleRefundSection}
        >
          <div className="flex items-center">
            <svg className="w-5 h-5 mr-2 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M12 15l-3-3m0 0l-3 3m3-3v6M7 7l3-3m0 0l3 3m-3-3v6"></path>
            </svg>
            <h4 className="text-md font-medium">Refund History</h4>
          </div>
          <div className="flex items-center">
            {!isRefundLoading && customerRefunds?.length > 0 && (
              <span className="mr-2 px-2 py-1 bg-purple-100 text-purple-800 text-xs rounded-full">
                {customerRefunds.length} Refunds
              </span>
            )}
            <svg 
              className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${isRefundSectionExpanded ? 'transform rotate-180' : ''}`} 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
            </svg>
          </div>
        </div>

        {/* Collapsible content */}
        {isRefundSectionExpanded && (
          <div className="border-t border-gray-200">
            {isRefundLoading ? (
              <div className="flex justify-center py-8">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
              </div>
            ) : customerRefunds && customerRefunds.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Refund ID</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Service Type</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Service Name</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Reason</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {customerRefunds.map((refund, index) => (
                      <tr key={refund.refundid || index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {refund.refundid}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {refund.serviceType}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {refund.survicename || refund.serviceName}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatDate(refund.createdAt)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {refund.currency} {refund.refundAmount?.toFixed(2)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {refund.reasons || refund.reason}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(refund.status)}`}>
                            {refund.status}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : refundError ? (
              <div className="p-6 text-center">
                <p className="text-red-500">Error: {refundError}</p>
                <button 
                  onClick={(e) => {
                    e.stopPropagation();
                    refreshData();
                  }}
                  className="mt-2 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
                >
                  Try Again
                </button>
              </div>
            ) : (
              <div className="p-6 text-center text-gray-500">
                No refund history available
              </div>
            )}
          </div>
        )}
      </div>
    );
  };


  // Render transactions section
const renderTransactionsSection = () => {
  return (
    <div className="mb-6 bg-gray-50 rounded-lg border border-gray-200 overflow-hidden">
      {/* Collapsible header */}
      <div 
        className="flex justify-between items-center p-4 cursor-pointer hover:bg-gray-100 transition-colors duration-150"
        onClick={toggleTransactionSection}
      >
        <div className="flex items-center">
          <svg className="w-5 h-5 mr-2 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path>
          </svg>
          <h4 className="text-md font-medium">Transaction History</h4>
        </div>
        <div className="flex items-center">
          {!isTransactionsLoading && customerTransactions?.length > 0 && (
            <span className="mr-2 px-2 py-1 bg-blue-100 text-blue-800 text-xs rounded-full">
              {customerTransactions.length} Transactions
            </span>
          )}
          <svg 
            className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${isTransactionSectionExpanded ? 'transform rotate-180' : ''}`} 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
          </svg>
        </div>
      </div>

      {/* Collapsible content */}
      {isTransactionSectionExpanded && (
        <div className="border-t border-gray-200">
          {isTransactionsLoading ? (
            <div className="flex justify-center py-8">
              <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-500"></div>
            </div>
          ) : customerTransactions?.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Booking ID</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Service</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Paid</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Balance</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {customerTransactions.map((transaction, index) => {
                    // Check if transaction has paid amount
                    const paidAmount = transaction.totalpaid || transaction.totalPaid || 0;
                    const isEligible = transaction.status === 'pending' && paidAmount > 0 && !transaction.refundRequested;
                    
                    return (
                      <tr key={transaction.transactionid || transaction.id || index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                          {isEligible ? (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRefundRequest(transaction);
                              }}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              Request Refund
                            </button>
                          ) : transaction.refundRequested ? (
                            <span className="text-gray-500">Refund Requested</span>
                          ) : transaction.status?.toLowerCase() === 'refunded' ? (
                            <span className="text-gray-500">Refunded</span>
                          ) : (
                            <span className="text-gray-400">Not Eligible</span>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {transaction.bookingId}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {transaction.serviceName}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {transaction.currency} {(transaction.amount || 0).toFixed(2)}
                          {transaction.discount > 0 && (
                            <span className="ml-1 text-xs text-green-600">
                              (-{transaction.discount.toFixed(2)})
                            </span>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatDate(transaction.createdAt)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {transaction.currency} {paidAmount.toFixed(2)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {transaction.currency} {(transaction.balance || 0).toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : transactionError ? (
            <div className="p-6 text-center">
              <p className="text-red-500">Error: {transactionError}</p>
              <button 
                onClick={(e) => {
                  e.stopPropagation();
                  refreshData();
                }}
                className="mt-2 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
              >
                Try Again
              </button>
            </div>
          ) : (
            <div className="p-6 text-center text-gray-500">
              No transactions found for this customer.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

  return (
    <div className="bg-white rounded-lg p-6 shadow-sm">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-medium">Payment Methods & Transactions</h3>
        <button
          onClick={refreshData}
          className="px-3 py-1 bg-blue-500 hover:bg-blue-600 text-white rounded flex items-center"
          disabled={isCustomerInfoLoading || isRefundLoading || isTransactionsLoading}
        >
          {(isCustomerInfoLoading || isRefundLoading || isTransactionsLoading) ? (
            <>
              <svg className="animate-spin mr-1 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Loading...
            </>
          ) : (
            <>
              <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
              </svg>
              Refresh
            </>
          )}
        </button>
      </div>
      
      {/* Payment Method Section - Collapsible */}
      {renderPaymentMethodSection()}
      
      {/* Transactions Section - Collapsible */}
      {renderTransactionsSection()}
      
      {/* Refund History Section - Now Collapsible */}
      {renderRefundHistorySection()}
      
      {/* Make sure modals are actually rendered when their state is true */}
      {showRefundModal && selectedTransaction && (
        <RefundModal
          isOpen={showRefundModal}
          transaction={selectedTransaction}
          paymentInfo={customerInfo?.payInfo}
          onClose={() => {
            setShowRefundModal(false);
            setSelectedTransaction(null);
          }}
          onSubmit={handleRefundSubmit}
        />
      )}
      
      {showPaymentModal && (
        <PaymentMethodModal
          isOpen={showPaymentModal}
          onClose={handleClosePaymentModal}
          onSave={handlePaymentMethodSave}
          existingPaymentInfo={customerInfo?.payInfo}
          customerId={customerId}
        />
      )}
    </div>
  );
};

export default RefundsSection;