import React, { FC, useState } from "react";
import { SimDataType } from "data/types";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Modal from "./simDetails";
import { useDispatch } from "react-redux";
import { setSelectedESIM } from "../../../redux/slices/ESIMSlice";


export interface CardBoxprops {
  className?: string;
  author: SimDataType;
  index?: number;
}

const CardAuthorBox : FC<CardBoxprops> = ({ className = "", author, index }) => {
  const { simDetails ,href, simDetails:data_plan } = author;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const saveAndRedirect = (author, url) => {
    dispatch(setSelectedESIM(author));
    navigate(url, { state: { author } });
  };
  

  const handleClick = (e) => {
    e.preventDefault(); // Prevent default behavior of the Link
    saveAndRedirect(author, `/sim-detials/${encodeURIComponent(author.simDetails.simCountry)}`);
  };

  return (
    <>
      <Link
        to={href}
        className={`nc-CardAuthorBox flex items-center p-2 border rounded-lg shadow-sm hover:shadow-md transition text-sm ${className}`}
        data-nc-id="CardAuthorBox"
        onClick={(e) => {
          e.preventDefault();
          handleClick(e);
        }}
      >
        <Avatar sizeClass="w-8 h-8 text-sm" radius="rounded-md" imgUrl={simDetails.avatar} userName={simDetails.simCountry} /> 
        <div className="ml-2">
          <h2 className="text-sm font-medium">{simDetails.simCountry}</h2>
          <span className="text-xs text-neutral-500">{simDetails.data_plan}</span>
        </div>
      </Link>
      
      
    </>
  );
};

export default CardAuthorBox ;