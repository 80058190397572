import React, { useState, useEffect } from 'react';
import { useESIMStore } from '../../../hooks/esim'; 

const CustomerEsim = ({ customerId = '' }) => {
  const [esimData, setEsimData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Get the fetchCustomerSims function from the store
  const { fetchCustomerSims } = useESIMStore();

  // Fetch data on component mount
  useEffect(() => {
    const loadData = async () => {
      if (!customerId) {
        setError("Customer ID not available");
        setLoading(false);
        return;
      }
      
      try {
        setLoading(true);
        console.log("Fetching eSIMs for customer:", customerId);
        const data = await fetchCustomerSims(customerId);
        console.log("eSIM data:", data);
        
        // Handle both array and single object responses
        const formattedData = Array.isArray(data) ? data : [data];
        setEsimData(formattedData);
        setError(null);
      } catch (err) {
        console.error("Error fetching eSIM data:", err);
        setError("Failed to load your eSIM data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [fetchCustomerSims]);

  // State to track which accordion items are expanded
  const [expandedItems, setExpandedItems] = useState({});

  // Toggle accordion expansion
  const toggleAccordion = (id) => {
    setExpandedItems(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  // Helper to get status badge color
  const getStatusColor = (status) => {
    switch(status) {
      case 'active':
        return 'bg-green-700 text-white';
      case 'pending':
        return 'bg-yellow-500 text-white';
      case 'inactive':
        return 'bg-gray-500 text-white';
      case 'expired':
        return 'bg-red-600 text-white';
      default:
        return 'bg-gray-200 text-gray-800';
    }
  };

  // Helper to format status text
  const formatStatus = (status) => {
    if (!status) return 'Unknown';
    return status.charAt(0).toUpperCase() + status.slice(1);
  };

  // Helper to format date strings
  const formatDate = (dateString) => {
    if (!dateString) return 'Not available';
    
    // Handle Firestore timestamp objects
    if (dateString._seconds) {
      const date = new Date(dateString._seconds * 1000);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    }
    
    // If it's already formatted like "March 3, 2025 at 11:04:33 AM UTC"
    if (typeof dateString === 'string' && dateString.includes('at')) {
      return dateString.split(' at')[0];
    }
    
    // Regular date string
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  // Get number of SIM cards for display
  const getSimCardsCount = (simCards) => {
    if (!simCards) return 0;
    return simCards.length;
  };

  if (loading) {
    return (
      <div className="p-4 md:p-6 bg-white rounded-lg shadow">
        <div className="flex items-center justify-center h-40">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-green-700"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 md:p-6 bg-white rounded-lg shadow">
        <div className="text-center text-red-600 p-4">
          {error}
          <button 
            className="mt-4 px-4 py-2 bg-green-700 text-white rounded-lg hover:bg-green-800 transition-colors"
            onClick={() => window.location.reload()}
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 md:p-6 bg-white rounded-lg shadow">
      <div className="mb-6">
        <h2 className="text-lg md:text-xl font-semibold mb-2">My eSIMs</h2>
        <p className="text-gray-600">Manage your electronic SIM cards for international travel</p>
      </div>
      
<div className="mb-6 flex justify-end">
        <button  onClick={(e) => {
                        e.stopPropagation();
                        window.location.href = "/e-sim";
                      }}
        className="px-4 py-2 bg-green-700 text-white rounded-lg hover:bg-green-800 transition-colors flex items-center justify-center text-sm">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
          </svg>
          Add New eSIM
        </button>
</div>
      
      {/* eSIM list */}
      <div className="space-y-4">
        {esimData.length === 0 ? (
          <div className="text-center p-8 bg-gray-50 rounded-lg">
            <p className="text-gray-600">You don't have any eSIMs yet.</p>
            <p className="text-gray-500 text-sm mt-2">Purchase your first eSIM to get started with international connectivity.</p>
          </div>
        ) : (
          esimData.map((esim, index) => (
            <div key={index} className="border rounded-lg overflow-hidden bg-white">
              {/* Accordion header */}
              <div 
                className="p-4 cursor-pointer flex justify-between items-center"
                onClick={() => toggleAccordion(index)}
              >
                <div className="flex items-center space-x-3">
                  <div className="w-10 h-10 bg-green-50 text-green-700 rounded-lg flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
                    </svg>
                  </div>
                  <div>
                    <div className="border border-red-300 rounded-full px-3 py-1 inline-block">
                      <h3 className="font-medium text-sm">{esim.esimorderid}</h3>
                    </div>
                    <div className="flex items-center mt-2">
                      <span className={`text-xs px-2 py-1 rounded-full ${getStatusColor(esim.status || esim.transaction?.status)}`}>
                        {formatStatus(esim.status || esim.transaction?.status)}
                      </span>
                    
                      <span className="text-xs text-gray-600 ml-2">
                        SIMs: {getSimCardsCount(esim.sims?.sim_cards)}
                      </span>
                    </div>
                  </div>
                </div>
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className={`h-5 w-5 text-gray-500 transition-transform ${expandedItems[index] ? 'transform rotate-180' : ''}`} 
                  viewBox="0 0 20 20" 
                  fill="currentColor"
                >
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </div>
              
              {/* Accordion content */}
              {expandedItems[index] && (
                <div className="border-t p-4">
                  {/* Transaction Details */}
                  <div className="mb-6">
                    <h4 className="font-medium mb-3 text-gray-700">Transaction Details</h4>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
                      <div className="p-3 bg-gray-50 rounded border">
                        <p className="text-xs text-gray-500">Date</p>
                        <p className="font-medium text-sm">
                          {formatDate(esim.transaction?.date || esim.createdAt)}
                        </p>
                      </div>
                      <div className="p-3 bg-gray-50 rounded border">
                        <p className="text-xs text-gray-500">Payment Method</p>
                        <p className="font-medium text-sm">
                          {esim.transaction?.paymentInfo?.paymentOption || 'N/A'} 
                          {esim.transaction?.paymentInfo?.paymentSource ? 
                            ` (${esim.transaction.paymentInfo.paymentSource})` : ''}
                        </p>
                      </div>
                      <div className="p-3 bg-gray-50 rounded border">
                        <p className="text-xs text-gray-500">Local Amount</p>
                        <p className="font-medium text-sm">
                          {esim.transaction?.paymentInfo?.currencyPaid || 'N/A'} {' '}
                          {esim.transaction?.paymentInfo?.actual_depositAmount || 'N/A'}
                        </p>
                      </div>
                      <div className="p-3 bg-gray-50 rounded border">
                        <p className="text-xs text-gray-500">USD Amount</p>
                        <p className="font-medium text-sm">
                          ${esim.transaction?.totalpaid || 'N/A'}
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  {/* SIM Cards Section */}
                  <div>
                    <h4 className="font-medium mb-3 text-gray-700">SIM Cards</h4>
                    <div className="space-y-3">
                      {esim.sims?.sim_cards?.length > 0 ? (
                        esim.sims.sim_cards.map((simCard, simIndex) => (
                          <div key={simIndex} className="border rounded p-3">
                            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                              <div className="mb-3 sm:mb-0">
                                <p className="font-medium text-sm">SIM Card #{simIndex + 1}</p>
                                <p className="text-xs text-gray-500 mt-1">ICCID: {simCard.short_iccid || simCard.iccid || 'N/A'}</p>
                              </div>
                              
                              <div className="flex space-x-2">
                                <button 
                                  className="text-xs px-3 py-1.5 bg-green-50 text-green-700 rounded border border-green-100 hover:bg-green-100"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (simCard.qrcode_url) {
                                      window.open(simCard.qrcode_url, '_blank');
                                    } else {
                                      alert('QR code not available');
                                    }
                                  }}
                                >
                                  View QR Code
                                </button>
                               
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="text-sm text-gray-500 p-3 bg-gray-50 rounded">
                          No SIM cards available
                        </div>
                      )}
                    </div>
                  </div>
                  
                  {/* Action buttons */}
                  <div className="mt-6 pt-4 border-t flex flex-wrap gap-2">
                    {(esim.status === 'pending' || esim.transaction?.status === 'pending') && (
                       <button 
                       className="text-sm px-4 py-2 bg-blue-50 text-green-700 rounded hover:bg-blue-100 border border-blue-200"
                       onClick={(e) => {
                         e.stopPropagation();
                         window.location.href = "/topup_esim";
                       }}
                     >
                       Check Balance
                     </button>
                    )}
                    {(esim.status === 'active' || esim.transaction?.status === 'active') && (
                      <button 
                      className="text-sm px-4 py-2 bg-green-50 text-green-700 rounded hover:bg-green-100 border border-green-200"
                      onClick={(e) => {
                        e.stopPropagation();
                        window.location.href = "/topup_esim";
                      }}
                    >
                      Top Up
                    </button>
                    )}
                    {/* <button 
                      className="text-sm px-4 py-2 bg-gray-50 text-gray-700 rounded hover:bg-gray-100 border border-gray-200"
                      onClick={(e) => e.stopPropagation()}
                    >
                      Support
                    </button> */}
                  </div>
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default CustomerEsim;