import CardAuthorBox from "./cardBox";
import Heading from "components/Heading/Heading";
import React, { FC, useEffect, useState } from "react";
import { useESIMStore } from "../../../hooks/esimLead";
import ListingESIMLoader from "./Loaders/ESIMLoader";

export interface OtherEsimDestinationsProps {
  className?: string;
  gridClassName?: string;
}

const OtherEsimDestinations: FC<OtherEsimDestinationsProps> = ({
  className = "",
  gridClassName = "grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-6 p-4 md:p-6 justify-center",
}) => {
    // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
  const { data, isLoading, fetchData } = useESIMStore();
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const filteredData = data.filter((destination) =>
    destination.country?.toLowerCase().includes(searchQuery.toLowerCase())
  );


  return (
    <div
      className={`nc-SectionGridAuthorBox flex flex-col items-center ${className}`}
      data-nc-id="SectionGridAuthorBox"
    >
      <Heading desc="Auto-Connect in 120 plus Destinations" isCenter className="text-base mb-4">
        All Destinations
      </Heading>

      <input
        type="text"
        placeholder="Where are you Traveling to?..."
        className="p-2 mb-4 border border-gray-300 rounded-md w-full max-w-md"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      {isLoading ? (
        <ListingESIMLoader length={12} />
      ) : (
        <div className={`grid ${gridClassName} w-full max-w-5xl`}>
          {filteredData.map((destination, index) => (
            <CardAuthorBox
              key={destination.country}
              author={{
                  surname: "",
                  othernames: "",
                  email: "",
                  phone: "",
                simDetails: {
                  avatar: destination.flag_url ?? "",
                  bgImage: "",
                  simCountry: destination.country ?? "Unknown Country",
                  data_plan: destination.data_plan ?? "No Data Plan",
                  currencyRate: 0,
                  totalPrice: undefined,
                  count: 0,
                },
              }}
              index={index < 3 ? index + 1 : undefined}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default OtherEsimDestinations;
