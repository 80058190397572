import ESIMCheckout from "../eSimCheckoutForm";
import { SimDataType } from "data/types";

type ESIMModalProps = {
  isOpen: boolean;
  onClose: () => void;
  id: string;
  purchaseDetails: SimDataType;
};

const ESIMModal: React.FC<ESIMModalProps> = ({ isOpen, onClose, id, purchaseDetails }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
      <div className="bg-white p-10 rounded-2xl shadow-lg  w-full md:max-w-4xl relative">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          &times;
        </button>

        {/* Header Section
        <h2 className="text-3xl mt-10 font-extrabold text-[#FF6B35] mb-6 text-center">
          <span className="text-[#03844e]">{id}</span> eSIM Plan
        </h2> */}

        {/* Checkout Section */}
        <div className="overflow-hidden">
          <ESIMCheckout onClose={onClose} purchaseInfo={purchaseDetails} />
        </div>
      </div>
    </div>
  );
};

export default ESIMModal;
