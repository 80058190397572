import React, { FC, Fragment, useEffect, useState } from "react";
import SimInfo from "./simInfo";
import CheckComp from "./checkCompac";
import { SimDataType } from "data/types";
import EsimCard from "./simInfo";
import ESIMCard from "./E-simcardDesigned";
import ESIMCheckout from "./eSimCheckoutForm"; // Adjust path if necessary
import { getCurrencySymbol } from "hooks/Global";
import { RootState } from "../../../redux/store"; // Adjust the import path if necessary
import Spinner from "components/Adways/ContentLoaders/formSpinner";
import { useCurrencyRate } from "hooks/leads";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import ESIMModal from "./ESIMModalPages/ESIMCheckOutModal";

export interface SimDetailsProps {
  className?: string;
  // Removed selectedData from props
}

const SimDetailsPage: FC<SimDetailsProps> = ({ className }) => {
  // Removed selectedData from props

  const { id } = useParams();
  const location = useLocation();

  // const [selectedData, setSelectedData] = useState<AuthorType>({
  //   id: "",
  //   firstName: "",
  //   lastName: "",
  //   displayName: "",
  //   currecyRate: 0,
  //   avatar: "",
  //   bgImage: "",
  //   email: "",
  //   count: 0,
  //   desc: "",
  //   jobName: "",
  //   href: "",
  //   starRating: 0,
  //   data_plan: "",
  //   phone: "",
  //   totalPrice: 0,
  // });

  //setSelectedData(useSelector((state: RootState) => state.ESIM.selected_esim)); // Access state

  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);
  //Get currenct rate
  const { currencyRate, isLoadingCurrencyRate } = useCurrencyRate();
  const [purchaseDetails, setPurchaseDetails] = useState<SimDataType>(
    location.state.author
  );

  const renderSidebar = () => {
    return (
      <div className=" dark:bg-neutral-900 rounded-lg p-4">
        {" "}
        {/* Added styling for better appearance */}
        <SimInfo
          selData={purchaseDetails}
          openModal={() => {
            setCheckoutModalOpen(true);
          }}
          setPurchaseDetails={setPurchaseDetails}
        />
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <>
        <ESIMCard />
        <CheckComp />
      </>
    );
  };

  if (isLoadingCurrencyRate) <Spinner />;

  useEffect(() => {
    setPurchaseDetails((prev) => ({
      ...prev,
      simDetails: {
        ...prev.simDetails,
        currencyRate: currencyRate?.value,
      },
    }));
  }, [currencyRate]);

  //console.log("purchaseDetails", purchaseDetails);

  return (
    <div className=" inset-0 flex items-center justify-center ">
      {/* Main Content Section */}
     
        <div className="bg-gradient-to-br from-[#FF6B35]/10 to-[#03844e]/10 w-full h-full flex flex-col md:items-center justify-center md:p-6 relative">
          <div
            className={`nc-AuthorPage ${className}`}
            data-nc-id="AuthorPage w-full h-full"
          >
            {/* Header Section */}
            <h2 className="md:w-screen p-3 mt-5 md:mt-0 md:ml-52 text-3xl font-extrabold text-[#FF6B35] lg:mb-6 text-left md:text-center lg:text-left">
              <span className="text-[#03844e]">{id}</span> eSIM Plan
            </h2>
            <main className="container pl-0 pr-0 mx-auto mt-0 mb-16 md:px-4 lg:px-8 lg:mt-1 lg:mb-24  overflow-hidden">
              <div className="grid grid-cols-1 lg:grid-cols-5 gap-8 py-8">
                {/* Sidebar Section */}
                <div className="lg:col-span-2">
                  <div className="lg:sticky lg:top-24  bg-white bg-opacity-40 p-4 md:bg-opacity-80 md:p-6 md-w-64 rounded-lg  ">
                    {renderSidebar()}
                  </div>
                </div>

                {/* Main Section */}
                <div className="lg:col-span-3 bg-white md:p-6 rounded-lg bg-opacity-70">
                  {renderSection1()}
                </div>
              </div>
            </main>
          </div>
        </div>
        {/* Checkout Modal */}
        {checkoutModalOpen && (
        // Checkout Modal
          <ESIMModal 
          id={id} 
         isOpen={true} 
        onClose={() => setCheckoutModalOpen(false)} 
        purchaseDetails={purchaseDetails} />
  
      ) }
    </div>
  );
};

export default SimDetailsPage;
