import create from 'zustand';
import axios from 'axios';

const base_url = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const STORAGE_KEY = 'esim_destinations';
const EXPIRY_KEY = 'esim_destinations_expiry';


const useESIMStore = create(( set, get ) => ({
  data: [],
  products: [],
  compatibilityResult: null, // Added state for compatibility check result
  isLoading: true,
  isProductsLoading: true,
  isCompatibilityLoading: false, // Added loading state for compatibility check
  customerSims: null,
  isCustomerSimsLoading: false,
  error: null,
  customerInfo: null,
  isCustomerInfoLoading: false,
  customerTransactions: [],
  isTransactionsLoading: false,
  transactionError: null,
  
  resetLoadingState: () => {
    set({ isLoading: false });
  },
  fetchData: async () => {
    const now = Date.now();
    const expiryTime = localStorage.getItem(EXPIRY_KEY);

    if (expiryTime && now < Number(expiryTime)) {
      const cachedData = localStorage.getItem(`${base_url}esim_destinations`);
      if (cachedData) {
        set({ data: JSON.parse(cachedData), isLoading: false });
        return;
      }
    }

    set({ isLoading: true });
    try {
      const response = await axios.get(`${base_url}get_all_esim_destinations`, {
        headers: {
          'adway-key': API_KEY,
        },
      });
      set({ data: response.data.destinations, isLoading: false });
      localStorage.setItem(STORAGE_KEY, JSON.stringify(response.data.destinations));
      localStorage.setItem(EXPIRY_KEY, (now + 12 * 60 * 60 * 1000).toString()); // 12 hours expiry
    } catch (error) {
    //  console.error('Error fetching eSIM data:', error);
      set({ isLoading: false });
    }
  },
  fetchProducts: async () => {
    set({ isProductsLoading: true });
    try {
      const response = await axios.get(`${base_url}fetch_all_esim_with_cost`, {
        headers: {
          'adway-key': API_KEY,
        },
      });
      set({ products: response.data.esimWithCost.data, isProductsLoading: false });
    } catch (error) {
     // console.error('Error fetching eSIM products:', error);
      set({ isProductsLoading: false });
    }
  },
  checkCompatibility: async (phoneModel) => {
    set({ isCompatibilityLoading: true, compatibilityResult: null }); // Reset result and set loading to true
    try {
      const response = await axios.post(
        `${base_url}check_esim_compactibility`,
        { phoneName: phoneModel },
        {
          headers: {
            'adway-key': API_KEY,
            'Content-Type': 'application/json', // Important:  Specify content type for POST requests
          },
        }
      );
     // console.log(response, phoneModel)
      set({
        compatibilityResult: response.data,
        isCompatibilityLoading: false,
      });
    } catch (error) {
     // console.error('Error checking eSIM compatibility:', error);
      set({
        compatibilityResult: { error: 'Failed to check compatibility. Please try again.' }, // Handle error appropriately
        isCompatibilityLoading: false,
      });
    }
  },
  resetCompatibilityResult: () => set({ compatibilityResult: null }),
  // Add this constant near your other URL constants

// Add these to your state properties in the create function
esimDetails: null,
isDetailsLoading: false,

// Add this function to your store
fetchESIMDetails: async (simId) => {
  set({ isDetailsLoading: true, esimDetails: null });
  try {
    const response = await axios.post(
      `${base_url}get_esim_details_with_simid`,
      { id: simId },
      {
        headers: {
          'adway-key': API_KEY,
          'Content-Type': 'application/json',
        },
      }
    );
    
    set({ 
      esimDetails: response.data, 
      isDetailsLoading: false 
    });
    
    return response.data;
  } catch (error) {
   // console.error('Error fetching eSIM details:', error);
    set({ 
      esimDetails: { error: 'Failed to fetch eSIM details. Please try again.' },
      isDetailsLoading: false 
    });
    
    throw error; // Propagate error for handling in UI components
  }
},
// Add these to your state properties


// Add this function to fetch customer SIMs with customer ID
fetchCustomerSims: async (customerId) => {
  set({ isCustomerSimsLoading: true, customerSims: null });
  try {
    const payload = { customerId , year : "2025" }
    
    const response = await axios.post(
      `${base_url}api_fetch_customer_sims_with_id`,
      payload,
      {
        headers: { 
          'adway-key': API_KEY,
          'Content-Type': 'application/json',
        },
      }
    );    
    set({ 
      customerSims: response.data.customer, 
      isCustomerSimsLoading: false 
    });
    
    return response.data.customer;
  } catch (error) {
    console.error('Error fetching customer SIMs:', error);
    set({ 
      customerSims: { error: 'Failed to fetch customer SIMs. Please try again.' },
      isCustomerSimsLoading: false 
    });
    
    throw error; // Propagate error for handling in UI components
  }
},


fetchCustomerInfo: async (customerId) => {
  set({ isCustomerInfoLoading: true, customerInfo: null });
  try {
    const response = await axios.post(
      `${base_url}api_fetch_customer_info_withid`,
      { customerId },
      {
        headers: {
          'adway-key': API_KEY,
          'Content-Type': 'application/json',
        },
      }
    );
    
    set({
      customerInfo: response.data.customer,
      isCustomerInfoLoading: false
    });
   
    return response.data.customer;
  } catch (error) {
    console.error('Error fetching customer information:', error);
    set({
      customerInfo: { error: 'Failed to fetch customer information. Please try again.' },
      isCustomerInfoLoading: false
    });
    
    throw error;
  }
},

  // New function to update personal info
  updatePersonalInfo: async (customerId, personalInfoData) => {
    try {
      set({ isLoading: true, error: null });
      
      // API endpoint URL (replace with your actual Firebase function URL)
      const endpoint = `${base_url}update_customer_personal_info`;
            
      // Make the API call
      const response = await axios.post(endpoint, 
        { 
          customerId, 
          personalInfo: personalInfoData 
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'adway-key': API_KEY
          }
        }
      );
      
      if (response.status === 200) {
        // Update the local state with the new data
        set(state => ({
          customerInfo: {
            ...state.customerInfo,
            personalInfo: {
              ...state.customerInfo.personalInfo,
              ...personalInfoData
            }
          },
          isLoading: false
        }));
        return { success: true };
      } else {
        throw new Error(response.data.message || 'Failed to update personal info');
      }
    } catch (error) {
      set({ 
        error: error.response?.data?.message || error.message, 
        isLoading: false 
      });
      return { success: false, error: error.message };
    }
  },
  updateContactInfo: async (customerId, contactInfoData) => {
    try {
      set(state => ({ ...state, isLoading: true, error: null }));
      
      // API endpoint URL
      const endpoint = `${base_url}update_customer_contact_info`;
      
      // API key from environment or config
      
      // Make the API call
      const response = await axios.post(endpoint, 
        { 
          customerId, 
          contacts: contactInfoData 
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'adway-key': API_KEY
          }
        }
      );
      
      if (response.status === 200) {
        // Update the local state with the new data
        set(state => {
          // Only update if customerInfo exists
          if (!state.customerInfo) return state;
          
          return {
            ...state,
            customerInfo: {
              ...state.customerInfo,
              contacts: {
                ...state.customerInfo.contacts,
                ...contactInfoData
              }
            },
            isLoading: false
          };
        });
        return { success: true };
      } else {
        throw new Error(response.data.message || 'Failed to update contact info');
      }
    } catch (error) {
      set(state => ({ 
        ...state,
        error: error.response?.data?.message || error.message, 
        isLoading: false 
      }));
      return { success: false, error: error.message };
    }
  },
  deleteDependant: async (customerId, dependantId) => {
    try {
      set(state => ({ ...state, isLoading: true, error: null }));
      
      // API endpoint URL
      const endpoint = `${base_url}update_customer_dependant`;
      
      // API key from environment or config
      
      // Make the API call
      const response = await axios.post(endpoint, 
        { 
          customerId,
          dependant: { dependantid: dependantId },
          operation: 'delete'
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'adway-key': API_KEY
          }
        }
      );
      
      if (response.status === 200) {
        // Update the local state with the new dependants array
        set(state => {
          // Only update if customerInfo exists
          if (!state.customerInfo) return state;
          
          return {
            ...state,
            customerInfo: {
              ...state.customerInfo,
              dependants: response.data.dependants || []
            },
            isLoading: false
          };
        });
        return { success: true };
      } else {
        throw new Error(response.data.message || 'Failed to delete dependant');
      }
    } catch (error) {
      set(state => ({ 
        ...state,
        error: error.response?.data?.message || error.message, 
        isLoading: false 
      }));
      return { success: false, error: error.message };
    }
  },
  updateDependant: async (customerId, dependantData) => {
    try {
      set(state => ({ ...state, isLoading: true, error: null }));
      
      // API endpoint URL
      const endpoint = `${base_url}update_customer_dependant`;
      
      // API key from environment or config
      
      // Make the API call
      const response = await axios.post(endpoint, 
        { 
          customerId,
          dependant: dependantData,
          operation: 'update'
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'adway-key': API_KEY
          }
        }
      );
      
      if (response.status === 200) {
        // Update the local state with the updated dependants array
        set(state => {
          // Only update if customerInfo exists
          if (!state.customerInfo) return state;
          
          return {
            ...state,
            customerInfo: {
              ...state.customerInfo,
              dependants: response.data.dependants || []
            },
            isLoading: false
          };
        });
        return { success: true };
      } else {
        throw new Error(response.data.message || 'Failed to update dependant');
      }
    } catch (error) {
      set(state => ({ 
        ...state,
        error: error.response?.data?.message || error.message, 
        isLoading: false 
      }));
      return { success: false, error: error.message };
    }
  },
  addDependant: async (customerId, dependantData) => {
    try {
      set(state => ({ ...state, isLoading: true, error: null }));
      
      // API endpoint URL
      const endpoint = `${base_url}update_customer_dependant`;
      
      // API key from environment or config
      
      // Make the API call
      const response = await axios.post(endpoint, 
        { 
          customerId,
          dependant: dependantData,
          operation: 'add'
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'adway-key': API_KEY
          }
        }
      );
      
      if (response.status === 200) {
        // Update the local state with the new dependants array
        set(state => {
          // Only update if customerInfo exists
          if (!state.customerInfo) return state;
          
          return {
            ...state,
            customerInfo: {
              ...state.customerInfo,
              dependants: response.data.dependants || []
            },
            isLoading: false
          };
        });
        return { success: true };
      } else {
        throw new Error(response.data.message || 'Failed to add dependant');
      }
    } catch (error) {
      set(state => ({ 
        ...state,
        error: error.response?.data?.message || error.message, 
        isLoading: false 
      }));
      return { success: false, error: error.message };
    }
  },
  updateParent: async (customerId, parentData) => {
    try {
      set(state => ({ ...state, isLoading: true, error: null }));
      
      // API endpoint URL
        const endpoint = `${base_url}update_customer_parent`;

      // API key from environment or config
      
      // Make sure parent type is included
      if (!parentData.type || !['father', 'mother'].includes(parentData.type)) {
        throw new Error("Parent type must be 'father' or 'mother'");
      }
      
      // Make the API call
      const response = await axios.post(endpoint, 
        { 
          customerId,
          parent: parentData
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'adway-key': API_KEY
          }
        }
      );
      
      if (response.status === 200) {
        // Update the local state with the new parents object
        set(state => {
          // Only update if customerInfo exists
          if (!state.customerInfo) return state;
          
          return {
            ...state,
            customerInfo: {
              ...state.customerInfo,
              parents: response.data.parents || {}
            },
            isLoading: false
          };
        });
        return { success: true };
      } else {
        throw new Error(response.data.message || 'Failed to update parent information');
      }
    } catch (error) {
      set(state => ({ 
        ...state,
        error: error.response?.data?.message || error.message, 
        isLoading: false 
      }));
      return { success: false, error: error.message };
    }
  },
  // Add a document to customer
  addDocument: async (customerId, documentData) => {
    try {
      set(state => ({ ...state, isLoading: true, error: null }));
    //  console.log("Adding document for customer:", customerId, documentData);
      
      // API endpoint URL
      const endpoint = 'https://us-central1-your-project-id.cloudfunctions.net/updateCustomerDocument';
      
      // API key from environment or config
      const apiKey = process.env.REACT_APP_ADWAY_API_KEY;
      
      // Make the API call
      const response = await axios.post(endpoint, 
        { 
          customerId,
          document: documentData,
          operation: 'add'
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'adway-key': apiKey
          }
        }
      );
      
     // console.log("Add document response:", response.data);
      
      if (response.status === 200) {
        // Update the local state with the new documents
        set(state => {
          // Only update if customerInfo exists
          if (!state.customerInfo) return state;
          
          return {
            ...state,
            customerInfo: {
              ...state.customerInfo,
              documents: response.data.documents || state.customerInfo.documents || [],
              passports: response.data.passports || state.customerInfo.passports || []
            },
            isLoading: false
          };
        });
        return { success: true };
      } else {
        throw new Error(response.data.message || 'Failed to add document');
      }
    } catch (error) {
      console.error("Error adding document:", error);
      set(state => ({ 
        ...state,
        error: error.response?.data?.message || error.message, 
        isLoading: false 
      }));
      return { success: false, error: error.message };
    }
  },
  
  // Update an existing document
  updateDocument: async (customerId, documentData) => {
    try {
      set(state => ({ ...state, isLoading: true, error: null }));
      //console.log("Updating document for customer:", customerId, documentData);
      
      // API endpoint URL
      const endpoint = 'https://us-central1-your-project-id.cloudfunctions.net/updateCustomerDocument';
      
      // API key from environment or config
      const apiKey = process.env.REACT_APP_ADWAY_API_KEY;
      
      // Make the API call
      const response = await axios.post(endpoint, 
        { 
          customerId,
          document: documentData,
          operation: 'update'
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'adway-key': apiKey
          }
        }
      );
      
      //console.log("Update document response:", response.data);
      
      if (response.status === 200) {
        // Update the local state with the updated documents
        set(state => {
          // Only update if customerInfo exists
          if (!state.customerInfo) return state;
          
          return {
            ...state,
            customerInfo: {
              ...state.customerInfo,
              documents: response.data.documents || state.customerInfo.documents || [],
              passports: response.data.passports || state.customerInfo.passports || []
            },
            isLoading: false
          };
        });
        return { success: true };
      } else {
        throw new Error(response.data.message || 'Failed to update document');
      }
    } catch (error) {
      console.error("Error updating document:", error);
      set(state => ({ 
        ...state,
        error: error.response?.data?.message || error.message, 
        isLoading: false 
      }));
      return { success: false, error: error.message };
    }
  },
  
  // Delete a document
  deleteDocument: async (customerId, documentData) => {
    try {
      set(state => ({ ...state, isLoading: true, error: null }));
    //  console.log("Deleting document for customer:", customerId, documentData);
      
      // API endpoint URL
      const endpoint = 'https://us-central1-your-project-id.cloudfunctions.net/updateCustomerDocument';
      
      // API key from environment or config
      const apiKey = process.env.REACT_APP_ADWAY_API_KEY;
      
      // Make the API call
      const response = await axios.post(endpoint, 
        { 
          customerId,
          document: documentData,
          operation: 'delete'
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'adway-key': apiKey
          }
        }
      );
      
   //   console.log("Delete document response:", response.data);
      
      if (response.status === 200) {
        // Update the local state with the new documents array
        set(state => {
          // Only update if customerInfo exists
          if (!state.customerInfo) return state;
          
          return {
            ...state,
            customerInfo: {
              ...state.customerInfo,
              documents: response.data.documents || state.customerInfo.documents || [],
              passports: response.data.passports || state.customerInfo.passports || []
            },
            isLoading: false
          };
        });
        return { success: true };
      } else {
        throw new Error(response.data.message || 'Failed to delete document');
      }
    } catch (error) {
      console.error("Error deleting document:", error);
      set(state => ({ 
        ...state,
        error: error.response?.data?.message || error.message, 
        isLoading: false 
      }));
      return { success: false, error: error.message };
    }
  },
  
  // Upload a document file (this would use Firebase Storage in a real app)
  uploadDocumentFile: async (file, customerId) => {
    try {
      set(state => ({ ...state, isLoading: true, error: null }));
     // console.log("Uploading document file for customer:", customerId, file.name);
      
      // In a real implementation, you would:
      // 1. Upload the file to Firebase Storage
      // 2. Get the download URL
      // 3. Return the URL for saving with the document metadata
      
      // For this example, we'll simulate a successful upload with a fake URL
      // Wait a bit to simulate upload
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Create a fake URL based on filename and timestamp
      const fileUrl = `https://firebasestorage.googleapis.com/v0/b/your-project.appspot.com/o/${encodeURIComponent(file.name)}?alt=media&token=${Date.now()}`;
      
      set(state => ({ ...state, isLoading: false }));
      
      return { 
        success: true, 
        fileUrl,
        fileName: file.name,
        contentType: file.type,
        size: file.size
      };
    } catch (error) {
      console.error("Error uploading document file:", error);
      set(state => ({ 
        ...state,
        error: error.message, 
        isLoading: false 
      }));
      return { success: false, error: error.message };
    }
  },
  fetchCustomerRefunds: async (customerId) => {
    set({ isRefundLoading: true, refundError: null });
    try {
      const response = await axios.post(
        `${base_url}get_customer_refunds`,
        { customerId },
        {
          headers: {
            'adway-key': API_KEY,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200) {
       
        set({
          customerRefunds: response.data.refunds || [],
          isRefundLoading: false
        });
        return { success: true, refunds: response.data.refunds };
      } else {
        throw new Error(response.data.message || 'Failed to fetch refunds');
      }
    } catch (error) {
      console.error('Error fetching customer refunds:', error);
      set({ 
        isRefundLoading: false, 
        refundError: error.response?.data?.message || error.message
      });
      return { 
        success: false, 
        error: error.response?.data?.message || error.message 
      };
    }
  },
  updatePaymentInfo: async (customerId, paymentData) => {
    set({ isUpdatingPaymentInfo: true, refundError: null });
    try {
      const response = await axios.post(
        `${base_url}update_customer_payment_info`,
        { 
          customerId, 
          payInfo: paymentData 
        },
        {
          headers: {
            'adway-key': API_KEY,
            'Content-Type': 'application/json',
          },
        }
      );
      
      if (response.status === 200) {
        // Update customer info in state if it exists
        set(state => {
          if (state.customerInfo) {
            return {
              ...state,
              customerInfo: {
                ...state.customerInfo,
                payInfo: paymentData
              },
              isUpdatingPaymentInfo: false
            };
          }
          return { ...state, isUpdatingPaymentInfo: false };
        });
        
        return { success: true };
      } else {
        throw new Error(response.data.message || 'Failed to update payment information');
      }
    } catch (error) {
      console.error('Error updating payment information:', error);
      set({ 
        isUpdatingPaymentInfo: false, 
        refundError: error.response?.data?.message || error.message
      });
      return { 
        success: false, 
        error: error.response?.data?.message || error.message 
      };
    }
  },
  updateCustomerPaymentInfo: async (customerId, paymentData) => {
    set({ isUpdatingPaymentInfo: true, error: null });
    try {
      // API endpoint URL
      const endpoint = `${base_url}update_customer_payment_info`;
      
     // console.log("Updating payment information for customer:", customerId, paymentData);
      
      // Make the API call
      const response = await axios.post(
        endpoint, 
        { 
          customerId, 
          payInfo: paymentData 
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'adway-key': API_KEY
          }
        }
      );
      
      if (response.status === 200) {
        // Update the local state with the new payment info
        set(state => {
          // Only update if customerInfo exists and the IDs match
          if (state.customerInfo && state.customerInfo.customerId === customerId) {
            return {
              ...state,
              customerInfo: {
                ...state.customerInfo,
                payInfo: paymentData
              },
              isUpdatingPaymentInfo: false
            };
          }
          return { ...state, isUpdatingPaymentInfo: false };
        });
        
        console.log("Payment information updated successfully");
        return { success: true, message: 'Payment information updated successfully' };
      } else {
        throw new Error(response.data.message || 'Failed to update payment information');
      }
    } catch (error) {
      console.error("Error updating payment information:", error);
      set({ 
        isUpdatingPaymentInfo: false, 
        error: error.response?.data?.message || error.message
      });
      return { 
        success: false, 
        error: error.response?.data?.message || error.message 
      };
    }
  },
  fetchCustomerTransactions: async (customerId) => {
    set({ isTransactionsLoading: true, transactionError: null });
    try {
      const response = await axios.post(
        `${base_url}fetch_customer_transactions`,
        { customerId },
        {
          headers: {
            'adway-key': API_KEY,
            'Content-Type': 'application/json',
          },
        }
      );
      
      if (response.status === 200) {
        set({
          customerTransactions: response.data.transactions || [],
          isTransactionsLoading: false
        });
        return { 
          success: true, 
          transactions: response.data.transactions 
        };
      } else {
        throw new Error(response.data.message || 'Failed to fetch transactions');
      }
    } catch (error) {
      console.error('Error fetching customer transactions:', error);
      set({ 
        isTransactionsLoading: false, 
        transactionError: error.response?.data?.message || error.message
      });
      return { 
        success: false, 
        error: error.response?.data?.message || error.message 
      };
    }
  },
  submitRefundRequest: async (refundData) => {
    set({ isSubmittingRefund: true, refundError: null });
    try {
  

      const response  = await axios.post(
        `${base_url}process_refund_request`,
        refundData,
        {
          headers: {
            'adway-key': API_KEY,
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.status === 200) {
        // Get current refunds and add the new one to the list
        const { customerRefunds } = get();
        const newRefund = response.data.refund;
        
        if (customerRefunds && customerRefunds.length > 0) {
          set({
            customerRefunds: [newRefund, ...customerRefunds],
            isSubmittingRefund: false
          });
        }
        
        return { 
          success: true, 
          refundid: newRefund.refundid 
        };
      } else {
        throw new Error(response.data.message || 'Failed to process refund');
      }
    } catch (error) {
      console.error('Error submitting refund request:', error);
      set({ 
        isSubmittingRefund: false, 
        refundError: error.response?.data?.message || error.message
      });
      return { 
        success: false, 
        error: error.response?.data?.message || error.message 
      };
    }
  },




  resetRefundError: () => set({ refundError: null })
}));

export {
    useESIMStore
  };



