

// src/components/customer/DocumentModal.jsx
import React, { useState, useEffect } from 'react';

const DocumentModal = ({ isOpen, onClose, onSave, document, initialDocumentType, customerId }) => {
  const travelDocumentTypes = [
    {label: "Passport", value: "passport"},
    {label: "Passport Photo", value: "Passport Photo"}, 
    {label: "Birth Certificate", value: "Birth Certificate"},
    {label: "Bank Statement", value: "Bank Statement"},
    {label: "Pay Slip", value: "Pay Slip"},
    {label: "Marriage Certificate", value: "Marriage Certificate"}, 
    {label: "Ghana Card", value: "Ghana Card"},
    {label: "Ghana Card Back", value: "Ghana Card Back"},
    {label: "Introductory Letter", value: "Introductory Letter from school"},
    {label: "Consent Letter(if traveling with one parent or another guardian)", value: "Consent Letter"},
  ];

  const [formData, setFormData] = useState({
    type: '',
    description: '',
    fileUrl: '',
    date_uploaded: new Date().toLocaleDateString('en-GB') + ' ' + new Date().toLocaleTimeString('en-GB'),
    passportNumber: '',
    passportDateIssued: '',
    passportDateExpirey: '',
    cardNumber: '',
    cardDateIssued: '',
    cardDateExpirey: '',
    isActive: true,
    isPassport: false // to track if it's a passport document
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);

  useEffect(() => {
    // If editing existing document, populate form
    if (document) {
      setFormData({
        ...formData,
        ...document,
        // For consistent field naming
        passportNumber: document.passportNumber || '',
        passportDateIssued: document.passportDateIssued || document.dateIssued || '',
        passportDateExpirey: document.passportDateExpirey || document.dateExpire || '',
        isPassport: document.isPassport || document.type === 'passport'
      });
    }
    
    // Set initial document type if provided
    if (initialDocumentType && !document) {
      setFormData(prev => ({
        ...prev,
        type: initialDocumentType,
        isPassport: initialDocumentType === 'passport'
      }));
    }
  }, [document, initialDocumentType]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Clear errors for this field
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null
      });
    }
    
    // Special handling for document type changes
    if (name === 'type') {
      const isPassport = value === 'passport';
      setFormData({
        ...formData,
        [name]: value,
        isPassport
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedFile(file);
      
      // Clear file error if exists
      if (errors.file) {
        setErrors({
          ...errors,
          file: null
        });
      }
      
      // In a real app, you would upload the file to storage and get back a URL
      // For now, we'll create a dummy URL
      const dummyFileUrl = `https://example.com/uploads/${file.name}`;
      setFormData({
        ...formData,
        fileUrl: dummyFileUrl
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    
    // Document type is required
    if (!formData.type) {
      newErrors.type = "Document type is required";
    }
    
    // Document file is required for new documents
    if (!document && !uploadedFile && !formData.fileUrl) {
      newErrors.file = "Please upload a document file";
    }
    
    // Passport number required for passport type
    if (formData.type === 'passport' && !formData.passportNumber) {
      newErrors.passportNumber = "Passport number is required";
    }
    
    // Card number required for Ghana Card
    if ((formData.type === 'Ghana Card' || formData.type === 'Ghana Card Back') && !formData.cardNumber) {
      newErrors.cardNumber = "Ghana Card number is required";
    }
    
    // Date validation
    if (formData.type === 'passport') {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset time to beginning of day for accurate comparison
      
      // Calculate the date one month from today
      const oneMonthFromNow = new Date(today);
      oneMonthFromNow.setMonth(today.getMonth() + 1);
      
      // Issue date required and must be in the past
      if (!formData.passportDateIssued) {
        newErrors.passportDateIssued = "Issue date is required";
      } else {
        const issueDate = new Date(formData.passportDateIssued);
        if (issueDate > today) {
          newErrors.passportDateIssued = "Issue date cannot be in the future";
        }
      }
      
      // Expiry date required and must be at least one month in the future
      if (!formData.passportDateExpirey) {
        newErrors.passportDateExpirey = "Expiry date is required";
      } else {
        const expiryDate = new Date(formData.passportDateExpirey);
        if (expiryDate <= today) {
          newErrors.passportDateExpirey = "Passport cannot be expired";
        } else if (expiryDate < oneMonthFromNow) {
          newErrors.passportDateExpirey = "Passport must be valid for at least one month from today";
        }
      }
      
      // Issue date must be before expiry date
      if (formData.passportDateIssued && formData.passportDateExpirey) {
        const issueDate = new Date(formData.passportDateIssued);
        const expiryDate = new Date(formData.passportDateExpirey);
        if (issueDate >= expiryDate) {
          newErrors.passportDateExpirey = "Expiry date must be after issue date";
        }
      }
    }
    
    // Ghana Card date validation
    if (formData.type === 'Ghana Card' || formData.type === 'Ghana Card Back') {
      if (formData.cardDateIssued && formData.cardDateExpirey) {
        const issueDate = new Date(formData.cardDateIssued);
        const expiryDate = new Date(formData.cardDateExpirey);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        if (issueDate > today) {
          newErrors.cardDateIssued = "Issue date cannot be in the future";
        }
        
        if (expiryDate <= today) {
          newErrors.cardDateExpirey = "Expiry date cannot be in the past";
        }
        
        if (issueDate >= expiryDate) {
          newErrors.cardDateExpirey = "Expiry date must be after issue date";
        }
      }
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    if (!validateForm()) {
      setIsSubmitting(false);
      return;
    }
    
    // In a real app, you would:
    // 1. Upload the file to storage
    // 2. Get the file URL from the storage service
    // 3. Save the document data with the URL to your database
    
    // Prepare data to save based on document type
    let documentToSave = {...formData};
    
    if (formData.type === 'passport') {
      // Standardize field names for passports
      documentToSave = {
        ...documentToSave,
        passportNumber: formData.passportNumber,
        dateIssued: formData.passportDateIssued,
        dateExpire: formData.passportDateExpirey,
      };
    } else if (formData.type === 'Ghana Card' || formData.type === 'Ghana Card Back') {
      // Add Ghana Card specific fields
      documentToSave = {
        ...documentToSave,
        cardNumber: formData.cardNumber,
        dateIssued: formData.cardDateIssued,
        dateExpire: formData.cardDateExpirey,
      };
    }
    
    // Call parent's save function
    onSave(documentToSave);
    setIsSubmitting(false);
  };

  // Calculate minimum expiry date (1 month from today)
  const getMinExpiryDate = () => {
    const today = new Date();
    today.setMonth(today.getMonth() + 1);
    return today.toISOString().split('T')[0];
  };

  // Function to format date string (YYYY-MM-DD for date input)
  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    
    // Check if already in YYYY-MM-DD format
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      return dateString;
    }
    
    // Try parsing various formats
    try {
      const date = new Date(dateString);
      if (!isNaN(date.getTime())) {
        return date.toISOString().split('T')[0];
      }
    } catch (e) {
      console.error("Date parsing error:", e);
    }
    
    return '';
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">
            {document ? 'Edit Document' : 'Add New Document'}
          </h2>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            {/* Document Type */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Document Type*
              </label>
              <select
                name="type"
                value={formData.type}
                onChange={handleChange}
                className={`w-full p-2 border ${errors.type ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                disabled={!!document} // Disable changing type when editing
                required
              >
                <option value=""></option>
                {travelDocumentTypes.map(docType => (
                  <option key={docType.value} value={docType.value}>
                    {docType.label}
                  </option>
                ))}
              </select>
              {errors.type && (
                <p className="mt-1 text-sm text-red-600">{errors.type}</p>
              )}
            </div>

            {/* File Upload */}
            {!document && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Upload Document*
                </label>
                <input
                  type="file"
                  onChange={handleFileChange}
                  className={`w-full p-2 border ${errors.file ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                  accept=".pdf,.jpg,.jpeg,.png"
                />
                {errors.file && (
                  <p className="mt-1 text-sm text-red-600">{errors.file}</p>
                )}
                <p className="mt-1 text-sm text-gray-500">
                  Upload PDF, JPG, or PNG files only.
                </p>
              </div>
            )}

            {/* Passport specific fields */}
            {formData.type === 'passport' && (
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Passport Number*
                  </label>
                  <input
                    type="text"
                    name="passportNumber"
                    value={formData.passportNumber}
                    onChange={handleChange}
                    className={`w-full p-2 border ${errors.passportNumber ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                    required
                  />
                  {errors.passportNumber && (
                    <p className="mt-1 text-sm text-red-600">{errors.passportNumber}</p>
                  )}
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Date Issued*
                    </label>
                    <input
                      type="date"
                      name="passportDateIssued"
                      value={formatDateForInput(formData.passportDateIssued)}
                      onChange={handleChange}
                      max={new Date().toISOString().split('T')[0]} // Max is today
                      className={`w-full p-2 border ${errors.passportDateIssued ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                      required
                    />
                    {errors.passportDateIssued && (
                      <p className="mt-1 text-sm text-red-600">{errors.passportDateIssued}</p>
                    )}
                    <p className="mt-1 text-xs text-gray-500">
                      Issue date must be in the past
                    </p>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Expiry Date*
                    </label>
                    <input
                      type="date"
                      name="passportDateExpirey"
                      value={formatDateForInput(formData.passportDateExpirey)}
                      onChange={handleChange}
                      min={getMinExpiryDate()} // Min is one month from today
                      className={`w-full p-2 border ${errors.passportDateExpirey ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                      required
                    />
                    {errors.passportDateExpirey && (
                      <p className="mt-1 text-sm text-red-600">{errors.passportDateExpirey}</p>
                    )}
                    <p className="mt-1 text-xs text-gray-500">
                      Must be valid for at least one month from today
                    </p>
                  </div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Is Active
                  </label>
                  <select
                    name="isActive"
                    value={formData.isActive ? 'true' : 'false'}
                    onChange={(e) => handleChange({
                      target: {
                        name: 'isActive',
                        value: e.target.value === 'true'
                      }
                    })}
                    className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
              </div>
            )}

            {/* Ghana Card specific fields */}
            {(formData.type === 'Ghana Card' || formData.type === 'Ghana Card Back') && (
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Ghana Card Number*
                  </label>
                  <input
                    type="text"
                    name="cardNumber"
                    value={formData.cardNumber}
                    onChange={handleChange}
                    className={`w-full p-2 border ${errors.cardNumber ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                    required
                  />
                  {errors.cardNumber && (
                    <p className="mt-1 text-sm text-red-600">{errors.cardNumber}</p>
                  )}
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Date Issued
                    </label>
                    <input
                      type="date"
                      name="cardDateIssued"
                      value={formatDateForInput(formData.cardDateIssued)}
                      onChange={handleChange}
                      max={new Date().toISOString().split('T')[0]} // Max is today
                      className={`w-full p-2 border ${errors.cardDateIssued ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                    />
                    {errors.cardDateIssued && (
                      <p className="mt-1 text-sm text-red-600">{errors.cardDateIssued}</p>
                    )}
                    <p className="mt-1 text-xs text-gray-500">
                      Issue date must be in the past
                    </p>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Expiry Date
                    </label>
                    <input
                      type="date"
                      name="cardDateExpirey"
                      value={formatDateForInput(formData.cardDateExpirey)}
                      onChange={handleChange}
                      min={new Date().toISOString().split('T')[0]} // Min is today
                      className={`w-full p-2 border ${errors.cardDateExpirey ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                    />
                    {errors.cardDateExpirey && (
                      <p className="mt-1 text-sm text-red-600">{errors.cardDateExpirey}</p>
                    )}
                    <p className="mt-1 text-xs text-gray-500">
                      Expiry date must be in the future
                    </p>
                  </div>
                </div>
              </div>
            )}

            {/* Description - Optional for all documents */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Description (Optional)
              </label>
              <textarea
                name="description"
                value={formData.description || ''}
                onChange={handleChange}
                rows="3"
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                placeholder="Add any additional details about this document"
              ></textarea>
            </div>
          </div>

          <div className="flex justify-end gap-2 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-800 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded disabled:bg-blue-300 disabled:cursor-not-allowed"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Saving...' : document ? 'Update' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DocumentModal;