
import React, { useState, useEffect } from 'react';
import { useESIMStore } from '../../../hooks/esim';
import PersonalInfoSection from './personal';
import ContactInfoSection from './contacts';
import DependantsSection from './dependants';
import ParentsSection from './parents';


const CustomerInfoDisplay = ( {customerId} ) => {
  
  const { fetchCustomerInfo, customerInfo, isCustomerInfoLoading } = useESIMStore();
  const [activeAccordion, setActiveAccordion] = useState('personal-info');

  useEffect(() => {
    if (customerId) {
      fetchCustomerInfo(customerId);
    }
  }, [customerId, fetchCustomerInfo]);

  const toggleAccordion = (accordionId) => {
    setActiveAccordion(activeAccordion === accordionId ? null : accordionId);
  };

  if (isCustomerInfoLoading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!customerInfo || customerInfo.error) {
    return (
      <div className="flex flex-col items-center p-8">
        <p className="text-red-500 mb-4">
          {customerInfo?.error || "Failed to load customer information"}
        </p>
        <button 
          className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
          onClick={() => fetchCustomerInfo(customerId)}
        >
          Retry
        </button>
      </div>
    );
  }

  // Accordion helper component
  const AccordionItem = ({ id, title, children }) => (
    <div className={id === 'parents' ? '' : 'border-b'}>
      <button
        className="w-full p-4 text-left font-semibold flex justify-between items-center focus:outline-none"
        onClick={() => toggleAccordion(id)}
      >
        <span>{title}</span>
        <svg
          className={`w-5 h-5 transition-transform ${
            activeAccordion === id ? 'transform rotate-180' : ''
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      
      {activeAccordion === id && (
        <div className="p-4 bg-white">
          {children}
        </div>
      )}
    </div>
  );

  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      <h2 className="text-2xl font-bold mb-2">{customerInfo.fullname.toUpperCase()}</h2>
      <p className="text-gray-500 mb-6">Customer ID: {customerInfo.customerId}</p>

      {/* Accordion Container */}
      <div className="w-full mb-6 border rounded-lg overflow-hidden">
        <AccordionItem id="personal-info" title="Personal Information">
          <PersonalInfoSection customerInfo={customerInfo} />
        </AccordionItem>

        <AccordionItem id="contacts" title="Contact Information">
          <ContactInfoSection customerInfo={customerInfo} />
        </AccordionItem>

        <AccordionItem id="dependants" title="Dependants">
          <DependantsSection customerInfo={customerInfo} customerId={customerId} />
        </AccordionItem>

        <AccordionItem id="parents" title="Parents">
          <ParentsSection customerInfo={customerInfo} customerId={customerId} />
        </AccordionItem>
      </div>
    </div>
  );
};

export default CustomerInfoDisplay;