// // src/components/customer/DependantModal.jsx
// import React, { useState, useEffect } from 'react';

// const DependantModal = ({ isOpen, onClose, onSave, dependant, customerId }) => {
//   const [formData, setFormData] = useState({
//     dependantFullName: '',
//     dependantGender: '',
//     dependantDateOfBirth: '',
//     dependantHouseNumber: '',
//     dependantAreaName: '',
//     schoolAttending: '',
//     schoolFees: '',
//     dependantid: Date.now() // Generate temporary ID for new entries
//   });

//   useEffect(() => {
//     // If editing existing dependant, populate form
//     if (dependant) {
//       setFormData({
//         ...formData,
//         ...dependant,
//         // Remove index field which was added for internal tracking
//         index: undefined
//       });
//     }
//   }, [dependant]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     onSave(formData);
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//       <div className="bg-white rounded-lg p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
//         <div className="flex justify-between items-center mb-4">
//           <h2 className="text-xl font-bold">
//             {dependant ? 'Edit Dependant' : 'Add New Dependant'}
//           </h2>
//           <button 
//             onClick={onClose}
//             className="text-gray-500 hover:text-gray-700"
//           >
//             <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
//               <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
//             </svg>
//           </button>
//         </div>

//         <form onSubmit={handleSubmit}>
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
//             <div>
//               <label className="block text-sm font-medium text-gray-700 mb-1">
//                 Full Name*
//               </label>
//               <input
//                 type="text"
//                 name="dependantFullName"
//                 value={formData.dependantFullName || ''}
//                 onChange={handleChange}
//                 required
//                 className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//               />
//             </div>

//             <div>
//               <label className="block text-sm font-medium text-gray-700 mb-1">
//                 Gender*
//               </label>
//               <select
//                 name="dependantGender"
//                 value={formData.dependantGender || ''}
//                 onChange={handleChange}
//                 required
//                 className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//               >
//                 <option value="">Select gender</option>
//                 <option value="male">Male</option>
//                 <option value="female">Female</option>
//                 <option value="other">Other</option>
//               </select>
//             </div>

//             <div>
//               <label className="block text-sm font-medium text-gray-700 mb-1">
//                 Date of Birth
//               </label>
//               <input
//                 type="text"
//                 name="dependantDateOfBirth"
//                 value={formData.dependantDateOfBirth || ''}
//                 onChange={handleChange}
//                 placeholder="DD/MM/YYYY"
//                 className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//               />
//             </div>

//             <div>
//               <label className="block text-sm font-medium text-gray-700 mb-1">
//                 House Number
//               </label>
//               <input
//                 type="text"
//                 name="dependantHouseNumber"
//                 value={formData.dependantHouseNumber || ''}
//                 onChange={handleChange}
//                 className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//               />
//             </div>

//             <div>
//               <label className="block text-sm font-medium text-gray-700 mb-1">
//                 Area Name
//               </label>
//               <input
//                 type="text"
//                 name="dependantAreaName"
//                 value={formData.dependantAreaName || ''}
//                 onChange={handleChange}
//                 className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//               />
//             </div>

//             <div>
//               <label className="block text-sm font-medium text-gray-700 mb-1">
//                 School Attending
//               </label>
//               <input
//                 type="text"
//                 name="schoolAttending"
//                 value={formData.schoolAttending || ''}
//                 onChange={handleChange}
//                 className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//               />
//             </div>

//             <div>
//               <label className="block text-sm font-medium text-gray-700 mb-1">
//                 School Fees
//               </label>
//               <input
//                 type="text"
//                 name="schoolFees"
//                 value={formData.schoolFees || ''}
//                 onChange={handleChange}
//                 className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//               />
//             </div>
//           </div>

//           <div className="flex justify-end gap-2">
//             <button
//               type="button"
//               onClick={onClose}
//               className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-800 rounded"
//             >
//               Cancel
//             </button>
//             <button
//               type="submit"
//               className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
//             >
//               {dependant ? 'Update' : 'Save'} Dependant
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default DependantModal;



import React, { useState, useEffect } from 'react';
import { useESIMStore } from '../../../hooks/esim';

const DependantModal = ({ isOpen, onClose, dependant, customerId }) => {
  const [formData, setFormData] = useState({
    dependantFullName: '',
    dependantGender: '',
    dependantDateOfBirth: '',
    dependantHouseNumber: '',
    dependantAreaName: '',
    schoolAttending: '',
    schoolFees: '',
    dependantid: Date.now().toString() // Generate temporary ID for new entries
  });
  
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  // Get the dependant functions from Zustand store
  const addDependant = useESIMStore(state => state.addDependant);
  const updateDependant = useESIMStore(state => state.updateDependant);

  useEffect(() => {
    // Reset form when modal is opened/closed
    if (isOpen) {
      // If editing existing dependant, populate form
      if (dependant) {
        setFormData({
          ...dependant,
          // Convert dependantid to string if it's not already
          dependantid: dependant.dependantid.toString()
        });
      } else {
        // New dependant - reset form with a new temporary ID
        setFormData({
          dependantFullName: '',
          dependantGender: '',
          dependantDateOfBirth: '',
          dependantHouseNumber: '',
          dependantAreaName: '',
          schoolAttending: '',
          schoolFees: '',
          dependantid: Date.now().toString()
        });
      }
      // Reset errors
      setError(null);
      setFormErrors({});
    }
  }, [isOpen, dependant]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Clear form errors for this field
    if (formErrors[name]) {
      setFormErrors({
        ...formErrors,
        [name]: null
      });
    }
    
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateForm = () => {
    const errors = {};
    
    // Required fields validation
    if (!formData.dependantFullName || formData.dependantFullName.trim() === '') {
      errors.dependantFullName = 'Full name is required';
    }
    
    if (!formData.dependantGender || formData.dependantGender.trim() === '') {
      errors.dependantGender = 'Gender is required';
    }
    
    // Date of birth validation if provided
    if (formData.dependantDateOfBirth) {
      // Check format DD/MM/YYYY
      const dateRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
      if (!dateRegex.test(formData.dependantDateOfBirth)) {
        errors.dependantDateOfBirth = 'Date must be in DD/MM/YYYY format';
      } else {
        // Check if it's a valid date
        const [, day, month, year] = formData.dependantDateOfBirth.match(dateRegex);
        const dateObj = new Date(`${year}-${month}-${day}`);
        if (isNaN(dateObj.getTime())) {
          errors.dependantDateOfBirth = 'Invalid date';
        }
      }
    }
    
    // School fees validation if provided
    if (formData.schoolFees && !/^(\d+(\.\d{1,2})?)$/.test(formData.schoolFees)) {
      errors.schoolFees = 'School fees must be a valid number';
    }
    
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    setIsSaving(true);
    setError(null);
    
    try {
      let result;
      
      if (dependant) {
        // Update existing dependant
        result = await updateDependant(customerId, formData);
      } else {
        // Add new dependant
        result = await addDependant(customerId, formData);
      }
      
      if (result.success) {
        // Close modal on success
        onClose();
      } else {
        setError(result.error || 'Failed to save dependant');
      }
    } catch (err) {
      setError(err.message || 'An error occurred while saving');
    } finally {
      setIsSaving(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">
            {dependant ? 'Edit Dependant' : 'Add New Dependant'}
          </h2>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
            disabled={isSaving}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-100 text-red-700 rounded">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Full Name*
              </label>
              <input
                type="text"
                name="dependantFullName"
                value={formData.dependantFullName || ''}
                onChange={handleChange}
                className={`w-full p-2 border ${formErrors.dependantFullName ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                disabled={isSaving}
              />
              {formErrors.dependantFullName && (
                <p className="mt-1 text-sm text-red-600">{formErrors.dependantFullName}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Gender*
              </label>
              <select
                name="dependantGender"
                value={formData.dependantGender || ''}
                onChange={handleChange}
                className={`w-full p-2 border ${formErrors.dependantGender ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                disabled={isSaving}
              >
                <option value="">Select gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
              {formErrors.dependantGender && (
                <p className="mt-1 text-sm text-red-600">{formErrors.dependantGender}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Date of Birth
              </label>
              <input
                type="text"
                name="dependantDateOfBirth"
                value={formData.dependantDateOfBirth || ''}
                onChange={handleChange}
                placeholder="DD/MM/YYYY"
                className={`w-full p-2 border ${formErrors.dependantDateOfBirth ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                disabled={isSaving}
              />
              {formErrors.dependantDateOfBirth && (
                <p className="mt-1 text-sm text-red-600">{formErrors.dependantDateOfBirth}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                House Number
              </label>
              <input
                type="text"
                name="dependantHouseNumber"
                value={formData.dependantHouseNumber || ''}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                disabled={isSaving}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Area Name
              </label>
              <input
                type="text"
                name="dependantAreaName"
                value={formData.dependantAreaName || ''}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                disabled={isSaving}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                School Attending
              </label>
              <input
                type="text"
                name="schoolAttending"
                value={formData.schoolAttending || ''}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                disabled={isSaving}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                School Fees
              </label>
              <input
                type="text"
                name="schoolFees"
                value={formData.schoolFees || ''}
                onChange={handleChange}
                className={`w-full p-2 border ${formErrors.schoolFees ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                disabled={isSaving}
              />
              {formErrors.schoolFees && (
                <p className="mt-1 text-sm text-red-600">{formErrors.schoolFees}</p>
              )}
            </div>
          </div>

          <div className="flex justify-end gap-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-800 rounded"
              disabled={isSaving}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded inline-flex items-center"
              disabled={isSaving}
            >
              {isSaving && (
                <svg className="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              )}
              {isSaving ? 'Saving...' : dependant ? 'Update' : 'Save'} {!isSaving && 'Dependant'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DependantModal;