import React, { useState, useEffect } from 'react';
import { useESIMStore } from '../../../hooks/esim';

const ContactInfoSection = ({ customerInfo }) => {
  const [editedInfo, setEditedInfo] = useState({
    contacts: {}
  });
  const [hasChanges, setHasChanges] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);

  // Get the updateContactInfo function from Zustand store
  const updateContactInfo = useESIMStore(state => state.updateContactInfo);

  useEffect(() => {
    if (customerInfo && customerInfo.contacts) {
      setEditedInfo({
        contacts: { ...customerInfo.contacts }
      });
    }
  }, [customerInfo]);

  // Check for changes in editable fields
  useEffect(() => {
    if (customerInfo && customerInfo.contacts && editedInfo.contacts) {
      const original = customerInfo.contacts;
      const edited = editedInfo.contacts;
      
      const editableFields = [
        'email2', 
        'phone2', 
        'emergencyContactPhone', 
        'emergencyContactEmail',
        'emergencyContactName',
        'emergencyContactRelationship'
      ];
      
      const hasAnyChanges = editableFields.some(field => 
        original[field] !== edited[field] && edited[field] !== undefined
      );
      
      setHasChanges(hasAnyChanges);
    }
  }, [editedInfo, customerInfo]);

  const handleContactInfoChange = (e) => {
    const { name, value } = e.target;
    
    // Clear errors for this field if they exist
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: null
      }));
    }
    
    setEditedInfo({
      ...editedInfo,
      contacts: {
        ...editedInfo.contacts,
        [name]: value
      }
    });
  };

  const validateForm = () => {
    const newErrors = {};
    
    // Validate email format if provided
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    if (editedInfo.contacts.email2 && !emailRegex.test(editedInfo.contacts.email2)) {
      newErrors.email2 = "Please enter a valid email address";
    }
    
    if (editedInfo.contacts.emergencyContactEmail && !emailRegex.test(editedInfo.contacts.emergencyContactEmail)) {
      newErrors.emergencyContactEmail = "Please enter a valid email address";
    }
    
    // Validate phone numbers if provided
    const phoneRegex = /^\d{9,15}$/;
    
    if (editedInfo.contacts.phone2 && !phoneRegex.test(editedInfo.contacts.phone2.replace(/\D/g, ''))) {
      newErrors.phone2 = "Please enter a valid phone number";
    }
    
    if (editedInfo.contacts.emergencyContactPhone && !phoneRegex.test(editedInfo.contacts.emergencyContactPhone.replace(/\D/g, ''))) {
      newErrors.emergencyContactPhone = "Please enter a valid phone number";
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveChanges = async () => {
    if (!hasChanges) {
      alert("No changes to save");
      return;
    }
    
    if (!validateForm()) {
      return;
    }
    
    setIsSaving(true);
    setSaveError(null);
    setSaveSuccess(false);
    
    try {
      // Call the Zustand store function to update contact info
      const result = await updateContactInfo(
        customerInfo.customerId, 
        editedInfo.contacts
      );
      
      if (result.success) {
        setSaveSuccess(true);
        // Reset success message after 3 seconds
        setTimeout(() => setSaveSuccess(false), 3000);
        // Reset hasChanges since data is now saved
        setHasChanges(false);
      } else {
        setSaveError(result.error || "Failed to save changes");
      }
    } catch (error) {
      setSaveError(error.message || "An error occurred while saving changes");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="bg-white rounded-lg p-6 shadow-sm">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-medium">Contact Information</h3>
        {saveSuccess && (
          <div className="bg-green-100 text-green-800 px-3 py-1 rounded-full text-sm font-medium">
            Changes saved successfully!
          </div>
        )}
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Primary Email</label>
          <div className="p-2 bg-gray-100 rounded">
            {customerInfo.contacts?.email || "N/A"}
          </div>
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Primary Phone</label>
          <div className="p-2 bg-gray-100 rounded">
            {customerInfo.contacts?.telephoneNumber || "N/A"}
          </div>
        </div>

        {/* Secondary Contact Info - Editable */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Secondary Email</label>
          <input
            type="email"
            name="email2"
            value={editedInfo.contacts?.email2 || ''}
            onChange={handleContactInfoChange}
            className={`w-full p-2 border ${errors.email2 ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
            placeholder="Second email address"
            disabled={isSaving}
          />
          {errors.email2 && (
            <p className="mt-1 text-sm text-red-600">{errors.email2}</p>
          )}
          {saveSuccess && editedInfo.contacts?.email2 && (
            <p className="mt-1 text-xs text-blue-600">Updated: {editedInfo.contacts.email2}</p>
          )}
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Secondary Phone</label>
          <input
            type="tel"
            name="phone2"
            value={editedInfo.contacts?.phone2 || ''}
            onChange={handleContactInfoChange}
            className={`w-full p-2 border ${errors.phone2 ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
            placeholder="Second phone number"
            disabled={isSaving}
          />
          {errors.phone2 && (
            <p className="mt-1 text-sm text-red-600">{errors.phone2}</p>
          )}
          {saveSuccess && editedInfo.contacts?.phone2 && (
            <p className="mt-1 text-xs text-blue-600">Updated: {editedInfo.contacts.phone2}</p>
          )}
        </div>
      </div>

      {/* Emergency Contact Section - Editable */}
      <h3 className="font-medium mt-6 mb-4 border-b pb-2">Emergency Contact</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Emergency Contact Phone</label>
          <input
            type="tel"
            name="emergencyContactPhone"
            value={editedInfo.contacts?.emergencyContactPhone || ''}
            onChange={handleContactInfoChange}
            className={`w-full p-2 border ${errors.emergencyContactPhone ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
            disabled={isSaving}
          />
          {errors.emergencyContactPhone && (
            <p className="mt-1 text-sm text-red-600">{errors.emergencyContactPhone}</p>
          )}
          {saveSuccess && editedInfo.contacts?.emergencyContactPhone && (
            <p className="mt-1 text-xs text-blue-600">Updated: {editedInfo.contacts.emergencyContactPhone}</p>
          )}
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Emergency Contact Email</label>
          <input
            type="email"
            name="emergencyContactEmail"
            value={editedInfo.contacts?.emergencyContactEmail || ''}
            onChange={handleContactInfoChange}
            className={`w-full p-2 border ${errors.emergencyContactEmail ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
            disabled={isSaving}
          />
          {errors.emergencyContactEmail && (
            <p className="mt-1 text-sm text-red-600">{errors.emergencyContactEmail}</p>
          )}
          {saveSuccess && editedInfo.contacts?.emergencyContactEmail && (
            <p className="mt-1 text-xs text-blue-600">Updated: {editedInfo.contacts.emergencyContactEmail}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Emergency Contact Name</label>
          <input
            type="text"
            name="emergencyContactName"
            value={editedInfo.contacts?.emergencyContactName || ''}
            onChange={handleContactInfoChange}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            disabled={isSaving}
          />
          {saveSuccess && editedInfo.contacts?.emergencyContactName && (
            <p className="mt-1 text-xs text-blue-600">Updated: {editedInfo.contacts.emergencyContactName}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Relationship</label>
          <select
            name="emergencyContactRelationship"
            value={editedInfo.contacts?.emergencyContactRelationship || ''}
            onChange={handleContactInfoChange}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            disabled={isSaving}
          >
            <option value="">Select relationship</option>
            <option value="spouse">Spouse</option>
            <option value="parent">Parent</option>
            <option value="child">Child</option>
            <option value="sibling">Sibling</option>
            <option value="friend">Friend</option>
            <option value="colleague">Colleague</option>
            <option value="other">Other</option>
          </select>
          {saveSuccess && editedInfo.contacts?.emergencyContactRelationship && (
            <p className="mt-1 text-xs text-blue-600">Updated: {editedInfo.contacts.emergencyContactRelationship}</p>
          )}
        </div>
      </div>
      
      <div className="mt-6 flex justify-between items-center">
        {saveError && (
          <div className="bg-red-100 text-red-800 px-3 py-1 rounded text-sm">
            Error: {saveError}
          </div>
        )}
        
        <div className="ml-auto">
          <button
            className={`px-4 py-2 ${hasChanges && !isSaving 
              ? 'bg-blue-500 hover:bg-blue-600' 
              : 'bg-gray-400 cursor-not-allowed'} text-white rounded transition-colors inline-flex items-center`}
            onClick={handleSaveChanges}
            disabled={!hasChanges || isSaving}
          >
            {isSaving && (
              <svg className="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            )}
            {isSaving ? 'Saving...' : 'Save Changes'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactInfoSection;