import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import cartReducer from './slices/cartSlice'; // Import your first slice
import tourFilterReducer from "./slices/tourFilter";
import customerReducer from "./slices/customerSlice";
import globalConfigReducer from "./slices/globalConfigReducer";
import ESIMSliceReducer from "./slices/ESIMSlice";

// Create the root reducer
const rootReducer = combineReducers({
  cart: cartReducer, 
  tourFilter: tourFilterReducer,
  customer_data: customerReducer,
  globalConfig: globalConfigReducer,
  ESIM: ESIMSliceReducer, 
});

// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cart', 'customer_data' , 'globalConfig', 'ESIM'], // Add reducers you want to persist here
};

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
