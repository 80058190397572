

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from "../../../api/firebase";
import useAuthStore from "../../../hooks/Auths";

const AuthComponent = () => {
  // Auth method state
  const [authMethod, setAuthMethod] = useState('phone'); // 'phone', 'email', 'password'
  
  // Phone auth states
  const [phoneNumber, setPhoneNumber] = useState('');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [step, setStep] = useState('phone'); // 'phone', 'otp', 'success'
  const [countdown, setCountdown] = useState(0);
  
  // Email/password auth states
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  // Navigation
  const navigate = useNavigate();
  
  // Zustand store
  const { 
    user, 
    userData, 
    loading, 
    error, 
    sendPhoneVerificationCode, 
    verifyPhoneCode, 
    signInWithEmailPassword,
    clearAuth,
    setError
  } = useAuthStore();
  
  // Use refs to track recaptcha instances for proper cleanup
  const recaptchaVerifierRef = useRef(null);

  // Effect to redirect if already authenticated
  useEffect(() => {
    if (userData && user && step !== 'success') {
      navigate('/customer_account');
    }
  }, [userData, user, navigate, step]);

  // Format phone number as user types
  useEffect(() => {
    const formatPhoneNumber = (value) => {
      // Remove all non-numeric characters except the plus sign at the beginning
      let formatted = value.replace(/[^\d+]/g, '');
      
      // Ensure the phone number starts with a plus sign
      if (!formatted.startsWith('+')) {
        formatted = '+' + formatted;
      }
      
      setFormattedPhoneNumber(formatted);
    };

    formatPhoneNumber(phoneNumber);
  }, [phoneNumber]);

  // Initialize recaptcha when component mounts and phone auth is selected
  useEffect(() => {
    let recaptchaVerifierInstance = null;
    
    if (authMethod === 'phone' && step === 'phone') {
      try {
        // Clear any existing reCAPTCHA instances globally
        if (window.recaptchaVerifier) {
          try {
            window.recaptchaVerifier.clear();
          } catch (e) {
            console.log('Error clearing previous reCAPTCHA:', e);
          }
          window.recaptchaVerifier = null;
        }
        
        // Ensure the container is empty and visible
        const container = document.getElementById('recaptcha-container');
        if (container) {
          container.innerHTML = '';
          container.style.display = 'block';
        }
        
        // Create a new reCAPTCHA verifier with explicit configuration
        recaptchaVerifierInstance = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
          'size': 'normal',
          'callback': (response) => {
            console.log('reCAPTCHA resolved with response token available');
            recaptchaVerifierRef.current = recaptchaVerifierInstance;
          },
          'expired-callback': () => {
            console.log('reCAPTCHA expired');
            setError('reCAPTCHA has expired. Please refresh the page and try again.');
            // Force a refresh of the reCAPTCHA
            setTimeout(() => {
              if (recaptchaVerifierInstance) {
                try {
                  recaptchaVerifierInstance.clear();
                  
                  // Re-create the reCAPTCHA after a short delay
                  setTimeout(() => {
                    const newVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                      'size': 'normal',
                      'callback': () => {}
                    });
                    recaptchaVerifierRef.current = newVerifier;
                    window.recaptchaVerifier = newVerifier;
                    newVerifier.render();
                  }, 500);
                } catch (e) {
                  console.error('Error refreshing expired reCAPTCHA:', e);
                }
              }
            }, 100);
          }
        });
        
        window.recaptchaVerifier = recaptchaVerifierInstance;
        
        // Explicitly render and handle the promise
        recaptchaVerifierInstance.render()
          .then(widgetId => {
            console.log('reCAPTCHA rendered successfully with ID:', widgetId);
          })
          .catch(error => {
            console.error('Error rendering reCAPTCHA:', error);
            setError('Error initializing verification system. Please refresh the page.');
          });
      } catch (error) {
        console.error('Error setting up reCAPTCHA:', error);
        setError('Error initializing verification system. Please refresh the page.');
      }
    }
    
    // Cleanup function
    return () => {
      if (recaptchaVerifierInstance) {
        try {
          recaptchaVerifierInstance.clear();
        } catch (e) {
          console.log('Error clearing reCAPTCHA on unmount:', e);
        }
      }
    };
  }, [authMethod, step, setError]);

  // Countdown timer for resend code
  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [countdown]);

  // Handle auth method change
  const handleAuthMethodChange = (method) => {
    setAuthMethod(method);
    setError('');
    
    // Reset states when switching methods
    if (method === 'phone') {
      setStep('phone');
    }
  };

  // Phone Authentication Handlers
  const handleSendCode = async (e) => {
    e.preventDefault();

    try {
      // Validate phone number
      if (!formattedPhoneNumber || !/^\+[1-9]\d{8,14}$/.test(formattedPhoneNumber)) {
        throw new Error('Please enter a valid phone number in international format (e.g., +233xxxxxxxxx for Ghana)');
      }

      // First check if we have a valid reCAPTCHA verifier
      if (!recaptchaVerifierRef.current) {
        console.error('No valid reCAPTCHA verifier found');
        
        // Try to recreate the reCAPTCHA verifier
        const container = document.getElementById('recaptcha-container');
        if (container) {
          container.innerHTML = '';
        }
        
        // Create a new instance
        const newVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
          'size': 'normal',
          'callback': () => {
            console.log('New reCAPTCHA resolved');
          }
        });
        
        try {
          await newVerifier.render();
          recaptchaVerifierRef.current = newVerifier;
          window.recaptchaVerifier = newVerifier;
        } catch (renderError) {
          console.error('Failed to render new reCAPTCHA:', renderError);
          throw new Error('reCAPTCHA verification failed. Please refresh the page and try again.');
        }
      }

      console.log('Sending verification code to:', formattedPhoneNumber);
      
      // Use the recaptchaVerifier from ref to ensure we have the latest instance
      const success = await sendPhoneVerificationCode(
        formattedPhoneNumber,
        recaptchaVerifierRef.current
      );

      if (success) {
        setStep('otp');
        setCountdown(60); // 60 seconds countdown for resend
      } else {
        // Reset recaptcha on failure for a fresh start
        try {
          if (recaptchaVerifierRef.current) {
            recaptchaVerifierRef.current.clear();
          }
          if (window.recaptchaVerifier) {
            window.recaptchaVerifier.clear();
            window.recaptchaVerifier = null;
          }
          
          // Recreate a new recaptcha after a small delay
          setTimeout(() => {
            const container = document.getElementById('recaptcha-container');
            if (container) {
              container.innerHTML = '';
              
              const newVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                'size': 'normal',
                'callback': () => {
                  console.log('New reCAPTCHA resolved after failure');
                }
              });
              
              newVerifier.render()
                .then(() => {
                  recaptchaVerifierRef.current = newVerifier;
                  window.recaptchaVerifier = newVerifier;
                });
            }
          }, 500);
          
        } catch (e) {
          console.log('Error clearing reCAPTCHA after failure:', e);
        }
      }
    } catch (error) {
      console.error('Send code error:', error);
      setError(error.message);
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    
    try {
      const success = await verifyPhoneCode(verificationCode);
      
      if (success) {
        setStep('success');
        
        // Redirect after success
        setTimeout(() => {
          navigate('/customer_account');
        }, 1500);
      }
    } catch (error) {
      console.error('Error in verification handler:', error);
    }
  };

  const handleResendCode = async () => {
    try {
      // Clean any existing instances first
      const container = document.getElementById('recaptcha-resend-container');
      if (container) {
        container.innerHTML = '';
      }
      
      // Create a fresh invisible reCAPTCHA for resending
      console.log('Creating new invisible reCAPTCHA for resend...');
      const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-resend-container', {
        size: 'invisible',
        callback: () => {
          console.log('Invisible reCAPTCHA resolved');
        },
        'expired-callback': () => {
          console.log('Invisible reCAPTCHA expired');
        }
      });
      
      // Render it first and explicitly wait for completion
      try {
        const widgetId = await appVerifier.render();
        console.log('Invisible reCAPTCHA rendered with ID:', widgetId);
        
        // Add a small delay to ensure reCAPTCHA has fully initialized
        await new Promise(resolve => setTimeout(resolve, 500));
        
        console.log('Resending verification code to:', formattedPhoneNumber);
        const success = await sendPhoneVerificationCode(
          formattedPhoneNumber,
          appVerifier
        );
        
        if (success) {
          setCountdown(60);
          setError(null); // Clear any previous errors
        }
      } catch (renderError) {
        console.error('Failed to render invisible reCAPTCHA:', renderError);
        throw new Error('Failed to initialize verification. Please refresh the page.');
      }
      
      // Clean up after use with proper error handling
      setTimeout(() => {
        try {
          if (appVerifier) {
            appVerifier.clear();
            console.log('Invisible reCAPTCHA cleared');
          }
        } catch (e) {
          console.log('Non-critical: Error clearing resend reCAPTCHA:', e);
        }
      }, 2000); // Give more time for the operation to complete
    } catch (error) {
      console.error('Resend code error:', error);
      setError(error.message || 'Failed to resend verification code');
    }
  };

  // Handle OTP input change - auto-format to 6 digits
  const handleOtpChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 6) {
      setVerificationCode(value);
    }
  };

  // Email/Password Authentication Handler
  const handleEmailPasswordSignIn = async (e) => {
    e.preventDefault();
    
    try {
      const success = await signInWithEmailPassword(email, password);
      
      if (success) {
        setStep('success');
        
        // Redirect after success
        setTimeout(() => {
          navigate('/customer_account');
        }, 1500);
      }
    } catch (error) {
      console.error('Error in email/password sign in handler:', error);
    }
  };


  
  const AuthMethodTabs = () => (
    <div className="flex mb-8 bg-gray-100 rounded-lg p-1.5 shadow-sm">
      <button
        className={`flex-1 py-3 px-4 rounded-lg font-medium text-sm transition-all duration-200 
          ${authMethod === 'phone' ? 'bg-white shadow-sm text-[#03844e]' : 'text-gray-600 hover:text-gray-900'}`}
        onClick={() => handleAuthMethodChange('phone')}
      >
        Phone OTP
      </button>
      <button
        className={`flex-1 py-3 px-4 rounded-lg font-medium text-sm transition-all duration-200 
          ${authMethod === 'password' ? 'bg-white shadow-sm text-[#03844e]' : 'text-gray-600 hover:text-gray-900'}`}
        onClick={() => handleAuthMethodChange('password')}
      >
        Email & Password
      </button>
    </div>
  );

  const LoadingSpinner = () => (
    <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  );

  const ErrorMessage = ({ message }) => (
    message ? (
      <div className="bg-red-50 border border-red-200 text-red-600 px-5 py-4 rounded-lg mb-6 shadow-sm">
        <div className="flex items-start">
          <svg className="h-5 w-5 text-red-500 mt-0.5 mr-2 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <span className="text-sm">{message}</span>
        </div>
      </div>
    ) : null
  );

  return (
    <div className="max-w-md mx-auto bg-white p-8 rounded-xl shadow-lg my-12 border border-gray-100">
      <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">
        {step === 'success' ? 'Authentication Successful' : 'Sign In'}
      </h2>
      
      {step !== 'success' && <AuthMethodTabs />}
      
      <ErrorMessage message={error} />
      
      {/* Phone Authentication Flow */}
      {authMethod === 'phone' && step === 'phone' && (
        <form onSubmit={handleSendCode}>
          <div className="text-gray-600 text-sm text-center mb-4">
            Please enter your phone number in international format Ghana:+233xxxxxxxxx
          </div>
                    
          <div className="mb-6">
            <label htmlFor="phone" className="block text-gray-700 font-medium mb-2">
              Phone Number
            </label>
            <div className="relative">
              <div className="flex shadow-sm">
                <div className="flex items-center justify-center bg-gray-50 border border-gray-300 border-r-0 rounded-l-lg p-4 text-gray-600 font-semibold">
                  <span className="font-medium">+</span>
                </div>
                <input
                  type="tel"
                  id="phone"
                  className="w-full p-4 border border-gray-300 rounded-r-lg focus:outline-none focus:ring-2 focus:ring-green-500 text-gray-700"
                  placeholder="233xxxxxxxxx"
                  value={phoneNumber.startsWith('+') ? phoneNumber.substring(1) : phoneNumber}
                  onChange={(e) => {
                    // Only allow digits
                    const value = e.target.value.replace(/\D/g, '');
                    setPhoneNumber('+' + value);
                  }}
                  required
                />
              </div>
            </div>
          </div>

          <div id="recaptcha-container" className="mb-6 flex justify-center bg-white rounded-lg p-2"></div>

          <button
            type="submit"
            className="w-full bg-[#03844e] text-white p-4 rounded-lg font-medium hover:bg-[#026d40] transition duration-200 flex items-center justify-center shadow-sm"
            disabled={loading}
          >
            {loading ? <LoadingSpinner /> : 'Send Verification Code'}
          </button>
        </form>
      )}

      {authMethod === 'phone' && step === 'otp' && (
        <form onSubmit={handleVerifyCode}>
          <div className="mb-8">
            <p className="text-gray-700 mb-5 text-center font-medium">
              We've sent a 6-digit verification code to {formattedPhoneNumber}
            </p>
            
            {/* Single input approach - more accessible */}
            <input
              type="text"
              className="sr-only"
              value={verificationCode}
              onChange={handleOtpChange}
              maxLength={6}
              autoComplete="one-time-code"
              id="otp-input"
            />
            
            {/* Visual OTP boxes */}
            <div className="flex justify-center mb-6">
              {[...Array(6)].map((_, i) => (
                <div
                  key={i}
                  className={`w-12 h-14 mx-1.5 text-center text-xl font-bold rounded-lg border ${verificationCode[i] ? 'border-[#03844e] bg-green-50' : 'border-gray-300'} flex items-center justify-center cursor-pointer shadow-sm transition-all`}
                  onClick={() => document.getElementById('otp-input').focus()}
                >
                  {verificationCode[i] || ''}
                </div>
              ))}
            </div>
            
            <div className="text-center mt-6">
              {countdown > 0 ? (
                <p className="text-gray-600 text-sm">
                  Resend code in <span className="font-semibold">{countdown}</span> seconds
                </p>
              ) : (
                <button
                  type="button"
                  className="text-[#03844e] hover:text-[#026d40] text-sm font-medium"
                  onClick={handleResendCode}
                  disabled={loading}
                >
                  Resend verification code
                </button>
              )}
            </div>
          </div>

          <div id="recaptcha-resend-container" className="mb-4 hidden"></div>

          <button
            type="submit"
            className="w-full bg-[#03844e] text-white p-4 rounded-lg font-medium hover:bg-[#026d40] transition duration-200 flex items-center justify-center shadow-sm"
            disabled={loading || verificationCode.length !== 6}
          >
            {loading ? <LoadingSpinner /> : 'Verify Code'}
          </button>

          <button
            type="button"
            className="w-full mt-4 bg-gray-100 text-gray-700 p-4 rounded-lg font-medium hover:bg-gray-200 transition duration-200 border border-gray-200"
            onClick={() => setStep('phone')}
            disabled={loading}
          >
            Change Phone Number
          </button>
        </form>
      )}

      {/* Email/Password Authentication Flow */}
      {authMethod === 'password' && step !== 'success' && (
        <form onSubmit={handleEmailPasswordSignIn}>
          <div className="mb-5">
            <label htmlFor="email" className="block text-gray-700 font-medium mb-2">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 shadow-sm"
              placeholder="your.email@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          
          <div className="mb-6">
            <label htmlFor="password" className="block text-gray-700 font-medium mb-2">
              Password
            </label>
            <input
              type="password"
              id="password"
              className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 shadow-sm"
              placeholder="Your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <div className="mt-2 flex justify-end">
              <a href="#" className="text-sm text-[#03844e] hover:text-[#026d40] font-medium">
                Forgot password?
              </a>
            </div>
          </div>
          
          <button
            type="submit"
            className="w-full bg-[#03844e] text-white p-4 rounded-lg font-medium hover:bg-[#026d40] transition duration-200 flex items-center justify-center shadow-sm"
            disabled={loading}
          >
            {loading ? <LoadingSpinner /> : 'Sign In'}
          </button>
        </form>
      )}

      {/* Success Screen (common for both methods) */}
      {step === 'success' && (
        <div className="text-center">
          <div className="mb-6">
            <svg className="mx-auto h-16 w-16 text-[#03844e]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
            </svg>
          </div>

          <p className="text-gray-700 mb-6 text-lg font-medium">
            Authentication successful!
          </p>

          <div className="bg-green-50 p-6 rounded-lg mb-6 border border-green-100 shadow-sm">
            {authMethod === 'phone' && (
              <p className="text-gray-700 text-sm mb-1">Phone: <span className="font-medium">{formattedPhoneNumber}</span></p>
            )}
            {authMethod === 'password' && (
              <p className="text-gray-700 text-sm mb-1">Email: <span className="font-medium">{email}</span></p>
            )}
            <p className="text-gray-700 text-sm">User ID: <span className="font-mono text-xs bg-gray-100 px-2 py-1 rounded">{user?.uid}</span></p>
            
            {userData && (
              <div className="mt-4 pt-3 border-t border-green-200">
                <p className="text-gray-700 font-medium mb-2">User Information:</p>
                <p className="text-gray-700 text-sm">
                  Name: <span className="font-medium">{userData.firstname} {userData.lastname}</span>
                </p>
                {userData.email && (
                  <p className="text-gray-700 text-sm mt-1">
                    Email: <span className="font-medium">{userData.email}</span>
                  </p>
                )}
              </div>
            )}
          </div>

          <button
            className="w-full bg-[#03844e] text-white p-4 rounded-lg font-medium hover:bg-[#026d40] transition duration-200 shadow-sm"
            onClick={() => {
              clearAuth();
              setStep('phone');
              setPhoneNumber('');
              setVerificationCode('');
              setEmail('');
              setPassword('');
              handleAuthMethodChange(authMethod);
            }}
          >
            Sign in again
          </button>
          
          <button
            className="w-full mt-3 bg-gray-100 text-gray-700 p-4 rounded-lg font-medium hover:bg-gray-200 transition duration-200 border border-gray-200"
            onClick={() => {
              navigate('/customer_account');
            }}
          >
            Go to Account
          </button>
        </div>
      )}

      {/* Sign up prompt for both methods */}
      {step !== 'success' && (
        <div className="mt-6 text-center">
          {/* <p className="text-gray-600">
            Don't have an account?{' '}
            <a href="#" className="text-blue-600 font-medium hover:text-blue-800">
              Create an account
            </a>
          </p> */}
        </div>
      )}
    </div>
  );
};

export default AuthComponent;
