import React, { useState, useEffect } from 'react';
import { useESIMStore } from "../../../hooks/esim"; 

const RefundModal = ({ isOpen, transaction, paymentInfo, onClose, onSubmit }) => {
  const { submitRefundRequest, isSubmittingRefund, refundError, resetRefundError } = useESIMStore(state => ({
    submitRefundRequest: state.submitRefundRequest,
    isSubmittingRefund: state.isSubmittingRefund,
    refundError: state.refundError,
    resetRefundError: state.resetRefundError
  }));
  
  // Use useEffect to reset form when transaction changes
  const [formData, setFormData] = useState({
    refundAmount: 0,
    reasons: '',
    details: '',
    transactionid: '',
    bookingId: '',
    currency: '',
    note: '',
    deductions: 0,
    paymentMethod: paymentInfo?.momoName ? 'momo' : 
                   paymentInfo?.bankNameGHS ? 'ghsBank' :
                   paymentInfo?.bankNameUSD ? 'usdBank' : ''
  });
  
  const [errors, setErrors] = useState({});

  // Reset form data when transaction changes
  useEffect(() => {
    if (transaction) {
      console.log("Transaction loaded in RefundModal:", transaction);
      const totalpaid = transaction.totalpaid || transaction.totalPaid || 0;
      
      setFormData({
        refundAmount: totalpaid,
        reasons: '',
        details: '',
        transactionid: transaction.transactionid || transaction.id || '',
        bookingId: transaction.bookingId || '',
        currency: transaction.currency || 'USD',
        survicename: transaction.serviceName || '',
        serviceType: transaction.transactionType || 'TOUR',
        note: '',
        amountpaid: totalpaid,
        customerId: transaction.customerId || '',
        paymentMethod: paymentInfo?.momoName ? 'momo' : 
                       paymentInfo?.bankNameGHS ? 'ghsBank' :
                       paymentInfo?.bankNameUSD ? 'usdBank' : ''
      });
    }
  }, [transaction, paymentInfo]);

  // Clear errors when modal is opened/closed
  useEffect(() => {
    if (isOpen) {
      setErrors({});
      resetRefundError && resetRefundError();
    }
  }, [isOpen, resetRefundError]);

  // Check which payment methods are available
  const hasMomoPayment = paymentInfo && paymentInfo.momoName && paymentInfo.momo_number;
  const hasGhsBankPayment = paymentInfo && paymentInfo.bankNameGHS && paymentInfo.bankNumberGHS;
  const hasUsdBankPayment = paymentInfo && paymentInfo.bankNameUSD && paymentInfo.bankNumberUSD;

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Clear errors for this field
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null
      });
    }
    
    // Special handling for refund amount to ensure it's numeric and not greater than paid amount
    if (name === 'refundAmount') {
      const totalpaid = transaction.totalpaid || transaction.totalPaid || 0;
      const numValue = parseFloat(value);
      if (!isNaN(numValue)) {
        if (numValue <= totalpaid) {
          setFormData({
            ...formData,
            [name]: numValue
          });
        } else {
          // Set to max possible refund amount
          setFormData({
            ...formData,
            [name]: totalpaid
          });
          setErrors({
            ...errors,
            refundAmount: `Maximum refund amount is ${transaction.currency} ${totalpaid}`
          });
        }
      } else if (value === '') {
        // Allow empty field for user to type
        setFormData({
          ...formData,
          [name]: ''
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const totalpaid = transaction.totalpaid || transaction.totalPaid || 0;
    
    // Refund amount must be positive and not exceed total paid
    const refundAmount = parseFloat(formData.refundAmount);
    if (isNaN(refundAmount) || refundAmount <= 0) {
      newErrors.refundAmount = 'Refund amount must be greater than zero';
    } else if (refundAmount > totalpaid) {
      newErrors.refundAmount = `Maximum refund amount is ${transaction.currency} ${totalpaid}`;
    }
    
    // Reason is required
    if (!formData.reasons || !formData.reasons.trim()) {
      newErrors.reasons = 'Please provide a reason for the refund';
    }
    
    // Details are required
    if (!formData.details || formData.details.trim().length < 10) {
      newErrors.details = 'Please provide more details about the refund (at least 10 characters)';
    }
    
    // Payment method is required
    if (!formData.paymentMethod) {
      newErrors.paymentMethod = 'Please select a payment method for the refund';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting refund form...");
    
    if (!validateForm()) {
      console.log("Form validation failed:", errors);
      return;
    }
    
    // Get payment method details
    let paymentMethodDetails = {};
    if (formData.paymentMethod === 'momo' && hasMomoPayment) {
      paymentMethodDetails = {
        type: 'mobile_money',
        provider: paymentInfo.paymentMode,
        name: paymentInfo.momoName,
        number: paymentInfo.momo_number
      };
    } else if (formData.paymentMethod === 'ghsBank' && hasGhsBankPayment) {
      paymentMethodDetails = {
        type: 'bank_transfer_ghs',
        bank: paymentInfo.bankNameGHS,
        accountName: paymentInfo.bankAccountNameGHS,
        accountNumber: paymentInfo.bankNumberGHS,
        branch: paymentInfo.branchGHS
      };
    } else if (formData.paymentMethod === 'usdBank' && hasUsdBankPayment) {
      paymentMethodDetails = {
        type: 'bank_transfer_usd',
        bank: paymentInfo.bankNameUSD,
        accountName: paymentInfo.bankAccountNameUSD,
        accountNumber: paymentInfo.bankNumberUSD,
        branch: paymentInfo.branchUSD
      };
    }
    
    // Prepare refund data
    const refundData = {
      ...formData,
      refundAmount: parseFloat(formData.refundAmount),
      customerId: transaction.customerId,
      amountpaid: transaction.totalpaid || transaction.totalPaid || 0,
      paymentMethodDetails,
      transactionid: transaction.transactionid || transaction.id || '',
      status: 'processed',
      // Include staff info from transaction if available
      staff: transaction.staff || { id: '' }
    };
    
    // Process refund
    console.log("Submitting refund with data:", refundData);
    
    // Use the store function to submit refund
    const result = await submitRefundRequest(refundData);
    
    if (result && result.success) {
      // Call the onSubmit prop to notify the parent component
      onSubmit({
        ...refundData,
        refundid: result.refundid
      });
    }
  };

  // Render guard
  if (!isOpen || !transaction) {
    return null;
  }

  const totalpaid = transaction.totalpaid || transaction.totalPaid || 0;
  const refundableLabelClass = totalpaid > 0 
    ? 'text-green-700' 
    : 'text-red-700';

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-lg max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Request Refund</h2>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
            disabled={isSubmittingRefund}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>

        <div className="bg-gray-100 p-4 rounded-md mb-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-gray-600">Booking ID</p>
              <p className="font-medium">{transaction.bookingId}</p>
            </div>
            <div>
              <p className="text-sm text-gray-600">Service</p>
              <p className="font-medium">{transaction.serviceName}</p>
            </div>
            <div>
              <p className="text-sm text-gray-600">Total Amount</p>
              <p className="font-medium">{transaction.currency} {(transaction.amount || 0).toFixed(2)}</p>
            </div>
            <div>
              <p className="text-sm text-gray-600">Paid Amount</p>
              <p className="font-medium">{transaction.currency} {totalpaid.toFixed(2)}</p>
            </div>
          </div>
          <div className="mt-2">
            <p className="text-sm text-gray-600">Refundable Amount</p>
            <p className={`font-medium ${refundableLabelClass}`}>
              {transaction.currency} {totalpaid.toFixed(2)}
            </p>
          </div>
        </div>

        {refundError && (
          <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-md">
            <p className="font-medium">Error:</p>
            <p>{refundError}</p>
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Expected Amount*
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500">{transaction.currency}</span>
                </div>
                <input
                  type="number"
                  name="refundAmount"
                  value={formData.refundAmount}
                  onChange={handleChange}
                  min="0.01"
                  step="0.01"
                  max={totalpaid}
                  className={`w-full pl-12 p-2 border ${errors.refundAmount ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                  required
                  disabled={isSubmittingRefund}
                />
              </div>
              {errors.refundAmount && (
                <p className="mt-1 text-sm text-red-600">{errors.refundAmount}</p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Note (optional)
              </label>
              <input
                type="text"
                name="note"
                value={formData.note}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                placeholder="E.g., Cancelation charges"
                disabled={isSubmittingRefund}
              />
            </div>

            {/* Payment Method Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Payment Method for Refund*
              </label>
              <div className="space-y-2">
                {hasMomoPayment && (
                  <div className="flex items-center">
                    <input
                      id="momo"
                      name="paymentMethod"
                      type="radio"
                      value="momo"
                      checked={formData.paymentMethod === 'momo'}
                      onChange={handleChange}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                      disabled={isSubmittingRefund}
                    />
                    <label htmlFor="momo" className="ml-2 block text-sm text-gray-700">
                      Mobile Money ({paymentInfo.momoName} - {paymentInfo.momo_number})
                    </label>
                  </div>
                )}
                
                {hasGhsBankPayment && (
                  <div className="flex items-center">
                    <input
                      id="ghsBank"
                      name="paymentMethod"
                      type="radio"
                      value="ghsBank"
                      checked={formData.paymentMethod === 'ghsBank'}
                      onChange={handleChange}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                      disabled={isSubmittingRefund}
                    />
                    <label htmlFor="ghsBank" className="ml-2 block text-sm text-gray-700">
                      GHS Bank Account ({paymentInfo.bankNameGHS} - {paymentInfo.bankNumberGHS})
                    </label>
                  </div>
                )}
                
                {hasUsdBankPayment && (
                  <div className="flex items-center">
                    <input
                      id="usdBank"
                      name="paymentMethod"
                      type="radio"
                      value="usdBank"
                      checked={formData.paymentMethod === 'usdBank'}
                      onChange={handleChange}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                      disabled={isSubmittingRefund}
                    />
                    <label htmlFor="usdBank" className="ml-2 block text-sm text-gray-700">
                      USD Bank Account ({paymentInfo.bankNameUSD} - {paymentInfo.bankNumberUSD})
                    </label>
                  </div>
                )}
              </div>
              {errors.paymentMethod && (
                <p className="mt-1 text-sm text-red-600">{errors.paymentMethod}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Reason for Refund*
              </label>
              <select
                name="reasons"
                value={formData.reasons}
                onChange={handleChange}
                className={`w-full p-2 border ${errors.reasons ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                required
                disabled={isSubmittingRefund}
              >
                <option value="">Select reason</option>
                <option value="Service Cancelled">Service Cancelled</option>
                <option value="Customer Request">Customer Request</option>
                <option value="Booking Error">Booking Error</option>
                <option value="Service Unavailable">Service Unavailable</option>
                <option value="Duplicate Payment">Duplicate Payment</option>
                <option value="Personal reasons">Personal reasons</option>
                <option value="Other">Other (specify in details)</option>
              </select>
              {errors.reasons && (
                <p className="mt-1 text-sm text-red-600">{errors.reasons}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Refund Details*
              </label>
              <textarea
                name="details"
                value={formData.details || ''}
                onChange={handleChange}
                rows="3"
                className={`w-full p-2 border ${errors.details ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                placeholder="Provide additional details about the refund reason"
                required
                disabled={isSubmittingRefund}
              ></textarea>
              {errors.details && (
                <p className="mt-1 text-sm text-red-600">{errors.details}</p>
              )}
            </div>

            {totalpaid <= 0 && (
              <div className="bg-red-100 p-3 rounded text-red-700 text-sm">
                <p className="font-bold">Warning:</p>
                <p>This transaction has no paid amount and cannot be refunded.</p>
              </div>
            )}
          </div>

          <div className="flex justify-end gap-2 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-800 rounded"
              disabled={isSubmittingRefund}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded disabled:bg-blue-300 disabled:cursor-not-allowed flex items-center justify-center min-w-[140px]"
              disabled={isSubmittingRefund || totalpaid <= 0 || (!hasMomoPayment && !hasGhsBankPayment && !hasUsdBankPayment)}
            >
              {isSubmittingRefund ? (
                <>
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Processing...
                </>
              ) : 'Submit Refund Request'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RefundModal;