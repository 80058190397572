import React, { useEffect, useState } from "react";
import CanConfetti from "../../components/Adways/Confetti";
import { useLocation } from "react-router-dom";

const WebsiteAds = () => {
  const [showAd, setShowAd] = useState(false);
  const location = useLocation();
  //console.log("inside Anniversary Countdown");
  //const hasShownAd = sessionStorage.getItem('hasShownAd');

  useEffect(() => {
    // Check if it's the first visit to the home page during this session
    const hasVisitedHome = sessionStorage.getItem("hasVisitedHome");

    if (location.pathname === "/" && !hasVisitedHome) {
      // If the user hasn't visited the home page in this session
      const lastShownTime = localStorage.getItem("lastShownTime");
      const now = new Date().getTime();
      const twentyFourHours = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

      // Check if 24 hours have passed since the last shown time
      if (
        !lastShownTime ||
        now - parseInt(lastShownTime, 10) > twentyFourHours
      ) {
        sessionStorage.removeItem("hasShownAd"); // Reset sessionStorage
        sessionStorage.removeItem("hasShownAd"); // Reset sessionStorage
      }

      // Check sessionStorage to determine if the modal should be shown
      const hasShownAd = sessionStorage.getItem("hasShownAd");

      if (!hasShownAd) {
        setShowAd(true);
        sessionStorage.setItem("hasShownAd", "true");
        localStorage.setItem("lastShownTime", now.toString());
      }

      // Mark the home page as visited
      sessionStorage.setItem("hasVisitedHome", "true");
    }
  }, [location]);

  const closeAd = () => {

    setShowAd(!showAd);
  };

  const scrollToSection = () => {
    window.location.href = "http://adansitravels.com/ads/2025_tour_calendar";
    closeAd();
  };

  if (!showAd) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div
        className="bg-white rounded-lg shadow-lg p-1 sm:p-6 w-11/12 w-full sm:max-w-4xl mx-auto relative"
        style={{}}
      >
        <button
          onClick={closeAd}
          className="absolute top-0 right-0 md:top-1 md:right-1 text-gray-400 hover:text-gray-600"
        >
          <svg
            className="h-6 w-6 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M6.225 4.811l-1.414 1.414L10.586 12l-5.775 5.775 1.414 1.414L12 13.414l5.775 5.775 1.414-1.414L13.414 12l5.775-5.775-1.414-1.414L12 10.586z" />
          </svg>
        </button>
        <div className="text-center">
          <div className="aspect-w-16 mt-6  sm:m-0 aspect-h-9">
            <iframe
              className="w-full h-full"
              //src="https://www.youtube.com/embed/NSZC23mq_vQ?autoplay=1&mute=1&playlist=NSZC23mq_vQ&loop=1"
              src="https://youtube.com/embed/Bp0Sp5g7VCc?si=CgO9cX0h5m4ShY-T?autoplay=1&mute=1&playlist=Bp0Sp5g7VCc&loop=1"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube Video"
            ></iframe>
          </div>
          <div className="text-center p-2 pt-5 bg-white rounded-lg max-w-2xl mx-auto  ">
            <h2 className="text-md md:text-2xl font-bold text-gray-900 mb-2">
            Welcome to our premium Branches.
            </h2>
            <p className="text-gray-700 text-xs md:text-lg mb-3">
            Experience unparalleled service at our offices.
            </p>

            {/* <button
              className="bg-green-600 text-sm text-white py-2 px-4 rounded-full shadow-md hover:bg-green-500 hover:shadow-lg transition duration-200 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-300"
              onClick={scrollToSection}
            >
              Download Now
            </button> */}
          </div>
        </div>
        {/* <CanConfetti />
        <CanConfetti /> */}
      </div>
    </div>
  );
};

export default WebsiteAds;
