import React, { FC, useEffect } from "react";
import SectionSubscribe2 from "../../../components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "../../../shared/SocialsList/SocialsList";
import Label from "../../../components/Label/Label";
import Input from "../../../shared/Input/Input";
import Textarea from "../../../shared/Textarea/Textarea";
import ButtonPrimary from "../../../shared/Button/ButtonPrimary";
import office1 from "../../../images/head-office.jpg";
export interface PageContactProps {}

const branches = [
  {
    name: "Head Office",
    location:
      "136 La Bawaleshi Road. American House, East Legon, Accra - Ghana",
    map: "https://maps.google.com/?cid=17419632851567509528&entry=gps",
  },
  {
    name: "The Adansi Travel House",
    location: "U2003 La Bawaleshie Road, East Legon, Accra - Ghana",
    map: "https://maps.app.goo.gl/AuwQLqEE1TXJkPu99",
  },
  {
    name: "Ashaley Botwe",
    location: "Plaza Building, Quartey Papafio Ave, Accra - Ghana",
    map: "https://maps.app.goo.gl/1J8Qxb9oQjjcTiqN8",
  },
  {
    name: "Takoradi",
    location: " Takoradi mall, Gate 2, Takoradi - Ghana",
    map: "https://maps.app.goo.gl/oUyneZDmEPCZeF4z9",
  },
  {
    name: "Kumasi",
    location: "Opposite The Baba Yara Stadium, Kumasi - Ghana",
    map: "https://maps.google.com/maps?q=6.684721946716309%2C-1.6066570281982422&z=17&hl=en",
  },
  {
    name: "Ikeja, Lagos",
    location: "78/79 Trinity Mall, Awolowo way, Ikeja Lagos - Nigeria",
    map: "https://maps.app.goo.gl/z7pcPi2cJnGCpRMC6",
  },
  {
    name: "Nester Square, Accra",
    location: "Nester Square, Accra - Ghana",
    map: "https://maps.app.goo.gl/ga7i7y4wxUKyiDp29",
  },
  {
    name: "Tema premium Branch, Tema C1",
    location: "Tema premium Branch, Tema C1 - Ghana",
    map: "https://maps.app.goo.gl/LbZypfGomcEJV9dw5",
  },
];

const contactInfo = [
  {
    title: "Head Office",
    desc: [
      "Visit our head office located at 136, La Bawaleshie Road, East Legon American House, AccraGhana. Our team will be happy to assist you with any inquiries.",
    ],
  },
  {
    title: "EMAIL",
    desc: ["info@adansitravels.com", "sales@adansitravels.com"],
  },
  {
    title: "PHONE",
    desc: ["+233 302548674", "+233 243606648"],
  },
];

const PageContact: FC<PageContactProps> = ({}) => {

  return (
    <div className={`nc-PageContact overflow-hidden `}>
      {/* CONTACT US */}
      <div className="mb-16 lg:mb-16 pb-10 px-0 sm:p-4 xl:p-8 ">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact Us
        </h2>

        <div className="container flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-2 ">
          <div className="pr-4 space-y-8">
            {contactInfo.map((item, index) => (
              <div key={index}>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  {item.title}
                </h3>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mb-4"></div>

                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  {Array.isArray(item.desc) ? (
                    item.desc.map((descItem, descIndex) => (
                      <div key={descIndex}>{descItem}</div>
                    ))
                  ) : (
                    <div>{item.desc}</div>
                  )}
                </span>
              </div>
            ))}
               <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            <div><a target="_blank" href="https://wa.me/233531011617?text=Welcome%20to%20Adansi%20Travels%20WhatsApp">Click to WhatsApp us : <strong>233 531 011 617</strong></a></div>
            </span>
            <div>

         

    
              <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                SOCIALS
              </h3>
              <SocialsList className="mt-2" />
            </div>
          </div>

          <div className="flex justify-center items-center w-full h-full">
            <div className="relative w-full h-screen md:w-[550px] md:h-[600px] border-2 border-[#03844e] overflow-hidden">
              <img
                src={office1}
                alt="our story"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container mx-auto mb-8 px-4 sm:px-0">
        <h3 className="my-8 text-2xl font-semibold text-neutral-900 dark:text-neutral-100">
          Our Branches
        </h3>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b dark:border-gray-700 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Branch
                </th>
                <th className="px-4 py-2 border-b dark:border-gray-700 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Location
                </th>
                <th className="px-4 py-2 border-b dark:border-gray-700 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Map
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
              {branches.map((branch, index) => (
                <tr key={index}>
                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
                    {branch.name}
                  </td>
                  <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    {branch.location}
                  </td>
                  <td className="px-4 py-4 whitespace-nowrap text-sm text-green-500">
                    <a
                      href={branch.map}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-green-600 hover:text-green-800"
                    >
                      View on map
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
        */}


<div className="container mx-auto mb-8 px-4 sm:px-0">
  <h3 className="my-8 text-2xl font-semibold text-neutral-900 dark:text-neutral-100">
    Our Branches
  </h3>
  <div className="overflow-x-auto">
    <table className="min-w-full bg-white border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
      <thead className="bg-gray-50 dark:bg-gray-700">
        <tr>
          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider dark:text-gray-300">
            Branch
          </th>
          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider dark:text-gray-300">
            Location
          </th> 
          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider dark:text-gray-300">
            Map
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
        {branches.map((branch, index) => (
          <tr key={index}>
            <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
              {branch.name}
            </td>
            <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
              {branch.location}
            </td>
            <td className="px-4 py-4 whitespace-nowrap text-sm text-green-500">
              <a
                href={branch.map}
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-600 hover:text-green-800"
              >
                View on map
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>

      
    </div>
  );
};

export default PageContact;

