import React, { useState, useEffect } from 'react';
import { CheckCircle, Wifi, Calendar, Clock, AlertTriangle } from 'lucide-react';
import { useESIMStore } from '../../../hooks/esim'; 

const ESIMTopup = () => {
  const [esimNumber, setEsimNumber] = useState('');
  const [error, setError] = useState('');
  const [localApiError, setLocalApiError] = useState(null);
  
  // Get the relevant state and functions from the Zustand store
  const { 
    fetchESIMDetails, 
    esimDetails, 
    isDetailsLoading 
  } = useESIMStore();

  // Reset error when esimDetails changes
  useEffect(() => {
    if (esimDetails?.error) {
      setLocalApiError(esimDetails.error);
    } else {
      setLocalApiError(null);
    }
  }, [esimDetails]);

  // Validation for eSIM number
  const validateESIMNumber = (number) => {
    // Basic validation - checking if it's numeric
    const regex = /^\d+$/;
    return regex.test(number);
  };

  // Handle input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    setEsimNumber(value);
    if (value && !validateESIMNumber(value)) {
      setError('Please enter a valid eSIM number (digits only)');
    } else {
      setError('');
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!esimNumber) {
      setError('Please enter an eSIM number');
      return;
    }
    
    if (!validateESIMNumber(esimNumber)) {
      setError('Please enter a valid eSIM number (digits only)');
      return;
    }
    
    // Clear any previous validation errors
    setError('');
    setLocalApiError(null);
    
    // Log the entered number
    console.log('eSIM number submitted:', esimNumber);
    
    // Call the store function to fetch eSIM details
    try {
      await fetchESIMDetails(esimNumber);
    } catch (error) {
      console.error('Error in component while fetching eSIM details:', error);
      // Note: The store already handles setting the error state
    }
  };

  const resetForm = () => {
    setEsimNumber('');
    setError('');
    setLocalApiError(null);
    // Reset the store state to null
    useESIMStore.getState().esimDetails = null;
  };

  // Check if we have result data
  const hasResult = esimDetails && !esimDetails.error;
  const hasError = localApiError || esimDetails?.error;

  return (
    <div className="flex items-center justify-center bg-gray-50 px-4 pt-20 pb-4 mb-[30px]">
      <div className="w-full max-w-sm mx-auto bg-white rounded-xl shadow-lg overflow-hidden sm:max-w-md lg:max-w-lg p-4 sm:p-6 mt-0 mb-2 border border-green-100 hover:shadow-xl transition-shadow duration-300">
        <div className="text-center mb-4 sm:mb-6">
          <h1 className="text-xl sm:text-2xl font-bold text-gray-800 mb-1 sm:mb-2">eSIM Wallet Topup</h1>
          <p className="text-sm sm:text-base text-gray-600">Check your balance or top up your eSIM</p>
          <div className="h-1 w-16 sm:w-20 bg-green-500 mx-auto mt-2 sm:mt-3 rounded-full"></div>
        </div>
        
        {!hasResult && !hasError ? (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <label htmlFor="esim-number" className="block text-sm font-medium text-gray-700">
                eSIM Number
              </label>
              <input
                type="text"
                id="esim-number"
                placeholder="Enter your eSIM number"
                value={esimNumber}
                onChange={handleInputChange}
                className={`block w-full px-4 py-3 rounded-md border ${
                  error ? 'border-red-300 bg-red-50' : 'border-gray-300'
                } focus:outline-none focus:ring-2 focus:ring-blue-500`}
                disabled={isDetailsLoading}
              />
              {error && <p className="text-red-500 text-sm">{error}</p>}
            </div>
            
            <button
              type="submit"
              disabled={isDetailsLoading}
              className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition-colors"
            >
              {isDetailsLoading ? (
                <div className="flex items-center justify-center">
                  <svg className="animate-spin h-5 w-5 mr-2 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Processing...
                </div>
              ) : (
                "Check Balance"
              )}
            </button>
          </form>
        ) : hasError ? (
          <div className="space-y-6">
            <div className="bg-red-50 border border-red-200 p-3 sm:p-5 rounded-lg shadow-sm">
              <div className="flex items-start sm:items-center">
                <div className="bg-white p-1.5 sm:p-2 rounded-full shadow-sm mr-2 sm:mr-3 mt-0.5 sm:mt-0 flex-shrink-0">
                  <AlertTriangle className="h-5 w-5 sm:h-6 sm:w-6 text-red-500" />
                </div>
                <p className="text-sm sm:text-base text-red-700 font-medium">
                  {localApiError || esimDetails?.error || 'Failed to fetch eSIM details. Please try again.'}
                </p>
              </div>
            </div>
            
            <button
              onClick={resetForm}
              className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition-colors shadow-sm"
            >
              Try Again
            </button>
          </div>
        ) : (
          <div className="space-y-6">
            <div className="bg-green-50 border border-green-200 p-3 sm:p-5 rounded-lg shadow-sm">
              <div className="flex items-start sm:items-center">
                <div className="bg-white p-1.5 sm:p-2 rounded-full shadow-sm mr-2 sm:mr-3 mt-0.5 sm:mt-0 flex-shrink-0">
                  <CheckCircle className="h-5 w-5 sm:h-6 sm:w-6 text-green-500" />
                </div>
                <p className="text-sm sm:text-base text-green-700 font-medium">eSIM balance retrieved successfully!</p>
              </div>
            </div>
            
            <div className="bg-white rounded-lg p-4 sm:p-5 shadow-sm border border-gray-200">
              <h2 className="text-base sm:text-lg font-semibold text-gray-800 mb-3 sm:mb-4 flex items-center">
                <div className="h-4 sm:h-5 w-1 sm:w-1.5 bg-green-500 rounded-full mr-2"></div>
                eSIM Details
              </h2>
              <div className="space-y-2 sm:space-y-3">
                <div className="flex flex-col sm:flex-row sm:justify-between py-2 border-b border-gray-100">
                  <span className="text-sm sm:text-base text-gray-600 font-medium">ICCID:</span>
                  <span className="text-sm sm:text-base font-medium text-gray-800 break-all">{esimDetails?.data?.iccid}</span>
                </div>
                <div className="flex flex-col sm:flex-row sm:justify-between py-2 border-b border-gray-100">
                  <span className="text-sm sm:text-base text-gray-600 font-medium">Short ICCID:</span>
                  <span className="text-sm sm:text-base font-medium text-gray-800">{esimDetails?.data?.short_iccid}</span>
                </div>
                <div className="flex flex-col sm:flex-row sm:justify-between py-2 border-b border-gray-100">
                  <span className="text-sm sm:text-base text-gray-600 font-medium">Order ID:</span>
                  <span className="text-sm sm:text-base font-medium text-gray-800 break-all">{esimDetails?.data?.order?.order_id}</span>
                </div>
                <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between py-2">
                  <span className="text-sm sm:text-base text-gray-600 font-medium mb-1 sm:mb-0">Code:</span>
                  <span className="text-sm sm:text-base font-medium font-mono bg-gray-50 px-2 sm:px-3 py-1 rounded-md border border-gray-200 text-gray-800 break-all">{esimDetails?.data?.order?.code}</span>
                </div>
              </div>
            </div>
            
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-3 sm:gap-4">
              <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-lg p-3 sm:p-4 flex flex-col items-center justify-center shadow-sm hover:shadow-md transition-shadow duration-300 border border-blue-200">
                <div className="bg-white p-1.5 sm:p-2 rounded-full mb-2 sm:mb-3 shadow-sm">
                  <Calendar className="h-6 w-6 sm:h-8 sm:w-8 text-blue-600" />
                </div>
                <span className="text-xs sm:text-sm text-blue-700 font-medium">Days Left</span>
                <span className="text-lg sm:text-2xl font-bold text-blue-800 mt-0.5 sm:mt-1">{esimDetails?.data?.status?.days_left || '0'}</span>
              </div>
              
              <div className="bg-gradient-to-br from-purple-50 to-purple-100 rounded-lg p-3 sm:p-4 flex flex-col items-center justify-center shadow-sm hover:shadow-md transition-shadow duration-300 border border-purple-200">
                <div className="bg-white p-1.5 sm:p-2 rounded-full mb-2 sm:mb-3 shadow-sm">
                  <Clock className="h-6 w-6 sm:h-8 sm:w-8 text-purple-600" />
                </div>
                <span className="text-xs sm:text-sm text-purple-700 font-medium">Minutes Left</span>
                <span className="text-lg sm:text-2xl font-bold text-purple-800 mt-0.5 sm:mt-1">{esimDetails?.data?.status?.minutes_left || '0'}</span>
              </div>
              
              <div className="bg-gradient-to-br from-green-50 to-green-100 rounded-lg p-3 sm:p-4 flex flex-col items-center justify-center shadow-sm hover:shadow-md transition-shadow duration-300 border border-green-200">
                <div className="bg-white p-1.5 sm:p-2 rounded-full mb-2 sm:mb-3 shadow-sm">
                  <Wifi className="h-6 w-6 sm:h-8 sm:w-8 text-green-600" />
                </div>
                <span className="text-xs sm:text-sm text-green-700 font-medium">Data Balance</span>
                <span className="text-lg sm:text-2xl font-bold text-green-800 mt-0.5 sm:mt-1">
                  {esimDetails?.data?.status?.limited_destincations_balance?.balance || '0 GB'}
                </span>
                <span className="text-xs text-green-700 mt-0.5 sm:mt-1">
                  {esimDetails?.data?.status?.limited_destincations_balance?.country || 'Unknown'}
                </span>
              </div>
            </div>
            
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4 mt-6">
              <button
                onClick={resetForm}
                className="bg-white border border-gray-300 hover:bg-gray-50 text-gray-700 font-medium py-3 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition-colors shadow-sm"
              >
                Check Another eSIM
              </button>
              <button
                onClick={() => console.log('Top up requested for eSIM:', esimDetails?.data?.iccid)}
                className="bg-green-600 hover:bg-green-700 text-white font-medium py-3 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition-colors shadow-sm"
              >
                Top Up Now
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ESIMTopup;