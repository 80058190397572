import create, { StateCreator } from 'zustand';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { set } from 'lodash';

// Define types for the store state
interface ESIMStoreState {
  data: any[];
  products: any[];
  compatibilityResult: any | null;
  isLoading: boolean;
  isProductsLoading: boolean;
  isCompatibilityLoading: boolean;
  fetchData: () => Promise<void>;
  fetchProducts: () => Promise<void>;
  checkCompatibility: (phoneModel: string) => Promise<void>;
  resetCompatibilityResult: () => void;
}

// Define constants
const BASE_URL = 'https://workstation.adansitravels.com/get_all_esim_destinations';
const PRODUCTS_URL = 'https://workstation.adansitravels.com/fetch_all_esim_with_cost';
const COMPATIBILITY_URL = 'https://workstation.adansitravels.com/check_esim_compactibility';
const API_KEY = process.env.REACT_APP_API_KEY;
const STORAGE_KEY = 'esim_destinations';
const EXPIRY_KEY = 'esim_destinations_expiry';

// Create the store with TypeScript types
const useESIMStore = create<ESIMStoreState>((set) => ({
  data: [],
  products: [],
  compatibilityResult: null,
  isLoading: true,
  isProductsLoading: true,
  isCompatibilityLoading: false,

  fetchData: async () => {
    const now = Date.now();
    const expiryTime = localStorage.getItem(EXPIRY_KEY);

    if (expiryTime && now < Number(expiryTime)) {
      const cachedData = localStorage.getItem(STORAGE_KEY);
      if (cachedData) {
        set({ data: JSON.parse(cachedData), isLoading: false });
        return;
      }
    }

    set({ isLoading: true });
    try {
      const response = await axios.get(BASE_URL, {
        headers: {
          'adway-key': API_KEY,
        },
      });
      
      // Uncommented the data setting code
      if (response.data && response.data.destinations) {
        set({ data: response.data.destinations, isLoading: false });
        localStorage.setItem(STORAGE_KEY, JSON.stringify(response.data.destinations));
        localStorage.setItem(EXPIRY_KEY, (now + 12 * 60 * 60 * 1000).toString()); // 12 hours expiry
      } else {
        // If API response format is unexpected, set empty data array and stop loading
        console.error('Unexpected API response format:', response.data);
        set({ data: [], isLoading: false });
      }
    } catch (error) {
      console.error('Error fetching eSIM data:', error);
      set({ isLoading: false });
    }
  },

  fetchProducts: async () => {
    set({ isProductsLoading: true });
    try {
      const response = await axios.get(PRODUCTS_URL, {
        headers: {
          'adway-key': API_KEY,
        },
      });
     // console.log(response);
      set({ products: response.data.data, isProductsLoading: false });
    } catch (error) {
      console.error('Error fetching eSIM products:', error);
      set({ isProductsLoading: false });
    }
  },

  checkCompatibility: async (phoneModel: string) => {
    set({ isCompatibilityLoading: true, compatibilityResult: null });
    try {
      const response = await axios.post(
        COMPATIBILITY_URL,
        { phoneName: phoneModel },
        {
          headers: {
            'adway-key': API_KEY,
            'Content-Type': 'application/json',
          },
        }
      );
     // console.log(response, phoneModel);
      set({
        compatibilityResult: response.data,
        isCompatibilityLoading: false,
      });
    } catch (error) {
      console.error('Error checking eSIM compatibility:', error);
      set({
        compatibilityResult: { error: 'Failed to check compatibility. Please try again.' },
        isCompatibilityLoading: false,
      });
    }
  },

  resetCompatibilityResult: () => set({ compatibilityResult: null }),
}));



const useSaveESIMLeads = (formValues, Base_url) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);

  const BASE_URL = Base_url;
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchData = async () => {
      const isFormValuesEmpty =
        !formValues || Object.keys(formValues).length === 0;
        if (isFormValuesEmpty) {
          setError(
            "Form values are empty. Please provide the required information."
          );
          setIsLoading(false);
          return;
        }
        setIsLoading(true);
        try {
          //console.log(formValues , "====")
         // console.log("inside hooks", formValues);
        const response = await axios.post(
          BASE_URL,
          {leads_data: formValues },
          {
            headers: {
              "adway-key": API_KEY,
            },
          }
        );

        setData(response.data);
        setSubmitted(true);
       
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error saving data.");
        setIsError(true);
      } finally {
        setIsLoading(false);
     
      }
    };

    fetchData();
  }, [formValues, API_KEY]);

  return {data, isLoading, submitted, error, isError };
};


const useUpdateESIMLeads = (formValues, Base_url) => {
  const [data, setData] = useState([]);
  const [isLoadingUpdate, setIsLoading] = useState(true);
  const [updated, setUpdated] = useState(false);
  const [updateFailed, setUpdateFailed] = useState(false);
  const [error, setError] = useState(null);

  const BASE_URL = Base_url;
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchData = async () => {
      const isFormValuesEmpty =
        !formValues || Object.keys(formValues).length === 0;

      if (isFormValuesEmpty) {
        setError(
          "Form values are empty. Please provide the required information."
        );
        setIsLoading(false);
        return;
      }

      //console.log("inside hooks", formValues);

      setIsLoading(true);
      try {
        //console.log(formValues , "====")
        const response = await axios.post(
          BASE_URL,
          formValues,
          {
            headers: {
              "adway-key": API_KEY,
            },
          }
        );
       // console.log(response);
        setData(response.data);
        setUpdated(true);
      
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(`Error fetching data. ${error}`);
        setUpdated(false);
        setUpdateFailed(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [formValues, API_KEY]);

  return { data, isLoadingUpdate, updated, error, updateFailed };
};

export { useESIMStore, useSaveESIMLeads, useUpdateESIMLeads };