import CardAuthorBox from "./cardBox";
import Heading from "components/Heading/Heading";
import React, { FC, useEffect } from "react";
import { useESIMStore } from "../../../hooks/esimLead";
import ListingESIMLoader from "./Loaders/ESIMLoader";

export interface RecentEsimDestinationsProps {
  className?: string;
  gridClassName?: string;
}

const RecentEsimDestinations: FC<RecentEsimDestinationsProps> = ({
  className = "",
  gridClassName = "grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-6 p-4 md:p-6 justify-center",
}) => {
    // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
  const { data, isLoading, fetchData } = useESIMStore();

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const popularDestinations = data.filter((destination) => destination.popular);

  return (
    <div
      className={`nc-SectionGridAuthorBox flex flex-col items-center ${className}`}
      data-nc-id="SectionGridAuthorBox"
    >
      <Heading
        desc="Rating based on customer reviews"
        isCenter
        className="text-base mb-4"
      >
        Popular Destinations
      </Heading>
      {isLoading ? (
        <ListingESIMLoader length={8} />
      ) : (
        <div className={`grid ${gridClassName} w-full max-w-5xl`}>
          {popularDestinations.map((destination, index) => (
            <CardAuthorBox
              key={destination.country}
              author={{
                surname: "",
                othernames: "",
                email: "",
                phone: "",
                simDetails: {
                  avatar: destination.flag_url ?? "",
                  bgImage: "",
                  simCountry: destination.country ?? "Unknown Country",
                  data_plan: destination.data_plan ?? "No Data Plan",
                  currencyRate: 0,
                  totalPrice: undefined,
                  count: 0,
                },
              }}
              index={index < 3 ? index + 1 : undefined}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default RecentEsimDestinations;
