import React from "react";

const SimDetailLoader: React.FC = () => {
  return (
    <div className="w-full p-6 bg-white rounded-lg shadow-md animate-pulse">
      {/* Header Loader */}
      <div className="h-6 w-2/3 bg-gray-300 rounded mb-4"></div>

      {/* Plan Options */}
      <div className="space-y-3">
        {[1, 2, 3, 4].map((_, index) => (
          <div key={index} className="h-10 w-full bg-gray-300 rounded"></div>
        ))}
      </div>

      {/* Quantity and Button */}
      <div className="flex items-center justify-between mt-4">
        <div className="h-8 w-24 bg-gray-300 rounded"></div>
        <div className="h-10 w-28 bg-gray-300 rounded"></div>
      </div>

      {/* Features List */}
      <div className="mt-6 space-y-2">
        {[1, 2, 3, 4].map((_, index) => (
          <div key={index} className="h-4 w-3/4 bg-gray-300 rounded"></div>
        ))}
      </div>
    </div>
  );
};

export default SimDetailLoader;
